import React from 'react';
import DataTable from 'react-data-table-component';

const HabitTable = ({ habits, toggleHabit, checkedDays }) => {
    const days = Array.from({ length: 31 }, (_, i) => i + 1);

    const columns = [
        {
            name: 'Habit',
            selector: row => row.habit,
            sortable: true,
            width: '150px',
        },
        ...days.map(day => ({
            name: day,
            cell: row => (
                <input
                    type="checkbox"
                    checked={!!checkedDays[row.habit_id]?.[day]}
                    disabled
                />
            ),
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        })),
    ];

    // Filter duplicate habits based on habit_id
    const uniqueHabits = habits.reduce((acc, habit) => {
        if (!acc.some(item => item.habit_id === habit.id)) {
            acc.push(habit);
        }
        return acc;
    }, []);

    const data = uniqueHabits.map((habit, habitIndex) => {
        const habitData = { habit: habit.habit, habit_id: habit.id };
        return habitData;
    });

    return (
        <div className='habit-data-table'>
            <DataTable
                columns={columns}
                data={data}
                pagination
                persistTableHead={true}
            />
        </div>
    );
};

export default HabitTable;
