import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../../utils';
import axios from 'axios';

const ConqueringFear = () => {
    const uid = localStorage.getItem('user_id') || '';
    const [formData, setFormData] = useState({});
    const [questions, setQuestions] = useState([]);

    const secondQuestionOptions = [
        'Procrastination', 'Perfectionism', 'Feeling stuck', 'Overwhelm',
        'Anxiety', 'Depression', 'Resistance', 'Lack of self confidence',
        'Avoidance', 'Negative self-talk', 'Overwhelm', 'Apathy',
        'Feeling powerless', 'Depression'
    ];

    useEffect(() => {
        fetchQuestions();   
    }, []);

    useEffect(() => {
        fetchQuestions();
    }, []);

    const fetchQuestions = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('main_category_id', '2');
        formDataToSend.append('sub_category_id', '3');
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getQuestions}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );
            if (response.data.status === 'success') {
                setQuestions(response.data.question || []);
                const initialFormData = {};
                (response.data.question || []).forEach((question) => {
                    initialFormData[question.id] = question.answer || '';
                });
                setFormData(initialFormData);
            } else {
                toast.error(response.data.message || 'Failed to fetch questions');
            }
        } catch (error) {
            console.error('Error fetching questions:', error);
        }
    };

    const handleSubmitFear = (e) => {
        e.preventDefault();

            handleSubmit();
        
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;


        if (type === 'checkbox') {
            setFormData((prevData) => {
                const prevAnswers = prevData[name] ? prevData[name].split(', ') : [];
                if (checked) {
                    return {
                        ...prevData,
                        [name]: [...prevAnswers, value].join(', '),
                    };
                } else {
                    return {
                        ...prevData,
                        [name]: prevAnswers.filter((answer) => answer !== value).join(', '),
                    };
                }
            });
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleSubmit = async (e) => {
        const formDataToSend = new FormData();
        questions.forEach((question, index) => {
            formDataToSend.append(`question_id[]`, question.id);
            formDataToSend.append(`answer[]`, formData[question.id] || '');
        });

        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.addAnswer}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                fetchQuestions();
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message || 'Failed to update answers');
            }
        } catch (error) {
            console.error('Error updating answers:', error);
        }
    };

    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <h5>Conquering Your Fear</h5>
                    </div>

                    <form className="form theme-form" onSubmit={handleSubmitFear}>
                        <div className="card-body m-3">
                            <div className="row">
                                <div className="col-sm-12">
                                    {questions.map((question, index) => (
                                        <div className="form-group row mb-2" key={question.id}>
                                            <label className="col-sm-12 col-form-label  mb-2 p-0"><b>{question.question}</b></label>
                                            {index === 1 ? (
                                                secondQuestionOptions.map((option, idx) => (

                                                    <div  key={idx}>
                                                        <input
                                                            className="checkbox_animated"
                                                            type="checkbox"
                                                            name={question.id}
                                                            value={option}
                                                            checked={(formData[question.id] || '').split(', ').includes(option)}
                                                            onChange={handleChange}
                                                        />
                                                        <label className="form-check-label">
                                                            {option}
                                                        </label>
                                                    </div>
                                                ))
                                            ) : (
                                                <textarea
                                                    className={`form-control `}
                                                    name={question.id}
                                                    value={formData[question.id] || ''}
                                                    onChange={handleChange}
                                                    rows="4"
                                                ></textarea>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <button type="submit" className="btn btn-success main-buttons me-1 float-end">
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ConqueringFear;
