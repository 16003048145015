import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API, { isSubscribedAccountant } from "../../utils";
import axios from "axios";
import TopTenCoreValue from "./coreValueComponent/topTenCoreValue";
import TopFiveCoreValue from "./coreValueComponent/topFIvecoreValue";
import { useNavigate } from "react-router-dom";

const MyCoreValues = () => {
    const uid = localStorage.getItem("user_id");
    const [checkedItems, setCheckedItems] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        if (!isSubscribedAccountant()) {
            navigate("/users/userEdit");
            window.location.reload();
        }
    }, [navigate]);

    const [topTencoreValues, setTopTenCoreValues] = useState([]);
    const [topFivecoreValues, setTopFiveCoreValues] = useState([]);

    const fetchTopTenCoreValues = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_all_to_personal_os_core_value}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: "FFxx21$$21life360AAA212TRRR",
                        'Content-Type': 'multipart/form-data'
                    },
                }
            );

            if (response.data.status === "success") {
                setTopTenCoreValues(response.data.data);
            } else {
                // toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during fetch:", error);
        }
    };

    const fetchTopFiveCoreValues = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_top5new_data}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: "FFxx21$$21life360AAA212TRRR",
                        'Content-Type': 'multipart/form-data'
                    },
                }
            );
            
            if (response.data.status === "success") {
                setTopFiveCoreValues(response.data.data);
            } else {
                // toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during fetch:", error);
        }
    };

    useEffect(() => {
        fetchTopTenCoreValues();
        fetchData();
        fetchTopFiveCoreValues();
    }, []);

    const [lifeAreas, setLifeAreas] = useState([]);

    const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_all_question_to_core_value}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                const fetchedLifeAreas = response.data.data;

                setLifeAreas(fetchedLifeAreas);

                const initialCheckedItems = {};
                fetchedLifeAreas.forEach((area, index) => {
                    initialCheckedItems[`question-${index}`] = area.is_check === "1";
                });
                setCheckedItems(initialCheckedItems);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch role model data error', error);
            toast.error("Failed to fetch role model data.");
        }
    };

    const handleCheckboxChange = async (event, area, coreId) => {
        const { checked, id } = event.target;
        const updatedCheckedItems = { ...checkedItems, [id]: checked };

        setCheckedItems(updatedCheckedItems);

        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('question_id', coreId);
        formDataToSend.append('is_check', checked ? '1' : '0');

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_update_personal_os_core_value}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                // toast.success(response.data.message); 
                fetchData();
                fetchTopTenCoreValues();
            } else {
                // toast.error(response.data.message || 'Failed to update checkbox status');
            }
        } catch (error) {
            console.error('Error updating checkbox status:', error);
            toast.error('An error occurred while updating checkbox status.');
        }
    };

    return (
        <div>
            <div className="container-fluid">
                <div className="row ">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header ">
                                <div className="d-flex justify-content-between">
                                    <div className="col-md-6 align-items-center d-flex">
                                        <h5>All Core Value List</h5>
                                    </div>
                                    <div className="col-md-6 text-end">
                                    </div>
                                </div>
                            </div>
                            <form className="form theme-form">
                                <div className="card-body">
                                    <div className="core-values-container mb-3">
                                        <strong className="section-title">“Read through this entire list of core values and anything that resonates with you, select it. If you pause, and have to think about it, that means you should select it. Select as many as you want that really resonate. This is the first pass at this, we will refine them later, so select as many that are meaningful to you.”</strong>
                                        <strong className="section-title">What are Core Values?</strong>
                                        <p className="section-paragraph">
                                            Core values are the general expression of what is most important for you. A value expresses the worth of something, and in this case what you categorically like and dislike. So, they are like categories for all your preferences in life. Values are formed starting in early childhood and are later consciously re-evaluated and can therefore be changed. By comparing two values you can discover which is representing something that is more important than the other. Therefore, you rate the one value over the other. Core values are generally operating in the background. They influence everything you do but usually it happens on auto-pilot. You just know intuitively what you like and dislike and decide accordingly.
                                        </p>
                                        <strong className="section-subtitle">Why is it good to know your core values?</strong>
                                        <p className="section-paragraph">
                                            It’s good to know this, first because you get clarity and build your self-awareness by identifying your values and secondly knowing your highest values can act like a guide for you. It makes intelligent decisions easier. Also, knowing your negative values, those from which you try to keep away from, is very helpful as well. When you are clearly aware of your value hierarchy you can consciously check situations against your value-system, which will create better decisions and results. It will be easier to keep your balance in life. For instance, when you are about to make an important decision you can double-check if going the one or the other way would go against one of your core values. On the long run, that would pose a problem for you. So, all in all it’s an awareness building process which can give you direction in life.
                                        </p>
                                        <p className="section-paragraph">
                                            Getting clear on your values will provide you with an important anchor and give you a deeper sense of ease as you navigate the complicated choices of work, life, and everything in between.
                                        </p>
                                    </div>
                                    <div className="container mt-4">
                                        <div className="row">
                                            {lifeAreas.map((area, index) => (
                                                <div className="col-md-3 col-sm-6" key={index}>
                                                    <div className="form-check">
                                                        <label className="form-check-label" htmlFor={`question-${index}`}>
                                                            <input
                                                                className="checkbox_animated"
                                                                type="checkbox"
                                                                id={`question-${index}`}
                                                                value={area.id}
                                                                onChange={(e) => handleCheckboxChange(e, area.question, area.id)}
                                                                checked={area.is_check === "1"}
                                                            />
                                                            {area.question}
                                                        </label>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Tabs
                    defaultActiveKey="My Top Core Values"
                    id="justify-tab-example"
                    className="border-tab nav-primary pt-3 mb-3"
                    justify
                >
                    <Tab eventKey="My Top Core Values" title="My Top Core Values">
                        <TopTenCoreValue topTencoreValues={topTencoreValues} fetchTopTenCoreValues={fetchTopTenCoreValues} fetchTopFiveCoreValues={fetchTopFiveCoreValues} />
                    </Tab>
                    <Tab eventKey="Top 5 Core Value List" title="Top 5 Core Value List">
                        <TopFiveCoreValue topFivecoreValues={topFivecoreValues} />
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
};

export default MyCoreValues;
