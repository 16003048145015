import React, { Suspense, useState } from "react";
import { useEffect } from "react";
import { BrowserRouter, HashRouter, Navigate, Route, Routes } from "react-router-dom";
import configDB from "../data/customizer/config";
import PrivateRoute from "./private-route";
import Signin from "../auth/signin";
import AppLayout from "../components/app";
import UnlockUser from "../pages/unlockUser";
import ResetPwd from "../pages/resetPwd";
import Error404 from "../pages/errors/error404";
import { routes } from "./layouts-routes";
import { Loader } from "react-feather";
import Signup from "../pages/signup";
import TwoStepVerification from "../pages/twostepverification";
import ForgetPass from "../pages/forgetpass";
import DailyAlert from "../components/common/dailyAlert/dratitudeAlert";
import AffirmationAlert from "../components/common/dailyAlert/afirmationAlert";

const MainRoutes = () => {
  const [currentUser, setCurrentUser] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
    const color = localStorage.getItem("color");
    const layout = localStorage.getItem("layout_version") || configDB.data.color.layout_version;
    if (layout) {
      document.body.classList.add(layout);
    }
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    // document.getElementById("color").setAttribute("href", `/assets/css/${color}.css`);

    return function cleanup() {
      abortController.abort();
    };
  }, []);

  return (
    <>

      <BrowserRouter >
        <Suspense fallback={<Loader />}>
        <DailyAlert />
        <AffirmationAlert/>
          <Routes>
            <Route path="/" element={<PrivateRoute />}>
              {routes.map(({ path, Component }, i) => (
                <Route element={<AppLayout />} key={i}>
                  <Route exact path={path} element={Component} />
                </Route>
              ))}
            </Route>
            <Route path="/login" element={<Signin />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgetpass" element={<ForgetPass />} />
            <Route path="/twostepverification" element={<TwoStepVerification />} />
            <Route path="/resetPwd" element={<ResetPwd />} />
            <Route path="/pages/unlockUser" element={<UnlockUser />} />
            <Route path="/pages/errors/error404" element={<Error404 />} />
          </Routes>
        </Suspense>
      </BrowserRouter>

    </>
  );
};

export default MainRoutes;
