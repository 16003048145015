import React from "react";
import { Modal, Button } from "react-bootstrap";

const DeleteModal = ({ isDeleteModalOpen, toggleDeleteModal, handleDeleteItem }) => (
  <Modal centered={true} show={isDeleteModalOpen} onHide={toggleDeleteModal}>
    <Modal.Header closeButton>
      <Modal.Title>Delete Daily Gratitude</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>Are you sure you want to delete this item?</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={toggleDeleteModal}>Cancel</Button>
      <Button variant="danger" onClick={handleDeleteItem}>Delete</Button>
    </Modal.Footer>
  </Modal>
);

export default DeleteModal;
