import React, { useState, useEffect } from "react";
import logo from "../assets/images/endless-logo.png";
import man from "../assets/images/user/user_default.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Login, LOGIN, YourName, Password, RememberMe } from "../constant";
import { Link, useNavigate } from "react-router-dom";
import Loginbg from "../assets/images/authimg/loginbg.svg";
import Google from "../assets/icoicon/google-icon.svg";
import Facebook from "../assets/icoicon/download.png";
import Logosvg from '../assets/images/logo/auth.svg';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { FaFacebook } from "react-icons/fa";
import FacebookLogin from 'react-facebook-login';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import API from '../utils';
import MyLoader from "../components/common/myloader";
import Imagelogoc from "../assets/images/logo/personalos.png";

const Signin = () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [userData, setProfileData] = useState({
    email: localStorage.getItem("email") || '',
    name: localStorage.getItem("name") || '',
    user_name: localStorage.getItem("user_name") || '',
    profileURL: localStorage.getItem("profileURL")
  });

  const navigate = useNavigate();

  const handleLoginSuccess = (userData) => {
    localStorage.setItem("login", JSON.stringify(userData));
    localStorage.setItem("profileURL", userData.profileURL);
  };

  const validate = () => {
    let emailError = "";
    let passwordError = "";

    if (!email) {
      emailError = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      emailError = "Email is invalid.";
    }

    if (!password) {
      passwordError = "Password is required.";
    } else if (password.length < 6) {
      passwordError = "Password must be at least 6 characters.";
    }

    if (emailError || passwordError) {
      setEmailError(emailError);
      setPasswordError(passwordError);
      return false;
    }

    return true;
  };


  const checkSubscription = async (userId) => {
    // try {
    //   const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.checkSubscription}/${userId}`, {
    //     headers: {
    //       Authorization: `${API.AUTHORIZATION_TOKEN}`
    //     }
    //   });

    //   // Assuming response.data.status will be "success" and message will be a descriptive text
    //   if (response.data.status === "success" && response.data.message.includes("not expired")) {
    //     return true; // Subscription is valid
    //   } else {
    //     return false; // Subscription is not valid
    //   }
    // } catch (error) {
    //   console.error('Error checking subscription:', error);
    //   return false; // Default to invalid subscription on error
    // }

    const formDataToSend = new FormData();
    formDataToSend.append('userid', userId);

    try {
      const response = await fetch(`${API.BASE_URL}${API.ENDPOINTS.check_subscription}`, {
        method: 'POST',
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`
        },
        body: formDataToSend
      });

      const data = await response.json();

      if (data.status === "success") {
        return true;
      } else {
        localStorage.setItem("isSubscriptionValid", false);
        return false;
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const loginAuth = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid) return;
    setLoading(true);

    const formDataToSend = new FormData();
    formDataToSend.append('email', email);
    formDataToSend.append('password', password);

    try {
      const response = await fetch(`${API.BASE_URL}${API.ENDPOINTS.login}`, {
        method: 'POST',
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`
        },
        body: formDataToSend
      });

      const data = await response.json();

      if (data.status === "success") {
        toast.success(data.message);
        localStorage.setItem("user_id", data.user_data.id);
        localStorage.setItem("user_name", data.user_data.user_name);
        localStorage.setItem("email", data.user_data.email);

        const userData = {
          email: data.user_data.email,
          name: data.user_data.name,
          profileURL: data.user_data.profileURL || man,
          user_name: data.user_data.user_name,
        };

        const isValidSubscription = await checkSubscription(data.user_data.id);
        localStorage.setItem("isSubscriptionValid", isValidSubscription);

        if (isValidSubscription) {
          toast.success(data.message);
          navigate(`/dashboard/default`);
          handleLoginSuccess(userData);
        } else {
          navigate(`/users/userEdit`);
          handleLoginSuccess(userData);
          toast.success(data.message);
        }
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error during login:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };


  const checkGoogleUser = async (email, name, sub) => {
    setLoading(true);

    const formDataToSend = new FormData();
    formDataToSend.append('email', email);
    formDataToSend.append('name', name);
    formDataToSend.append('sub', sub);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.googlelogin}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`
          }
        }
      );

      if (response.data.status === "success") {
        localStorage.setItem("googlelogin", true);
        localStorage.setItem("user_id", response.data.user_data.id);
        localStorage.setItem("user_name", response.data.user_data.user_name);
        localStorage.setItem("email", response.data.user_data.email);
        // localStorage.setItem("profileURL", response.data.profileURL || man);


        const isValidSubscription = await checkSubscription(response.data.user_data.id);
        localStorage.setItem("isSubscriptionValid", isValidSubscription);

        if (isValidSubscription) {
          navigate(`/dashboard/default`);
          toast.success(response.data.message);
          return { isValid: true, userData: response.data.user_data };


        } else {
          navigate(`/users/userEdit`);
          toast.success(response.data.message);
          return { isValid: true, userData: response.data.user_data };
        }

      } else {
        toast.error(response.data.message);
        return { isValid: false };
      }
    } catch (error) {
      console.error("Error checking Google user:", error);
      toast.error("An error occurred while checking Google user.");
      return { isValid: false, error };
    } finally {
      setLoading(false); // Hide loader
    }
  };


  const handleGoogleSuccess = async (response) => {
    try {
      const token = response.credential;
      const decoded = jwtDecode(token);

      const userData = {
        // profileURL: decoded.picture,
        email: decoded.email,
        name: decoded.name,
        sub: decoded.sub
      };

      const userCheckResponse = await checkGoogleUser(userData.email, userData.name, userData.sub);

      if (userCheckResponse.isValid) {
        handleLoginSuccess(userData);
      } else {
        toast.error("User validation failed. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred during the Google login process.");
      console.error("Google login error:", error);
    }
  };


  const handleGoogleFailure = (error) => {
    toast.error("Google login failed.");
    if (error.error === "popup_closed_by_user") {
      toast.error("Google sign-in was interrupted. Please try again.");
    } else {
      toast.error("Google login failed. Please try again.");
    }
  };

  const checkFacebookUser = async (email, name, sub) => {
    setLoading(true);

    const formDataToSend = new FormData();
    formDataToSend.append('email', email);
    formDataToSend.append('name', name);
    formDataToSend.append('sub', sub);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.facebooklogin}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`
          }
        }
      );

      if (response.data.status === "success") {
        localStorage.setItem("facebookLogin", true);
        localStorage.setItem("user_id", response.data.user_data.id);
        localStorage.setItem("user_name", response.data.user_data.user_name);
        localStorage.setItem("email", response.data.user_data.email);
        localStorage.setItem("profileURL", response.data.user_data.profileURL || man);

        // console.log("Checking Facebook user with email:", response.data.user_data);
        // navigate(`/dashboard/default`);
        // toast.success(response.data.message);
        // return { isValid: true, userData: response.data.user_data };
        const isValidSubscription = await checkSubscription(response.data.user_data.id);
        localStorage.setItem("isSubscriptionValid", isValidSubscription);

        if (isValidSubscription) {
          navigate(`/dashboard/default`);
          toast.success(response.data.message);
          return { isValid: true, userData: response.data.user_data };

        } else {
          navigate(`/users/userEdit`);
          toast.success(response.data.message);
          return { isValid: true, userData: response.data.user_data };
        }

      } else {
        toast.error(response.data.message);
        return { isValid: false };
      }
    } catch (error) {
      console.error("Error checking Facebook user:", error);
      toast.error("An error occurred while checking Facebook user.");
      return { isValid: false, error };
    } finally {
      setLoading(false);
    }
  };

  const responseFacebook = async (response) => {
    try {
      const userData = {
        profileURL: response.picture ? response.picture.data.url : '',
        email: response.email,
        name: response.name,
        sub: response.id
      };

      const userCheckResponse = await checkFacebookUser(userData.email, userData.name, userData.sub);

      if (userCheckResponse.isValid) {
        handleLoginSuccess(userData);

      } else {
        toast.error("User validation failed. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred during the Facebook login process.");
      console.error("Facebook login error:", error);
    }
  };

  const handleFacebookFailure = (error) => {
    console.error("Facebook login failed:", error);
    toast.error("Facebook login failed.");
  };

  useEffect(() => {
    localStorage.setItem("profileURL", userData.profileURL);
    localStorage.getItem("user_id", userData.user_id);
    localStorage.getItem("user_name", userData.user_name);
  }, [userData]);

  return (
    <>
      <MyLoader active={loading} >
        <div className="login-container d-flex">
          <div className="background-image">
            <div className="p-3 text-white justify-content-end text-end align-items-center">
              <div className="d-flex">
                <div className=" d-flex align-items-lg-center me-lg-4">
                  <img src={Imagelogoc} className="Logosvg-main" alt="background" />
                </div>
                <div className="">
                  <h2 className="text-white mb-0 fw-bold ">Your Personal Operating System to<br /> Live Life More Intentionally</h2>
                  <h1 className="display-4 fw-bold ">Personal OS</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="login-box">
            <div className="auth-heding-main">
              <h2 className="auth-heding">Login</h2>
              <p className="auth-peregraft">Enter your Username and Password</p>
            </div>
            <form>
              <div className="form-group">
                <label className="col-form-label pt-0">Email</label>
                <input
                  className={`form-control ${emailError ? 'is-invalid' : ''}`}
                  type="email"
                  name="email"
                  value={email}
                  placeholder="Enter Your Email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError("");
                  }}
                />
                {emailError && <div className="text-danger">{emailError}</div>}
              </div>
              <div className="form-group">
                <label className="col-form-label">{Password}</label>
                <input
                  className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordError("");
                  }}
                  placeholder="**********"
                />
                {passwordError && <div className="text-danger">{passwordError}</div>}
              </div>
              <div className="checkbox-solid-info checkbox d-flex justify-content-between">
                <div>

                </div>
                <div className="password-help">
                  <Link to="/forgetpass" >Forgot password?</Link>
                </div>
              </div>

              {/* <button type="submit" className="login-button">Login</button> */}
              <div className="form-group  mt-3 mb-0 d-grid">
                <button className="login-button" onClick={loginAuth} >
                  {Login}
                </button>
              </div>

              <div className="row my-4">
                <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center my-2">
                  <GoogleOAuthProvider clientId="52287124339-ibcfdq2tuni4d55li1eu05bnn0dhk61h.apps.googleusercontent.com">
                    <GoogleLogin
                      onSuccess={handleGoogleSuccess}
                      onFailure={handleGoogleFailure}
                      render={(renderProps) => (
                        <button
                          className="btn text-dark border fw-normal d-flex align-items-center justify-content-center rounded-2 py-8"
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                        >
                          <img src={Google} alt="google-icon" className="img-fluid me-2" width="18" height="18" />
                          <span className="flex-shrink-0">with Google</span>
                        </button>
                      )}
                    />
                  </GoogleOAuthProvider>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center my-2">
                  <FacebookLogin
                    appId="511836008262121"
                    fields="name,email,picture"
                    callback={responseFacebook}
                    onFailure={handleFacebookFailure}
                    icon={(<FaFacebook className="mx-2 Facebook-icon" />)}
                    render={(renderProps) => (
                      <button
                        className="btn text-dark border fw-normal d-flex align-items-center justify-content-center rounded-2 py-8"
                        onClick={renderProps.onClick}
                      >
                        <img src={Facebook} alt="facebook-icon" className="img-fluid me-2" width="18" height="18" />
                        <span className="flex-shrink-0">with Facebook</span>
                      </button>
                    )}
                  />
                </div>
              </div>

              <div className=" account-auth text-center my-3">
                <p className="mb-0">Don't have an account? <Link to="/signup" className="">Signup</Link></p>
              </div>

            </form>
          </div>

        </div>
      </MyLoader>
      <ToastContainer />
    </>
  );
};

export default Signin;
