import React, { useEffect, useState } from 'react';
import { FaHatCowboySide, FaTasks } from 'react-icons/fa';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from '../../../utils';
import HabitTable from "./habittable";
import { Dropdown, DropdownButton } from 'react-bootstrap';
import moment from "moment";

const DashBoardHabit = () => {
    const [selectedHabitIndex, setSelectedHabitIndex] = useState(0);
    const [selectedMonth, setSelectedMonth] = useState(moment().month() );
    const [selectedYear, setSelectedYear] = useState(moment().year());
    const [checkedDays, setCheckedDays] = useState({});
    const uid = localStorage.getItem('user_id') || '';
    const [habits, setHabits] = useState([]);
    const [tracker, setTracker] = useState([]);

    const toggleHabit = (day, habit) => {
        const newTracker = [...tracker];
        newTracker[day][habit] = !newTracker[day][habit];
        setTracker(newTracker);
    };

    useEffect(() => {
        // Fetch data for the current month when component mounts
        handleMonthChange(selectedMonth);
    }, []);

    const handleMonthChange = async (monthIndex) => {
        setSelectedMonth(monthIndex);
        const formDataToSend = new FormData();
        formDataToSend.append('selected_month', monthIndex);
        formDataToSend.append('user_id', uid);
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_data_from_table_monthwise}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: "FFxx21$$21life360AAA212TRRR",
                    },
                }
            );
    
            if (response.data && response.data.status === "success") {
                const habitsWithDaily = response.data.data || []; 
                setHabits(habitsWithDaily);
                const { data } = response.data;
                const checkedDaysObj = {};
                data.forEach(item => {
                    if (item.status === "completed") {
                        if (!checkedDaysObj[item.habit_id]) {
                            checkedDaysObj[item.habit_id] = {};
                        }
                        checkedDaysObj[item.habit_id][moment(item.track_date).date()] = true;
                    }
                });
                setCheckedDays(checkedDaysObj);
            } else {
                const errorMessage = response.data ? response.data.message : "Unknown error";
                toast.error(errorMessage);
            }
        } catch (error) {
            console.error("Fetch checked days error", error);
            toast.error("Failed to fetch checked days.");
        }
    };
    
    return (
        <>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h5><FaHatCowboySide /> Habit Tracking</h5>
                    <DropdownButton
                        id="dropdown-basic-button"
                        title={moment.months(selectedMonth)}
                        variant="success"
                        className="align-items-center main-menu"
                    >
                        {moment.months().map((month, index) => (
                            <Dropdown.Item key={index} onClick={() => handleMonthChange(index)}>
                                <FaTasks style={{color:"black"}}/> {month}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                </div>
                <div className="card-body">
                    <HabitTable habits={habits} toggleHabit={toggleHabit} checkedDays={checkedDays} />
                </div>
            </div>
        </>
    );
};

export default DashBoardHabit;
