import React, { Fragment, useState } from 'react';
import { Login, Password } from '../constant';
import { FaFacebook, FaHeart } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import Google from "../assets/icoicon/google-icon.svg";
import Facebook from "../assets/icoicon/download.png";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { ToastContainer, toast } from "react-toastify";
import FacebookLogin from 'react-facebook-login';
import axios from 'axios';
import API from '../utils';
import { jwtDecode } from 'jwt-decode';
import man from "../assets/images/user/user_default.jpg";
import MyLoader from '../components/common/myloader';
import CardDetailsModal from '../auth/cardDetailsmodal/cardDetails';
import Imagelogoc from "../assets/images/logo/personalos.png";

const Signup = () => {
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        sub: '',
    });
    const [validationErrors, setValidationErrors] = useState({});
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));

        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleLoginSuccess = (userData) => {
        localStorage.setItem("login", JSON.stringify(userData));
        localStorage.setItem("profileURL", userData.profileURL || man);
        localStorage.setItem("email", userData.email);
        localStorage.setItem("user_name", userData.user_name);
    };

    const checkSubscription = async (userId) => {
        // try {
        //   const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.checkSubscription}/${userId}`, {
        //     headers: {
        //       Authorization: `${API.AUTHORIZATION_TOKEN}`
        //     }
        //   });

        //   // Assuming response.data.status will be "success" and message will be a descriptive text
        //   if (response.data.status === "success" && response.data.message.includes("not expired")) {
        //     return true; // Subscription is valid
        //   } else {
        //     return false; // Subscription is not valid
        //   }
        // } catch (error) {
        //   console.error('Error checking subscription:', error);
        //   return false; // Default to invalid subscription on error
        // }



        const formDataToSend = new FormData();
        formDataToSend.append('userid', userId);

        try {
            const response = await fetch(`${API.BASE_URL}${API.ENDPOINTS.check_subscription}`, {
                method: 'POST',
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`
                },
                body: formDataToSend
            });

            const data = await response.json();

            if (data.status === "success") {
                return true;
            } else {
                localStorage.setItem("isSubscriptionValid", false);
                return false;
            }
        } catch (error) {
            console.error("Error during login:", error);
        }
    };


    const handleSubmit = async (e) => {

        e.preventDefault();
        const errors = {};

        if (!formData.username) {
            errors.username = 'Name is required';
        }

        if (!formData.email) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = 'Email is invalid';
        }

        if (!formData.password) {
            errors.password = 'Password is required';
        } else if (formData.password.length < 6) {
            errors.password = 'Password must be at least 6 characters';
        }

        setValidationErrors(errors);

        if (Object.keys(errors).length === 0) {


            const formDataToSend = new FormData();

            formDataToSend.append('email', formData.email);
            try {
                const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.email_exist}`, formDataToSend, {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    },
                });

                if (response.data.status === "success") {
                    setShowModal(true);
                } else {
                    toast.error(response.data.message);
                    setShowModal(false);
                }
            } catch (error) {
                // toast.error("Signup failed. Please try again.");
            }

        }
    };

    const handleModalSubmit = async (cardDetails) => {
        setLoading(true);

        const formDataToSend = new FormData();
        formDataToSend.append('username', formData.username);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('password', formData.password);
        formDataToSend.append('price', 100);
        formDataToSend.append('card_number', cardDetails.card_number);
        formDataToSend.append('exp_month', cardDetails.exp_month);
        formDataToSend.append('exp_year', cardDetails.exp_year);
        formDataToSend.append('cvc', cardDetails.cvc);

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.signup}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`
                },
            });

            if (response.data.status === "success") {
                toast.success(response.data.message);
                try {
                    const loginResponse = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.login}`,
                        formDataToSend,
                        {
                            headers: {
                                Authorization: `${API.AUTHORIZATION_TOKEN}`,
                                'Content-Type': 'multipart/form-data'
                            },
                        }
                    );
                    if (loginResponse.data.status === "success") {
                        localStorage.setItem("login", true);
                        localStorage.setItem("user_id", loginResponse.data.user_data.id);
                        localStorage.setItem("user_name", loginResponse.data.user_data.user_name);
                        localStorage.setItem("email", loginResponse.data.user_data.email);

                        const userData = {
                            email: loginResponse.data.user_data.email,
                            name: loginResponse.data.user_data.name,
                            profileURL: loginResponse.data.user_data.profileURL || man,
                            user_name: loginResponse.data.user_data.user_name,
                        };

                        const isValidSubscription = await checkSubscription(loginResponse.data.user_data.id);
                        localStorage.setItem("isSubscriptionValid", isValidSubscription);

                        if (isValidSubscription) {
                            navigate(`/dashboard/default`);
                            handleLoginSuccess(userData);
                            toast.success(loginResponse.data.message);

                        } else {
                            navigate(`/users/userEdit`);
                            handleLoginSuccess(userData);
                            toast.success(loginResponse.data.message);
                        }
                    } else {
                        toast.error(loginResponse.data.message);
                    }
                } catch (error) {
                    toast.error("Signup failed. Please try again.");
                }
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Signup failed. Please try again.");
        } finally {
            setLoading(false); // Hide loader
            setShowModal(false); // Hide modal
        }
    };

    const checkGoogleUser = async (email, name, sub) => {
        setLoading(true);

        const formDataToSend = new FormData();
        formDataToSend.append('email', email);
        formDataToSend.append('name', name);
        formDataToSend.append('sub', sub);

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.googlelogin}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    }
                }
            );

            if (response.data.status === "success") {
                localStorage.setItem("login", true);
                localStorage.setItem("user_id", response.data.user_data.id);
                localStorage.setItem("user_name", response.data.user_data.user_name);
                localStorage.setItem("email", response.data.user_data.email);
                localStorage.setItem("profileURL", response.data.user_data.profileURL || man);

                navigate('/dashboard/default');
                toast.success(response.data.message);
                return { isValid: true, userData: response.data.user_data };
            } else {
                toast.error(response.data.message);
                return { isValid: false };
            }
        } catch (error) {
            console.error("Error checking Google user:", error);
            toast.error("An error occurred while checking Google user.");
            return { isValid: false, error };
        }
    };



    const handleGoogleSuccess = async (response) => {
        try {
            const token = response.credential;
            const decoded = jwtDecode(token);

            const userData = {
                profileURL: decoded.picture,
                email: decoded.email,
                name: decoded.name,
                sub: decoded.sub
            };

            // Call checkGoogleUser with email, name, and sub
            const userCheckResponse = await checkGoogleUser(userData.email, userData.name, userData.sub);

            // Assuming userCheckResponse contains necessary validation results
            if (userCheckResponse.isValid) {
                toast.success("User validation True");

            } else {
                toast.error("User validation failed. Please try again.");
            }
        } catch (error) {
            toast.error("An error occurred during the Google login process.");
            console.error("Google login error:", error);
        }
    };


    const handleGoogleFailure = (error) => {
        toast.error("Google login failed.");
        if (error.error === "popup_closed_by_user") {
            toast.error("Google sign-in was interrupted. Please try again.");
        } else {
            toast.error("Google login failed. Please try again.");
        }
    };

    const checkFacebookUser = async (email, name, sub) => {
        setLoading(true);

        const formDataToSend = new FormData();
        formDataToSend.append('email', email);
        formDataToSend.append('name', name);
        formDataToSend.append('sub', sub);

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.facebooklogin}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    }
                }
            );

            if (response.data.status === "success") {
                localStorage.setItem("login", true);
                localStorage.setItem("profileURL", response.data.user_data.profileURL || man);
                localStorage.setItem("user_id", response.data.user_data.id);
                localStorage.setItem("user_id", response.data.user_data.id);
                localStorage.setItem("user_name", response.data.user_data.user_name);
                localStorage.setItem("email", response.data.user_data.email);

                navigate(`/dashboard/default`);
                toast.success(response.data.message);
                return { isValid: true, userData: response.data.user_data };
            } else {
                toast.error(response.data.message);
                return { isValid: false };
            }
        } catch (error) {
            console.error("Error checking Facebook user:", error);
            toast.error("An error occurred while checking Facebook user.");
            return { isValid: false, error };
        } finally {
            setLoading(false); // Hide loader
        }
    };


    const responseFacebook = async (response) => {
        try {
            const userData = {
                profileURL: response.picture ? response.picture.data.url : '',
                email: response.email,
                name: response.name,
                sub: response.id  // Assuming 'id' is the equivalent of 'sub' for Facebook
            };

            // Call checkFacebookUser with email, name, and sub
            const userCheckResponse = await checkFacebookUser(userData.email, userData.name, userData.sub);

            // Handle the response from checkFacebookUser
            if (userCheckResponse.isValid) {
                toast.success("User validation True");
            } else {
                toast.error("User validation failed. Please try again.");
            }
        } catch (error) {
            toast.error("An error occurred during the Facebook login process.");
            console.error("Facebook login error:", error);
        }
    };

    const handleFacebookFailure = (error) => {
        console.error("Facebook login failed:", error);
        toast.error("Facebook login failed.");
    };

    return (
        <>
            <MyLoader active={loading} >
                <Fragment>
                    <div className="login-container d-flex">
                        <div className="background-image">
                            <div className="p-3 text-white justify-content-end text-end align-items-center">
                                <div className="d-flex">
                                    <div className=" d-flex align-items-lg-center me-lg-4">
                                        <img src={Imagelogoc} className="Logosvg-main" alt="background" />
                                    </div>
                                    <div className="">
                                        <h2 className="text-white mb-0 fw-bold ">Your Personal Operating System to<br /> Live Life More Intentionally</h2>
                                        <h1 className="display-4 fw-bold ">Personal OS</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="singup-box">
                            <div className="auth-heding-main-singup">
                                <h2 className="auth-heding">SignUp</h2>
                                <p className="auth-peregraft">Enter your Email and Password For Signup</p>
                            </div>
                            <form className="theme-form" onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label className="col-form-label">Name</label>
                                    <input
                                        className={`form-control ${validationErrors.username ? 'is-invalid' : ''}`}
                                        name="username"
                                        value={formData.username}
                                        onChange={handleChange}
                                        type="text"
                                        placeholder="Enter Your Name"
                                    />
                                    {validationErrors.username && (
                                        <div className="text-danger">{validationErrors.username}</div>
                                    )}
                                </div>

                                <div className="form-group">
                                    <label className="col-form-label">Email</label>
                                    <input
                                        className={`form-control ${validationErrors.email ? 'is-invalid' : ''}`}
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        type="text"
                                        placeholder="Enter Your Email"
                                    />
                                    {validationErrors.email && (
                                        <div className="text-danger">{validationErrors.email}</div>
                                    )}
                                </div>

                                <div className="form-group">
                                    <label className="col-form-label">{Password}</label>
                                    <input
                                        className={`form-control ${validationErrors.password ? 'is-invalid' : ''}`}
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        type="password"
                                        placeholder="**********"
                                    />
                                    {validationErrors.password && (
                                        <div className="text-danger">{validationErrors.password}</div>
                                    )}
                                </div>

                                <div className="form-group">
                                    <div className=" p-0 suscribe-us">

                                        <b>Subscribe Us @ $10</b>
                                    </div>
                                </div>

                                <div className="form-group  mt-3 mb-0 d-grid">
                                    <button className="login-button" type="submit">
                                        Sign up
                                    </button>
                                </div>

                                <div className="divider d-flex align-items-center my-3">
                                    <p className="text-center fw-bold mx-3 mb-0">OR</p>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center my-1">
                                        <GoogleOAuthProvider clientId="52287124339-ibcfdq2tuni4d55li1eu05bnn0dhk61h.apps.googleusercontent.com">
                                            <GoogleLogin
                                                onSuccess={handleGoogleSuccess}
                                                onFailure={handleGoogleFailure}
                                                render={(renderProps) => (
                                                    <button
                                                        className="btn text-dark border fw-normal d-flex align-items-center justify-content-center rounded-2 py-8"
                                                        onClick={renderProps.onClick}
                                                        disabled={renderProps.disabled}
                                                    >
                                                        <img src={Google} alt="google-icon" className="img-fluid me-2" width="18" height="18" />
                                                        <span className="flex-shrink-0">with Google</span>
                                                    </button>
                                                )}
                                            />
                                        </GoogleOAuthProvider>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center my-1">
                                        <FacebookLogin
                                            appId="511836008262121"
                                            fields="name,email,picture"
                                            callback={responseFacebook}
                                            onFailure={handleFacebookFailure}
                                            icon={(<FaFacebook className="mx-2 Facebook-icon" />)}
                                            render={(renderProps) => (
                                                <button
                                                    className="btn text-dark border fw-normal d-flex align-items-center justify-content-center rounded-2 py-8"
                                                    onClick={renderProps.onClick}
                                                >
                                                    <img src={Facebook} alt="facebook-icon" className="img-fluid me-2" width="18" height="18" />
                                                    <span className="flex-shrink-0">with Facebook</span>
                                                </button>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="account-auth text-center my-3">
                                    <p className="mb-0">Are you already user?<Link to="/login"> {Login} </Link></p>
                                </div>
                            </form>
                        </div>
                    </div>
                    <ToastContainer />
                </Fragment>
                <CardDetailsModal
                    show={showModal}
                    handleClose={() => setShowModal(false)}
                    handleSubmit={handleModalSubmit}
                />
            </MyLoader >
        </>
    );
};

export default Signup;
