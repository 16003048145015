import React, { useState, useEffect } from "react";
import { Button, Col, Row, Form, Modal } from "react-bootstrap";
import Chart from "react-google-charts";
import moment from "moment";
import { FaRegEdit, FaTrash } from "react-icons/fa";
import axios from 'axios';
import AddHabitModal from "./habittrackermodal/addHabitModal";
import DeleteConfirmationModal from "./habittrackermodal/DeleteHabitModal";
import API from "../../utils";

const HabitTracker = () => {
  const uid = localStorage.getItem("user_id");
  const [addHabitModal, setAddHabitModal] = useState(false);
  const [habits, setHabits] = useState([]);
  const [view, setView] = useState("thisMonth");
  const [selectedMonth, setSelectedMonth] = useState(moment().month());
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [checkedDays, setCheckedDays] = useState({});
  const [formData, setFormData] = useState({ habit: "" });
  const [validationErrors, setValidationErrors] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    fetchHabit();
  }, []);

  useEffect(() => {
    if (habits.length > 0) {
      fetchCheckedDays();
    }
  }, [selectedMonth, selectedYear, habits]);

  const fetchHabit = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.myHabits}/${uid}`, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "success") {
        const habitsWithDaily = response.data.habits.map(habit => ({
          ...habit,
          daily: habit.daily || Array(moment().daysInMonth()).fill(false)
        }));
        setHabits(habitsWithDaily);
      } else {
        // toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Fetch habits error', error);
      // toast.error("Failed to fetch habits.");
    }
  };

  const fetchCheckedDays = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    formDataToSend.append('month', selectedMonth + 1);
    formDataToSend.append('year', selectedYear);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.getHabitDate}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        const { data } = response.data;
        const updatedCheckedDays = {};
        data.forEach(item => {
          if (item.status === "completed") {
            if (!updatedCheckedDays[item.habit_id]) {
              updatedCheckedDays[item.habit_id] = {};
            }
            updatedCheckedDays[item.habit_id][item.track_date] = true;
          }
        });
        setCheckedDays(updatedCheckedDays);
      } else {
        // toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Fetch checked days error", error);
      // toast.error("Failed to fetch checked days.");
    }
  };

  const handleCheckboxChange = async (habitIndex, dayIndex) => {
    const newHabits = [...habits];
    const habitId = newHabits[habitIndex].id;
    const date = moment().month(selectedMonth).startOf('month').add(dayIndex, 'days').format('YYYY-MM-DD');
    const status = !newHabits[habitIndex].daily[dayIndex] ? 'completed' : 'not_completed';

    newHabits[habitIndex].daily[dayIndex] = !newHabits[habitIndex].daily[dayIndex];
    setHabits(newHabits);

    const formDataToSend = new FormData();
    formDataToSend.append('habit_id', habitId);
    formDataToSend.append('track_dates', date);
    formDataToSend.append('status', status);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.habictracker}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        // toast.success(`Habit status updated for ${date}`);
        const updatedCheckedDays = { ...checkedDays };
        if (!updatedCheckedDays[habitId]) {
          updatedCheckedDays[habitId] = {};
        }
        if (status === 'completed') {
          updatedCheckedDays[habitId][date] = true;
        } else {
          delete updatedCheckedDays[habitId][date];
        }
        setCheckedDays(updatedCheckedDays);
      } else {
        // toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Update habit status error", error);
      // toast.error("Failed to update habit status.");
    }
  };

  const handleAddHabitSubmit = async () => {
    const errors = {};
    if (!formData.habit.trim()) {
      errors.habit = "This field is required";
    }
    if (Object.keys(errors).length === 0) {
      const formDataToSend = new FormData();
      formDataToSend.append('user_id', uid);
      formDataToSend.append('habit', formData.habit);

      try {
        const response = await axios.post(
          `${API.BASE_URL}${API.ENDPOINTS.addHabit}`,
          formDataToSend,
          {
            headers: {
              Authorization: `${API.AUTHORIZATION_TOKEN}`,
            },
          }
        );

        if (response.data.status === "success") {
          const newHabit = { habit: formData.habit, daily: Array(moment().daysInMonth()).fill(false) };
          setHabits(prevHabits => [...prevHabits, newHabit]);
          setAddHabitModal(false);
          setFormData({ habit: "" });
          fetchHabit();
          // toast.success("Habit added successfully");
        } else {
          // toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Add habit error", error);
        // toast.error("Failed to add habit.");
      }
    } else {
      setValidationErrors(errors);
    }
  };

  const handleDelete = (item) => {
    setSelectedItem(item);
    setIsDeleteModalOpen(true);
  };

  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const handleDeleteItem = async () => {
    try {
      const url = `${API.BASE_URL}${API.ENDPOINTS.deleteHabit}/${selectedItem.id}`;
      const headers = {
        Authorization: `${API.AUTHORIZATION_TOKEN}`,
      };

      const response = await axios.get(url, { headers });

      if (response.data.status === "success") {
        // toast.success(response.data.message);
        fetchHabit();
        toggleDeleteModal();
      } else {
        // toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Delete habit error', error);
      // toast.error("Failed to delete habit.");
    }
  };

  const handleViewSelect = (event) => {
    setView(event.target.value);
  };

  const handleMonthSelect = (event) => {
    setSelectedMonth(parseInt(event.target.value, 10));
  };

  const renderCheckboxes = (habit, habitIndex) => {
    const daysInMonth = moment().month(selectedMonth).daysInMonth();
    const habitId = habit.id;
    const habitCheckedDays = checkedDays[habitId] || {};

    let checkboxes = [];
    switch (view) {
      case "today":
        const today = moment().date() - 1;
        checkboxes.push(
          <div key={today} className="d-flex flex-column align-items-center mx-2 mb-2">
            <span>{moment().format("ddd")}</span>
            <span>{moment().format("D")}</span>
            <input
              type="checkbox"
              className="checkbox_animated"
              checked={habitCheckedDays[moment().format("YYYY-MM-DD")] || false}
              onChange={() => handleCheckboxChange(habitIndex, today)}
            />
          </div>
        );
        break;
      case "thisWeek":
        const startOfWeek = moment().startOf("week").day();
        const endOfWeek = moment().endOf("week").day();
        for (let i = startOfWeek; i <= endOfWeek; i++) {
          const date = moment().startOf("week").add(i - startOfWeek, "days").date() - 1;
          if (date >= 0 && date < daysInMonth) {
            const dateFormatted = moment().month(selectedMonth).date(date + 1).format("YYYY-MM-DD");
            checkboxes.push(
              <div key={date} className="d-flex flex-column align-items-center mx-2 mb-2">
                <span>{moment().startOf("week").add(i - startOfWeek, "days").format("ddd")}</span>
                <span>{date + 1}</span>
                <input
                  type="checkbox"
                  className="checkbox_animated"
                  checked={habitCheckedDays[dateFormatted] || false}
                  onChange={() => handleCheckboxChange(habitIndex, date)}
                />
              </div>
            );
          }
        }
        break;
      case "thisMonth":
      default:
        for (let i = 0; i < daysInMonth; i++) {
          const dateFormatted = moment().month(selectedMonth).date(i + 1).format("YYYY-MM-DD");
          checkboxes.push(
            <div key={i} className="d-flex flex-column align-items-center mx-2 mb-2">
              <span>{moment().month(selectedMonth).date(i + 1).format("ddd")}</span>
              <span>{i + 1}</span>
              <input
                type="checkbox"
                className="checkbox_animated"
                checked={habitCheckedDays[dateFormatted] || false}
                onChange={() => handleCheckboxChange(habitIndex, i)}
              />
            </div>
          );
        }
        break;
    }
    return checkboxes;
  };

  const renderCharts = () => {
    if (habits.length === 0) {
      return (
        <div className="text-center mt-4">
          <h5><b>No Habits Added.</b></h5>
        </div>
      );
    }
    return habits.map((habit, habitIndex) => {
      const daysInMonth = moment().month(selectedMonth).daysInMonth();
      let completedCount = 0;
      let notCompletedCount = 0;

      Array.from({ length: daysInMonth }, (_, i) => {
        const day = i + 1;
        const dateFormatted = moment().month(selectedMonth).date(day).format("YYYY-MM-DD");
        const isCompleted = checkedDays[habit.id]?.[dateFormatted] || false;

        if (isCompleted) {
          completedCount += 1;
        } else {
          notCompletedCount += 1;
        }
      });

      const data = [
        ["Status", "Count"],
        ["Completed", completedCount],
        ["Not Completed", notCompletedCount],
      ];
      return (

        <Row>
          <Col lg={6} md={6} className="mt-3 mb-3">


            <div key={habitIndex} className={`mb-3 p-3 habittracker-calendar `}>
              <div className="">
                <div className="habittracker">
                  <div className="row p-2">
                    <div className="col-md-8">
                      <h6 className="habit-calendar-title">{habit.habit}</h6>
                    </div>
                    <div className="col-md-4 text-end">
                      <FaTrash
                        className="mx-2 text-end habittracker-delete-svg"
                        onClick={() => handleDelete(habit)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-wrap">{renderCheckboxes(habit, habitIndex)}</div>  </div>

          </Col>
          <Col lg={6} md={6}>
            <div key={habit.id} className="mb-4">

              <Chart
                width={"100%"}
                height={"400px"}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={data}
                options={{
                  title: `${habit.habit} - Habit Tracker`,
                  legend: "none",
                  pieSliceText: "label",
                  colors: ["#90BE6D", "#F94144"], 
                  pieStartAngle: 100,
                }}
                rootProps={{ "data-testid": "4" }}
              />

            </div>
          </Col>
        </Row>
      );
    });
  };

  return (
    <div className="container-fluid">
      <Row>
        <Col lg={12} md={12}>
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-md-6 align-items-center d-flex">
                  <h5>Habit Tracker</h5>
                </div>
                <div className="col-md-6 text-end">
                  <Button className="btn-success main-buttons" onClick={() => setAddHabitModal(true)} >
                    Add Habit To Track
                  </Button>
                </div>
              </div>
            </div>
            <div className="card-body">
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group>
                    <Form.Label><b>View by:</b></Form.Label>
                    <Form.Control as="select" value={view} onChange={handleViewSelect}>
                      <option value="thisMonth">This Month</option>
                      <option value="thisWeek">This Week</option>
                      <option value="today">Today</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group>
                    <Form.Label><b>Select Month:</b></Form.Label>
                    <Form.Control as="select" value={selectedMonth} onChange={handleMonthSelect}>
                      {moment.months().map((month, index) => (
                        <option key={index} value={index}>
                          {month}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>

              </Row>
              {renderCharts()}
            </div>
          </div>
        </Col>
      </Row>
      <AddHabitModal
        show={addHabitModal}
        onHide={() => setAddHabitModal(false)}
        onSubmit={handleAddHabitSubmit}
        formData={formData}
        setFormData={setFormData}
        validationErrors={validationErrors}
        setValidationErrors={setValidationErrors}
      />
      <DeleteConfirmationModal
        show={isDeleteModalOpen}
        onHide={toggleDeleteModal}
        selectedItem={selectedItem}
        handleDeleteItem={handleDeleteItem}
      />
    </div>
  );
};

export default HabitTracker;
