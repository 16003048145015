import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const AddModal = ({ isOpen, toggle, handleAdd, core, setCore, coreerror, setCoreerror, coreBorderError, setCoreBorderError }) => {
  const handleInputChange = (e) => {
    setCore(e.target.value);
    if (coreerror) {
      setCoreerror('');
    }
    if (coreBorderError) {
      setCoreBorderError(false);
    }
  };

  const handleSubmit = () => {
    if (!core) {
      setCoreerror('This field is required.');
      setCoreBorderError(true);
      return;
    }
    handleAdd();
  };

  return (
    <Modal centered={true} show={isOpen} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>Add Those I Want to Serve</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="theme-form">
          <div className="form-group">
            <label className="col-form-label pt-0" htmlFor="Corevalue">Those I Want to Serve</label>
            <input
              className={`form-control ${coreBorderError ? 'input-error' : ''}`}
              type="text"
              // value={core}
              name='core'
              onChange={handleInputChange}
              placeholder="Enter Those I Want to Serve"
            />
            {coreerror && <div className="text-danger">{coreerror}</div>}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-primary me-1" onClick={toggle}>Cancel</Button>
        <Button className="btn btn-secondary" onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddModal;
