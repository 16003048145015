import React, { Fragment, useState } from 'react';
import { NewPassword, RetypePassword } from "../constant";
import { Link } from 'react-router-dom';
import TwoStepVerificationbg from "../assets/images/authimg/twostepverification.svg";
import Resetpwdbg from "../assets/images/authimg/resetpwd.svg";
import Logosvg from '../assets/images/logo/auth.svg';

const ResetPwd = () => {
    const [formData, setFormData] = useState({
        newPassword: '',
        retypePassword: ''
    });

    const [validationErrors, setValidationErrors] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
        setValidationErrors('');
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = {};

        if (!formData.newPassword) {
            errors.newPassword = 'New password is required';
        } else if (formData.newPassword.length < 6) {
            errors.newPassword = 'Password must be at least 6 characters';
        }

        if (!formData.retypePassword) {
            errors.retypePassword = 'Please retype your password';
        } else if (formData.newPassword !== formData.retypePassword) {
            errors.retypePassword = 'Passwords do not match';
        }

        if (Object.keys(errors).length === 0) {
            // Submit the form
            console.log('Password reset', formData);
        } else {
            setValidationErrors(errors);
        }
    };

    return (
        <Fragment>
            <div className="auth-page-wrapper">
                <div className="page-wrapper">
                    <div className="container-fluid">
                        <div className="authentication-main">
                            <div className="row m-0">
                                <div className="col-md-12 p-0">
                                    <div className="auth-innerright">
                                        <div className="authentication-box">

                                            <div className="card  mt-4">
                                                <div className='row m-0'>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 login-main ">
                                                        {/* className="login-bg" */}
                                                        <div className="h-100">
                                                            <img src={Resetpwdbg} className="Resetpwdbg-bg" alt="login-Bg"></img>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 col-sm-12 ">
                                                        <div className="p-lg-5 p-md-3 p-sm-3">
                                                            <div className="text-center">
                                                                <img className="Logosvg-auth" src={Logosvg} alt="logo-svg" />
                                                                <span className='lifelogosignin'>Life360</span>
                                                            </div>
                                                            <div>
                                                                <h5 className="text-center text-primary text-login-h5">Reset Password 🔒</h5>
                                                                <p className="text-center text-muted ">for test@gmail.com</p>
                                                            </div>
                                                            <form className="theme-form" onSubmit={handleSubmit}>
                                                                <div className="form-group">
                                                                    <label className="col-form-label">{NewPassword}</label>
                                                                    <input
                                                                        className={`form-control ${validationErrors.newPassword ? 'is-invalid' : ''}`}
                                                                        name="newPassword"
                                                                        type="password"
                                                                        placeholder="············"
                                                                        value={formData.newPassword}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {validationErrors.newPassword && (
                                                                        <div className="text-danger">{validationErrors.newPassword}</div>
                                                                    )}
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="col-form-label">{RetypePassword}</label>
                                                                    <input
                                                                        className={`form-control ${validationErrors.retypePassword ? 'is-invalid' : ''}`}
                                                                        name="retypePassword"
                                                                        type="password"
                                                                        placeholder="············"
                                                                        value={formData.retypePassword}
                                                                        onChange={handleChange}
                                                                    />
                                                                    {validationErrors.retypePassword && (
                                                                        <div className="text-danger">{validationErrors.retypePassword}</div>
                                                                    )}
                                                                </div>

                                                                <div className="form-group  mt-3 mb-0 d-grid">
                                                                    <button className="btn btn-primary" type="submit">
                                                                        Set New Password
                                                                    </button>
                                                                </div>
                                                            </form>
                                                            <div className="text-center">
                                                                <p className="mb-0 mt-4">Wait, I remember my password...
                                                                    <Link to="/login" className="fw-semibold text-primary text-decoration-underline">Click here</Link>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ResetPwd;
