import React, { useState, useEffect, useRef } from "react";
import { Button , Spinner ,ProgressBar } from "react-bootstrap";
import { CircularProgressbar } from 'react-circular-progressbar';
import { FaRegEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import 'react-circular-progressbar/dist/styles.css';
import API, { isSubscribedAccountant } from "../../utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { IoIosArrowUp ,IoIosArrowDown} from "react-icons/io";

const Question = ({ question, formData, handleChange, errors }) => {
  const renderInput = () => {
    switch (question.type) {
      case 'textarea':
        return (
          <textarea
            className={`form-control life-plan-area-personalos ${errors[question.name] ? 'is-invalid' : ''}`}
            name={question.name}
            value={formData[question.name] || ''}
            onChange={handleChange}
            rows="3"
            placeholder="Type Your Answer Here"
            onKeyDown={(e) => handleChange(e, true)} 
          ></textarea>
        );
      case 'select':
        return (
          <select
            className="form-control"
            name={question.name}
            value={formData[question.name] || ''}
            onChange={handleChange}
            onKeyDown={(e) => handleChange(e, true)} 
          >
            <option value="">Select option</option>
            <option value="0">0 %</option>
            <option value="25">25 %</option>
            <option value="50">50 %</option>
            <option value="75">75 %</option>
            <option value="100">100 %</option>
          </select>
        );
      case 'radio':
        return (
          <div className="radio-group">
            {[...Array(10)].map((_, index) => (
              <label key={index} className="radio-label">
                <input
                  className={`radio_animated radio-${index < 3 ? 'primary' : index < 6 ? 'secondary' : 'warning'}`}
                  type="radio"
                  name={question.name}
                  value={index + 1}
                  checked={formData[question.name] === String(index + 1)}
                  onChange={handleChange}
                />
              <span className="Family-rating">{index + 1}</span>
              </label>
            ))}
          </div>
        );
      default:
        return (
          <input
            type="text"
            className={`form-control life-plan-area-personalos p-2 ${errors[question.name] ? 'is-invalid' : ''}`}
            name={question.name}
            value={formData[question.name] || ''}
            onChange={handleChange}
            placeholder="Type Your Answer Here"
            onKeyDown={(e) => handleChange(e, true)}
          />
        );
    }
  };

  return (
    <div className="form-group row mb-2 mx-2">
      <label className="col-sm-12 col-form-label mb-4 p-0 life-plan-form-label">{question.label}</label>
      {renderInput()}
      {errors[question.name] && <div className="text-danger">{errors[question.name]}</div>}
    </div>
  );
};

const Family = () => {
  const navigate = useNavigate();
  const user_id = localStorage.getItem('user_id');
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [questions, setQuestions] = useState([]);
  const formRef = useRef(null);
  const [isApplicable, setIsApplicable] = useState("");


  const [loading, setLoading] = useState(true); // State for loader
const handleChange = (e, isKeyPress = false) => {
  const { name, value } = e.target;

  // If it's a key press, check if it's the Enter key
  if (isKeyPress && e.key === 'Enter') {
    e.preventDefault();
    handleSubmit(e);
    return;
  }

  setFormData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
};

const calculateStepProgress = () => {
  return ((currentStep + 1) / questions.length) * 100;
};

const fetchQuestions = async () => {
  setLoading(true); // Start loading

  const formDataToSend = new FormData();
  formDataToSend.append('category', "Family");

  try {
    const response = await axios.post(
      `${API.BASE_URL}${API.ENDPOINTS.getonlyquestions}`,
      formDataToSend,
      {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`
        },
      }
    );
    if (response.data.status === "success") {
      const fetchedQuestions = response.data.questions.map((q, index) => ({
        id: q.id,
        name: `question_${index + 1}`,
        label: q.question,
        type: index === 4 ? 'textarea' : index === response.data.questions.length - 2 ? 'select' : index === response.data.questions.length - 1 ? 'radio' : 'text',
      }));
      setQuestions(fetchedQuestions);

      const initialFormData = fetchedQuestions.reduce((acc, question) => {
        acc[question.name] = question.answer || '';
        return acc;
      }, {});

      setFormData(initialFormData);

    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.error("Failed to fetch questions.");
    console.error(error);
  }
  finally {
    setLoading(false); // Stop loading
  }
};



  useEffect(() => {
    if (!isSubscribedAccountant()) {
      navigate("/users/userEdit");
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentStep]);

  useEffect(() => {
    const focusElement = document.querySelector(`[name=${questions[currentStep]?.name}]`);
    if (focusElement) {
      focusElement.focus();
    }
  }, [currentStep, questions]);
  
 
  useEffect(() => {
    fetchQuestionsand();
  }, []);
  
    const fetchQuestionsand = async () => {
      // setLoading(true); 
      const formDataToSend = new FormData();
      formDataToSend.append('category', "Family");
      formDataToSend.append('user_id', user_id);

      try {
        const response = await axios.post(
          `${API.BASE_URL}${API.ENDPOINTS.getquestionbycategoty}`,
          formDataToSend,
          {
            headers: {
              Authorization: `${API.AUTHORIZATION_TOKEN}`
            },
          }
        );

        if (response.data.status === "success") {
          setIsApplicable(response.data.graph_checked)

          if (response.data.question && response.data.question.length > 0) {
          const fetchedQuestions = response.data.question.map((q, index) => ({
            id: q.question_id,
            name: `question_${index + 1}`,
            label: q.question,
            answer: q.answer || "",
            type: index === 4 ? 'textarea' : index === response.data.question.length - 2 ? 'select' : index === response.data.question.length - 1 ? 'radio' : 'text',
          }));
          setQuestions(fetchedQuestions);

          const initialFormData = fetchedQuestions.reduce((acc, question) => {
            acc[question.name] = question.answer || '';
            return acc;
          }, {});

          setFormData(initialFormData);
        }  else {
          fetchQuestions();
        }
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error(error);
        // toast.error("Failed to fetch questions.");
      }
      finally {
        setLoading(false); 
      }
    };
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      const formDataToSend = new FormData();
  
      questions.forEach((question, index) => {
        formDataToSend.append(`question[${index + 1}]`, question.id);
        formDataToSend.append(`answer[${index + 1}]`, formData[question.name] || '');
      });
  
      formDataToSend.append('user_id', user_id);
      formDataToSend.append('category', "Family");
  
      try {
        const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.addpersonalos}`, formDataToSend,
          {
            headers: {
              Authorization: `${API.AUTHORIZATION_TOKEN}`,
            },
          }
        );
  
        if (response.data.status === "success") {
          if (currentStep < questions.length - 1) {
            setCurrentStep(currentStep + 1)
            }else{
              fetchQuestionsand()
            }
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("User Profile update failed.");
        console.error(error);
      }
    };
  
    const okbutton = () => {
      if (currentStep < questions.length - 1) {
        return (
          <button
            className="btn btn-primary mt-4 mx-1"
            style={{background: "#0445af"}}
            type="button"
            // onClick={() => setCurrentStep(currentStep + 1)}
            onClick={handleSubmit}
          >
            Ok
          </button>
        );
      }
      return (
        <button
          className="btn btn-success mx-1"
          type="button"
          onClick={handleSubmit}
        >
          Save
        </button>
      );
    };
  
    const nextButton = () => {
      if (currentStep < questions.length - 1) {
        return (
          <button
            className="btn btn-primary mx-1"
            style={{background: "#0445af"}}
            type="button"
             // onClick={() => setCurrentStep(currentStep + 1)}
             onClick={handleSubmit}
          >
            <IoIosArrowDown style={{fontSize:"26px"}}/>
          </button>
        );
      }
      return (
        <button
          className="btn btn-success mx-1"
          type="button"
          onClick={handleSubmit}
        >
          Save
        </button>
      );
    };
  
    const previousButton = () => {
      if (currentStep > 0) {
        return (
          <button
            className="btn btn-secondary mx-1"
            type="button"
            onClick={() => setCurrentStep(currentStep - 1)}
          >
            <IoIosArrowUp style={{fontSize:"26px"}}/>
          </button>
        );
      }
      return null;
    };

  const getColorForProgress = (value) => {
    if (value <= 25) {
      return '#FF0000';
    } else if (value <= 50) {
      return '#FFA500';
    } else if (value <= 75) {
      return '#FFFF00';
    } else {
      return '#008000';
    }
  };

  const calculateProgressValue = () => {
    if (questions.length === 0) {
      return 0;
    }

    const answeredQuestions = questions.filter((q) => formData[q.name] && formData[q.name].trim() !== '');
    return (answeredQuestions.length / questions.length) * 100;
  };

  const progressValue = calculateProgressValue();
  const handleCheckboxChange = async (e) => {
    const checked = e.target.checked;
    setIsApplicable(checked ? "1": "0");

    const status = checked ? 1 : 0;

    const formDataToSend = new FormData();
    formDataToSend.append('graph_checked', status);
    formDataToSend.append('user_id', user_id);
    formDataToSend.append('category', "Family");


    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.checked_category}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        // toast.success("Applicability status updated.");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed to update applicability status.");
      console.error(error);
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header p-2">
                <div className="row">
                  <div className="col-lg-2 d-flex align-items-center">
                    <h5>Family</h5>
                  </div>
                  <div className="col-lg-9 d-flex align-items-center justify-content-end mt-2">
                    <div className="checkbox-group d-flex">
                    <label style={{ color: "white", marginLeft: "10px" }}>
                        <input
                          className="checkbox_animated m-l-10"
                          type="checkbox"
                          checked={isApplicable === "1"}
                          onChange={handleCheckboxChange}
                        />{" "}
                      </label>
                      <h6 style={{ color: "white" }}>Exclude This Life Area from My Plan </h6>
                    
                  
                    </div>
                  </div>
                  <div className="col-lg-1 d-flex align-items-center justify-content-end">
                    <CircularProgressbar
                      value={progressValue}
                      text={`${Math.round(progressValue)}%`}
                      styles={{
                        path: {
                          stroke: getColorForProgress(progressValue),
                        },
                        text: {
                          fill: "#000000",
                          fontSize: "20px",
                        },
                        root: {
                          width: "65px",
                          background: "white",
                          padding: "4px",
                          borderRadius: "7px",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              {loading ? (
                  <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  <>
              <div className="card-body" style={{ height: "315px" }} ref={formRef}>
    
              <ProgressBar now={calculateStepProgress()} />              
                 <form onSubmit={handleSubmit} className="life-plan-form responsive-life-plan-form">
                  <TransitionGroup>
                    <CSSTransition
                      key={currentStep}
                      timeout={300}
                      classNames="slide"
                    >
                      <div>
                        {questions.length > 0 && (
                          <Question
                            question={questions[currentStep]}
                            formData={formData}
                            handleChange={handleChange}
                            errors={errors}
                          />
                        )}
                      </div>
                    </CSSTransition>
                  </TransitionGroup>

                  {okbutton()}
                </form>
             
              </div>
             
              <div className="text-end my-2">
                <div className="btn-group ">
                  {previousButton()}
                  {nextButton()}
                </div>
              </div>
              </>
                    )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Family;