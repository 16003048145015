import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { ModalFooter, Modal, ModalHeader, ModalBody } from 'reactstrap';
import axios from 'axios';
import API from "../../../utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ViewModal = ({ isOpen, toggle, taskId }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [taskDetails, setTaskDetails] = useState({});
  const [loading, setLoading] = useState(true);

  console.log("taskId", taskId);
  
  const fetchTaskDetails = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.viewTask}/${taskId}`, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });
      const data = response.data;
      if (data.status === "success") {
        setTaskDetails(data.task);
      } else {
        console.error("Failed to fetch task details");
      }
    } catch (error) {
      console.error("Error fetching task details:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && taskId) {
      fetchTaskDetails();
    }
  }, [isOpen, taskId]);

  const onSubmit = (data) => {
    console.log(data);
  };

  const thStyle = {
    border: "1px solid gray",
    textAlign: "left",
    background: "black",
    color: "white"
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Task Details</ModalHeader>
      <ModalBody>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <table className="table">
            <thead></thead>
            <tbody>
              <tr>
                <th style={thStyle} colSpan={2}>Task Detail</th>
              </tr>
              <tr>
                <td>Task Name</td>
                <td>{taskDetails?.title || 'N/A'}</td>
              </tr>
              <tr>
                <td>Criticality</td>
                <td>{taskDetails?.criticality || 'N/A'}</td>
              </tr>
              <tr>
                <td>Due Date</td>
                <td>{taskDetails?.due_date || 'N/A'}</td>
              </tr>
              <tr>
                <td>Description</td>
                <td>{taskDetails?.description || 'N/A'}</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>{taskDetails?.status || 'N/A'}</td>
              </tr>
              <tr>
                <td>Life Area</td>
                <td>{taskDetails?.life_area || 'N/A'}</td>
              </tr>
            </tbody>
          </table>
        )}
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-primary me-1" onClick={toggle}>Cancel</button>
      </ModalFooter>
    </Modal>
  );
};

export default ViewModal;
