import React, { useEffect, useState } from "react";
import { Container,Tab, Tabs } from "react-bootstrap";
import axios from "axios";
import API, { isSubscribedAccountant } from '../../utils.js';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import MyVeryBIGDream from "./bigdatatab/myverybigdream";
import MyTop5LifeGoals from "./bigdatatab/mytoplifegoals";

const Bigdata = () => {
    const navigate = useNavigate()
    const [tabs, setTabs] = useState([]);
    const [activeTab, setActiveTab] = useState("My Very BIG Dream");

    useEffect(() => {
        fetchTabs();
    }, []);

    useEffect(() => {
      
        if (!isSubscribedAccountant()) {
          navigate("/users/userEdit");
          window.location.reload();
        }
      
      }, []);
    const fetchTabs = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('main_category', "My Very BIG Dream");

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.futureSUb}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    },
                }
            );

            if (response.data.status === "success") {
                setTabs(response.data.sub_category);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };
    const handleTabChange = (tab) => {
        setActiveTab(tab);
        clearValidationErrors();
    };

    const clearValidationErrors = () => {
        window.dispatchEvent(new Event('clearValidationErrors'));
    };
    return (
        <>
            <Container >
                <Tabs
                    activeKey={activeTab}
                    onSelect={handleTabChange}
                    id="justify-tab-example"
                    className="border-tab nav-primary mb-3"
                    justify
                >
                    {tabs.map(tab => (
                        <Tab key={tab.id} eventKey={tab.sub_category} title={tab.sub_category}>
                            {getTabContent(tab.sub_category)}
                        </Tab>
                    ))}
                </Tabs>
            </Container>
        </>
    );
};

const getTabContent = (tabName) => {
    switch (tabName) {
        case 'My Very BIG Dream':
            return <MyVeryBIGDream />;
        case 'My Top 5 Life Goals':
            return <MyTop5LifeGoals />;
        default:
            return null;
    }
};

export default Bigdata;
