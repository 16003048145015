import React from "react";
import { Modal, Button } from "react-bootstrap";

const EditModal = ({
  isEditModalOpen,
  toggleEditModal,
  formData,
  handleInputChange,
  handleEditItem,
  handleKeyDownEdit
}) => (
  <Modal centered={true} show={isEditModalOpen} onHide={toggleEditModal}>
    <Modal.Header closeButton>
      <Modal.Title>Edit Daily Gratitude</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <form className="theme-form">
        {formData.gratitude.map((gratitude, index) => (
          <div className="form-group" key={index}>
            <label className="col-form-label pt-0" htmlFor={`gratitude${index}`}>
              Daily Gratitude {index + 1}
            </label>
            <textarea
              className={`form-control`}
              id={`gratitude${index}`}
              name={`gratitude${index}`}
              value={gratitude}
              onChange={(e) => handleInputChange(e, index)}
              onKeyDown={handleKeyDownEdit}
            />
          </div>
        ))}
      </form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={toggleEditModal}>Cancel</Button>
      <Button variant="primary" onClick={handleEditItem}>Save</Button>
    </Modal.Footer>
  </Modal>
);

export default EditModal;
