import React from 'react';
import axios from 'axios';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from '../../../utils';


const TopFiveCoreValue = ({ topFivecoreValues }) => {
    const uid = localStorage.getItem("user_id");

    const handleInputChange = async (event, entry) => {
        const description = event.target.value;
    
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('question_id', entry.question_id);
        formDataToSend.append('description', description);
    
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_description_top5new_data}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );
    
            // Log the entire response object for debugging
            console.log('API Response:', response.data);
    
            if (response.data.status === "success") {
                //toast.success(response.data.message);
                // Check if data field is present
                if (response.data.data) {
                    console.log('Data received:', response.data.data);
                } else {
                    console.warn('Data field is missing in the response.');
                }
            } else {
                toast.error(response.data.message || 'Failed to update description');
            }
        } catch (error) {
            console.error('Error updating description:', error);
            toast.error('An error occurred while updating description.');
        }
    };
    

    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <h5>Top 5 Core Value List</h5>
                    </div>
                    <div className="card-body m-2">
                    <div className="core-values-container">
               <p className='section-paragraph'>     <strong>  Add a Verb to Each Value Label </strong></p>
                                <p className="section-paragraph">Add a verb to each value so you can see what it looks like as an actionable core value. </p>
                            <p className="section-paragraph">
                                <strong>For example:</strong>
                                <ul>
                                    <li>•	Live in freedom.</li>
                                    <li>•	Seek opportunities for making a difference.</li>
                                    <li>•	Act with mindfulness.</li>
                                    <li>•	Promote well-being.</li>
                                    <li>•	Multiply happiness.</li>
                                </ul>
                            </p>
                            <p className="section-paragraph">This will guide you in the actions you need to take to feel like you are truly living on purpose. </p>

                            <p className="section-paragraph">Remember, everyone has different values and ideas of what’s most important to them. No one is wrong.  Everyone isdifferent. The idea is to first identify your values and then determine how they play a role in your life. </p>

                            </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr style={{ backgroundColor: '#800000', color: '#fff' }}>
                                            <th>#</th>
                                            <th>MY CORE VALUES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {topFivecoreValues != null && topFivecoreValues.length > 0 ? (
                                            topFivecoreValues.map((entry, index) => (
                                                <React.Fragment key={index}>
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{entry.question_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>&nbsp;</td> 
                                                        <td>
                                                            <input 
                                                                type='text'
                                                                className="form-control"
                                                                placeholder="Enter your text here..."
                                                                defaultValue={entry.description || ""}
                                                                onChange={(e) => handleInputChange(e, entry)}
                                                            />
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="2" className="text-center">
                                                    <b>Data Not Found</b>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopFiveCoreValue;
