import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, Dropdown, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { FaSortDown, FaTasks, FaCalendarWeek, FaCalendar, FaExclamationCircle, FaCheckCircle, FaHourglassHalf, FaPauseCircle } from 'react-icons/fa';
import CreatedByme from './tasksModals/taskCreatebyme';
import { CgToday } from "react-icons/cg";
import { PiHourglassHighFill } from "react-icons/pi";
import { SiVlcmediaplayer } from "react-icons/si";
import { GiStabbedNote } from "react-icons/gi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditTaskModal from './tasksModals/editModal';
import ViewModal from './tasksModals/viewModal';
import AddModal from './tasksModals/addModal';
import DeleteModal from './tasksModals/DeleteModal.js';

import API, { isSubscribedAccountant } from '../../utils.js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Task = () => {
  const navigate = useNavigate()
  const [formErrors, setFormErrors] = useState({});
  const [checkedTasks, setCheckedTasks] = useState({});
  const [activeTab, setActiveTab] = useState('all');
  const [addModal, setAddModal] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [viewTask, setViewTask] = useState(null);
  const [deleteTask, setDeleteTask] = useState(null);
  const [taskId, setTaskId] = useState(null);
  const [deleteTaskId, setDeleteTaskId] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState({
    title: '',
    criticality: '',
    status: '',
    life_area: '',
    due_date: '',
    updated_at: '',
  });
  const uid = localStorage.getItem("user_id");
  const componentRef = useRef();

  useEffect(() => {
    fetchTasks();
  }, [activeTab]);

  useEffect(() => {
    if (!isSubscribedAccountant()) {
      navigate("/users/userEdit");
      window.location.reload();
    }
  }, []);

  const fetchTasks = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.allTask}/${uid}?filter=${activeTab}`, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });
      const data = response.data;
      if (data.status === "success") {
        // setTasks(data.tasks);
        const sortedTasks = data.tasks.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setTasks(sortedTasks);
      } else {
        console.error("Failed to fetch tasks");
      }
    } catch (error) {
      console.error("Error fetching tasks:", error.message);
    }
  };

  const addToggle = () => {
    setAddModal(!addModal);
    setFormErrors({});
  };

  const closeAddModal = () => {
    setAddModal(false);
    setFormErrors({});

  };

  const openEditModal = (task) => {
    setSelectedTask(task);
    setEditModalOpen(true);
  };

  const openViewModal = (task) => {
    setViewTask(task);
    setViewModalOpen(true);
    setTaskId(task.id);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
    setSelectedTask(null);
  };

  const closeViewModal = () => {
    setViewModalOpen(false);
    setViewTask(null);
  };

  const RemoveTask = (task) => {
    setDeleteModalOpen(true);
    setDeleteTask(task);
    setDeleteTaskId(task.id);
  };


  const tabHeadings = {
    'all': 'All Tasks',
    'today': 'Today Tasks',
    'delayed': 'Delayed Tasks',
    'upcoming': 'Upcoming Tasks',
    'this_week': "This Week's Tasks",
    'this_month': "This Month's Tasks",
    'critical': 'Critical Tasks',
    'high': 'High Priority Tasks',
    'medium': 'Medium Priority Tasks',
    'low': 'Low Priority Tasks',
    'complete': 'Completed Tasks',
    'on-hold': 'Tasks On Hold',
    'Not Started': 'Not Started Tasks',
    'In Process': 'Tasks In Process',
    
  };

  const tabIcons = {
    'all': <FaTasks />,
    'today': <CgToday />,
    'delayed': <FaHourglassHalf />,
    'upcoming': <FaCalendar />,
    'this_week': <FaCalendarWeek />,
    'this_month': <FaCalendar />,
    'critical': <FaExclamationCircle />,
    'high': <PiHourglassHighFill />,
    'medium': <SiVlcmediaplayer />,
    'low': <FaExclamationCircle />,
    'complete': <FaCheckCircle />,
    'on-hold': <FaPauseCircle />,
    'Not Started': <GiStabbedNote />,
    'In Process': <FaTasks />,
 
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const addTaskInline = (field, value) => {
    if (field === 'submit') {
      setTasks([...tasks, newTask]);
      setNewTask({
        title: '',
        criticality: '',
        status: '',
        life_area: '',
        due_date: '',
        updated_at: '',
      });
    } else {
      setNewTask({ ...newTask, [field]: value });
    }
  };



  return (
    <Fragment>
      <Container fluid>
        <div className="email-wrap bookmark-wrap">
          <Row>
            <Col lg={12} md={12} sm={12} className="box-col-12">
              <div className="email-right-aside bookmark-tabcontent">
                <Card className="email-body radius-left">
                  <div className="ps-0">
                    <CardHeader className="d-flex justify-content-between">
                      <div className="align-items-center d-flex">
                        <h5 className="mb-0">{tabHeadings[activeTab]}</h5>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <AddModal isOpen={addModal} toggle={addToggle} closeAddModal={closeAddModal} fetchTasks={fetchTasks} />
                      <div className='d-flex justify-content-between'>
                        
                        <Dropdown>
                          <Dropdown.Toggle as="div" id="dropdown-basic">
                            <Button
                              variant="secondary"
                              className="align-items-center main-menu">
                              Filter <FaSortDown className='mytask-toggle-icon mb-2' />
                            </Button>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {Object.keys(tabHeadings).map(tab => (
                              <Dropdown.Item
                                key={tab}
                                className={activeTab === tab ? 'active' : ''}
                                onClick={() => handleTabChange(tab)}
                              >
                                {tabIcons[tab]} {tabHeadings[tab]}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                        <div className='col-md-3'>
                        
                        </div>
                      </div>
                      <CreatedByme
                        RemoveTask={RemoveTask}
                        openEditModal={openEditModal}
                        openViewModal={openViewModal}
                        ref={componentRef}
                        mytasks={tasks}
                        addTaskInline={addTaskInline}
                        getDetails={fetchTasks}
                      />
                    </CardBody>
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <EditTaskModal isOpen={editModalOpen} toggle={closeEditModal} task={selectedTask} getDetails={fetchTasks} />
      <ViewModal isOpen={viewModalOpen} toggle={closeViewModal} task={viewTask} taskId={taskId} />
      <DeleteModal isOpen={deleteModalOpen} toggle={() => setDeleteModalOpen(false)} task={deleteTask} taskId={deleteTaskId} fetchTasks={fetchTasks} />
    </Fragment>
  );
};

export default Task;
