import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Testresults from '../../../assets/images/testresults/testresults.png';
import { useNavigate } from 'react-router-dom';
import API, { isSubscribedAccountant } from '../../../utils';

const Disctestdetalistable = ({ activeTab }) => {
    const navigate = useNavigate();
    const [visionStatements, setVisionStatements] = useState({});
    const [lifeAreas, setLifeAreas] = useState([]);
    const uid = localStorage.getItem("user_id");
    const [debounceTimer, setDebounceTimer] = useState(null);

    useEffect(() => {
        if (!isSubscribedAccountant()) {
            navigate("/users/userEdit");
            window.location.reload();
        }
    }, [navigate]);

    const fetchData = async () => {
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_all_question_to_disc_test_detailsMotivation}`,
                null, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                setLifeAreas(response.data.data);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch role model data error', error);
            toast.error("Failed to fetch role model data.");
        }
    };

    const fetchAnswers = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_all_to_disc_test_detailsMotivation}`,
                formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                const visionData = response.data.data.reduce((acc, item) => {
                    acc[item.question_id] = {
                        rating: item.rating,
                        details: item.details,
                    };
                    return acc;
                }, {});
                setVisionStatements(visionData);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch answers data error', error);
            toast.error("Failed to fetch answers data.");
        }
    };
    const getBackgroundColor = (areaId) => {
        switch (areaId) {
            case 0:
                return '#b3e5a1';
            case 1:
                return '#215e99'; // light orange
            case 2:
                return '#ffc000'; // light green
            case 3:
                return '#ff0000'; // light blue
            case 4:
                return '#f1a983'; // light blue
            case 5:
                return '#000000'; // light blue
            case 6:
                return '#80340d'; // light blue    
            default:
                return '#FFFFFF'; // default white
        }
    };
    const handleChange = (e, key, field) => {
        const { value } = e.target;
        setVisionStatements({
          ...visionStatements,
          [key]: {
            ...visionStatements[key],
            [field]: value,
          },
        });
        if (debounceTimer) {
          clearTimeout(debounceTimer);
        }
    
        const newTimer = setTimeout(() => {
          handleSubmit(key);
        }, 2000); // Adjust the delay as needed
    
        setDebounceTimer(newTimer);
      };

    const handleSubmit = useCallback(async (key) => {
        const visionStatement = visionStatements[key];
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('question_id', key);
        formDataToSend.append('rating', visionStatement?.rating || '');
        formDataToSend.append('details', visionStatement?.details || '');
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_update_disc_test_details_Motivation}`,
                formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                //toast.success(response.data.message);
                fetchAnswers();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Add vision statement error', error);
            toast.error("Failed to add vision statement.");
        }
    });

    useEffect(() => {
        fetchData();
        fetchAnswers(); // Fetch answers data on component mount
    }, []);

    return (
        <>
        <h3>Details of Your Motivations</h3>
        <table className="table table-bordered">
        <thead>
            <tr>
                <th style={{width: "25%"}}>Value</th>
                <th style={{width: "20%"}}>Threshold</th>
                <th style={{width: "55%"}}> Details</th>
            </tr>
        </thead>
        <tbody>
            {lifeAreas.map((area ,areaId) => (
                <tr key={area.id}>
                      <td style={{ backgroundColor: getBackgroundColor(areaId) , color: 'white'}}><b>{area.question}</b></td>
                    <td>
                        <input type="number"
                            className="form-control"
                            name={area.id}
                            value={visionStatements[area.id]?.rating || ''}
                            onChange={(e) => handleChange(e, area.id, 'rating')}
                        />
                          
                        
                    </td>
                    <td>
                        <textarea
                            className="form-control"
                            name={area.id}
                          placeholder='Insert Here...'
                            value={visionStatements[area.id]?.details || ''}
                            onChange={(e) => handleChange(e, area.id, 'details')}
                        />
                    </td>
                    {/* <td>
                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => handleSubmit(area.id)}
                        >
                            Save
                        </button>
                    </td> */}
                </tr>
            ))}
        </tbody>
    </table>
        </>

    );
};

export default Disctestdetalistable;
