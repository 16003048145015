import React, { useState, useEffect, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from "react-toastify";
import axios from 'axios';
import API, { isSubscribedAccountant } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';

const LoveLoathe = () => {
  const user_id = localStorage.getItem('user_id');
  const [fields, setFields] = useState([{ id: null, love: '', hate: '' }]);
  const [removethetophated, setRemovethetophated] = useState('');
  const [validationMessage, setValidationMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (!isSubscribedAccountant()) {
      navigate("/users/userEdit");
      window.location.reload();
    }
  }, []);

  const fetchData = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', user_id);
    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_user_tasks_by_type}`,
        formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "success") {
        const data = response.data.data.map(task => ({
          id: task.id,
          love: task.Love, 
          hate: task.Hate 
        }));
        const question = response.data.data.length > 0 ? response.data.data[0].question : '';
        setRemovethetophated(question);
        setFields(data.length > 0 ? data : [{  id: null, love: '', hate: '' }]);
      } else {
        toast.error(response.data.data);
      }
    } catch (error) {
      console.error('Fetch data error', error);
      toast.error('Failed to fetch data.');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  
  const addFields = () => {
    const allFieldsFilled = fields.every(field => field.love !== '' && field.hate !== '');
    if (allFieldsFilled) {
      setFields([...fields, { id: null, love: '', hate: '' }]);
      setValidationMessage('');
    } else {
      setValidationMessage('Please fill in all fields before adding more.');
    }
  };

  const debouncedSaveData = useCallback(debounce(async (updatedFields, updatedQuestion) => {
    const formDataToSend = new FormData();
    updatedFields.forEach((field) => {
      formDataToSend.append('love[]', field.love);
      formDataToSend.append('hate[]', field.hate);
      formDataToSend.append('id[]', field.id);
    });
    formDataToSend.append('question', updatedQuestion);
    formDataToSend.append('user_id', user_id);
    
    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.add_love_hate}`, 
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
       // toast.success(response.data.message);
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("User Profile update failed.");
      console.error(error);
    }
  }, 2000), []); 

  const handleInputChange = (index, event) => {
    const values = [...fields];
    values[index][event.target.name] = event.target.value;
    setFields(values);
    setValidationMessage('');

    debouncedSaveData(values, removethetophated);
  };

  const handleTextareaChange = (event) => {
    const value = event.target.value;
    setRemovethetophated(value);

    debouncedSaveData(fields, value);
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="align-items-center justify-content-between d-flex">
                    <h5>Things I Love and Hate</h5>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className='text-end'>
                  <Button variant="success" className=" mx-2" onClick={addFields}>Add More</Button>
                </div>
                <div className="row m-2">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Things I Love to Do that Give You Strength / Energy</th>
                        <th>Things I Hate to Do that Drain Your Strengths / Energy</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map((field, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="text"
                              name="love"
                              value={field.love}
                              onChange={event => handleInputChange(index, event)}
                              className="form-control"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="hate"
                              value={field.hate}
                              onChange={event => handleInputChange(index, event)}
                              className="form-control"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {validationMessage && (
                    <div className="text-danger">
                      {validationMessage}
                    </div>
                  )}
                </div>
              </div>

              <div className="row m-3">
                <div className="col-lg-12 col-sm-12 col-md-12">
                  <div className="form-group mb-3">
                    <label className="form-label"><b>What are ways you can plan to remove the top hated task on your list that is draining your energy?</b></label>
                    <textarea
                      className="form-control"
                      type="text"
                      name="removethetophated"
                      value={removethetophated}
                      onChange={handleTextareaChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoveLoathe;
