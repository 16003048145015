import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../../utils';
import axios from 'axios';
import { debounce } from 'lodash';

const ComfortZone = () => {
    const uid = localStorage.getItem('user_id') || '';
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({});
    const [questions, setQuestions] = useState([]);

    const checkboxOptions = [
        { id: 'option1', label: "Do One Thing You've Always Wanted To" },
        { id: 'option2', label: "Take on a Fitness Challenge" },
        { id: 'option3', label: "Change Up Your Routine" },
        { id: 'option4', label: "Expand Your Professional Skill Set" },
        { id: 'option5', label: "Choose a Fear, and Face It" },
        { id: 'option6', label: "Travel Somewhere New" },
    ];

    useEffect(() => {
        const savedFormData = JSON.parse(localStorage.getItem('comfortZoneFormData')) || {};
        setFormData(savedFormData);
        fetchQuestions(savedFormData);
    }, []);

    useEffect(() => {
        fetchQuestions();

        const clearErrors = () => setErrors({});
        window.addEventListener('clearValidationErrors', clearErrors);

        return () => {
            window.removeEventListener('clearValidationErrors', clearErrors);
        };
    }, []);

    useEffect(() => {
        localStorage.setItem('comfortZoneFormData', JSON.stringify(formData));
    }, [formData]);

    const fetchQuestions = async (savedFormData) => {
        const formDataToSend = new FormData();
        formDataToSend.append('main_category_id', '2');
        formDataToSend.append('sub_category_id', '2');
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getQuestions}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );
            if (response.data.status === 'success') {
                const questions = response.data.question || [];
                setQuestions(questions);

                const initialFormData = {};
                questions.forEach((question) => {
                    initialFormData[question.id] = savedFormData[question.id] || [];
                });
                setFormData(initialFormData);
            } else {
                toast.error(response.data.message || 'Failed to fetch questions');
            }
        } catch (error) {
            console.error('Error fetching questions:', error);
        }
    };

    const debouncedSaveData = useCallback(
        debounce(async (updatedFormData) => {
            const formDataToSend = new FormData();
            questions.forEach((question) => {
                formDataToSend.append('question_id[]', question.id);
                formDataToSend.append('answer[]', updatedFormData[question.id].join(', '));
            });

            formDataToSend.append('user_id', uid);

            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.addAnswer}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                if (response.data.status === 'success') {
                    // toast.success(response.data.message);
                } else {
                    toast.error(response.data.message || 'Failed to update answers');
                }
            } catch (error) {
                // toast.error('Failed to update answers. Please try again later.');
            }
        }, 2000),
        [questions]
    );

    const handleChange = (e, questionId) => {
        const { name, checked } = e.target;
        setErrors((prevErrors) => ({
            ...prevErrors,
            [questionId]: '',
        }));
        setFormData((prevData) => {
            const newOptions = checked
                ? [...prevData[questionId], name]
                : prevData[questionId].filter((option) => option !== name);

            const updatedFormData = {
                ...prevData,
                [questionId]: newOptions,
            };

            // Trigger the debounced save data function
            debouncedSaveData(updatedFormData);

            return updatedFormData;
        });
    };

    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <h5>Comfort Zone</h5>
                    </div>
                    <form className="form theme-form">
                        <div className="card-body m-3">
                            <div className="row">
                                <div className="col-sm-12">
                                    {questions.map((question) => (
                                        <div className="form-group row mb-2" key={question.id}>
                                            <label className="col-sm-12 col-form-label mb-2 p-0 "><b>{question.question}</b></label>
                                            {checkboxOptions.map((option) => (
                                                <div className="form-group row mb-2" key={option.id}>
                                                    <div className="col-lg-12">
                                                        <label className={`d-block ${formData[question.id]?.includes(option.label) ? 'checked-label' : ''}`} htmlFor={`chk-${question.id}-${option.id}`}>
                                                            <input
                                                                className="checkbox_animated"
                                                                id={`chk-${question.id}-${option.id}`}
                                                                type="checkbox"
                                                                name={option.label}
                                                                checked={formData[question.id]?.includes(option.label) || false}
                                                                onChange={(e) => handleChange(e, question.id)}
                                                            />
                                                            {option.label}
                                                        </label>
                                                    </div>
                                                </div>
                                            ))}
                                            {errors[question.id] && <div className="text-danger">{errors[question.id]}</div>}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ComfortZone;
