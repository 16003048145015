import React , { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import API from "../../../utils";


const DeleteModal = ({ isOpen, toggle, task, taskId , fetchTasks }) => {

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const handleDelete = async () => {
    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.deleteTask}/${taskId}`, 
        null, 
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );
  
      if (response.data.status === "success") {
        // toast.success(response.data.message);
        fetchTasks(); 
        toggle();
      } else {
        // toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Task app delete error', error);
     
    }
  };
  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Delete Task</ModalHeader>
      <ModalBody>
        {task ? (
          <p>Are you sure you want to delete "{task.title}"?</p>
        ) : (
          <p>Task details not available.</p>
        )}
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-secondary" onClick={toggleDeleteModal}>
          Cancel
        </button>
        {task && (
          <button className="btn btn-danger me-1" onClick={handleDelete}>
            Delete
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default DeleteModal;
