import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const EditAnswerModal = ({ show, handleClose, tempAnswer, handleChange, handleSave }) => {
    const [error, setError] = useState('');

    useEffect(() => {
        if (!show) {
            setError('');
        }
    }, [show]);



    const validateAndSave = () => {
        if (!tempAnswer.trim()) {
            setError('This Field Required.');
        } else {
            setError('');
            handleSave();
        }
    };

    const handleInputChange = (e) => {
        setError('');
        handleChange(e);
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Answer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input
                    className={`form-control ${error ? 'is-invalid' : ''}`}
                    type="text"
                    value={tempAnswer}
                    onChange={handleInputChange}
                />
                {error && <div className="invalid-feedback">{error}</div>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary"  onClick={validateAndSave}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditAnswerModal;
