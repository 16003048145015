import React, { useEffect, useState } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import axios from "axios";
import API from "../../utils";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import NegetiveLifeMoment from "./myrealityteb/negetivelife";
import MyLifeline from "./myrealityteb/myreality";
import SpecificQuestions from "../my-belief/definesuccess/specificQuestion";

const LifelineMilestones = () => {
    const [tabs, setTabs] = useState([]);
    const [activeTab, setActiveTab] = useState('My Lifeline');

    useEffect(() => {
        fetchTabs();
    }, []);

    const fetchTabs = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('main_category_name', "Lifeline / Milestones");

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getsubcategorymyreality}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    },
                }
            );

            if (response.data.status === "success") {
                setTabs(response.data.sub_category);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
            toast.error("Failed to fetch tabs.");
        }
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        clearValidationErrors();
    };
    
    const clearValidationErrors = () => {
        window.dispatchEvent(new Event('clearValidationErrors'));
    };
    

    return (
        <>
            <Container fluid>
                {/* <div className="d-lg-none m-15 d-flex col-4">
                    <select className="form-select" value={activeTab} onChange={handleSelect}>
                        {tabs.map(tab => (
                            <option key={tab.id} value={tab.sub_category}>{tab.sub_category}</option>
                        ))}
                    </select>
                </div>

               
                <div className="d-none d-lg-block"> */}
                    <Tabs
                        defaultActiveKey="My Lifeline"
                        // activeKey={activeTab}
                        onSelect={handleTabChange}
                        id="justify-tab-example"
                        className="border-tab nav-primary mb-3"
                        justify
                    >
                        {tabs.map(tab => (
                            <Tab key={tab.id} eventKey={tab.sub_category} title={tab.sub_category}>
                                {getTabContent(tab.sub_category)}
                            </Tab>
                        ))}
                    </Tabs>
                {/* </div> */}

            </Container>
        </>
    );
};

const getTabContent = (tabName) => {
    switch (tabName) {
        case 'My Lifeline':
            return <MyLifeline />;
        case 'Negetive Life Moment':
            return <NegetiveLifeMoment />;
        case 'Positive Life Moment':
            return <SpecificQuestions />;
        default:
            return null;
    }
};

export default LifelineMilestones;
