import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../../utils';
import axios from 'axios';
import { debounce } from 'lodash';

const MyTop5Lifequestionss = () => {
    const uid = localStorage.getItem('user_id') || '';
    const [formData, setFormData] = useState({});
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        fetchQuestions();
    }, []);

    const fetchQuestions = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('main_category_id', '3');
        formDataToSend.append('sub_category_id', '20');
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getQuestions}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                const questionsData = response.data.question || [];
                setQuestions(questionsData);

                const initialFormData = {};
                questionsData.forEach((question) => {
                    initialFormData[question.id] = question.answer || '';
                });
                setFormData(initialFormData);
            } else {
                toast.error(response.data.message || 'Failed to fetch questions');
            }
        } catch (error) {
            console.error('Error fetching questions:', error);
            toast.error('An error occurred while fetching questions.');
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        setFormData((prevData) => {
            if (type === 'checkbox') {
                const prevAnswers = prevData[name] ? prevData[name].split(', ') : [];
                if (checked) {
                    return {
                        ...prevData,
                        [name]: [...prevAnswers, value].join(', '),
                    };
                } else {
                    return {
                        ...prevData,
                        [name]: prevAnswers.filter((answer) => answer !== value).join(', '),
                    };
                }
            } else {
                return {
                    ...prevData,
                    [name]: value,
                };
            }
        });
    };

    const handleBlur = () => {
        debouncedHandleSubmit();
    };

    const handleSubmit = async () => {
        const formDataToSend = new FormData();
        questions.forEach((question) => {
            formDataToSend.append(`question_id[]`, question.id);
            formDataToSend.append(`answer[]`, formData[question.id] || '');
        });

        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.addAnswer}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                fetchQuestions();
                // toast.success(response.data.message);
            } else {
                toast.error(response.data.message || 'Failed to update answers');
            }
        } catch (error) {
            console.error('Error updating answers:', error);
            toast.error('An error occurred while updating answers.');
        }
    };

    const debouncedHandleSubmit = useCallback(debounce(handleSubmit), [formData, questions]);

    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <h5>My Top 5 Life Goals</h5>
                    </div>

                    <form className="form theme-form">
                        <div className="card-body m-3">
                            <div className="row">
                                <div className="col-sm-12">
                                    <label className="col-sm-12 col-form-label mb-2 p-0"><b>My Top 5 Life Goals I Need From MY BIG Dream Are:</b></label>
                                    {questions.map((question) => (
                                        <div className="form-group row mb-2" key={question.id}>
                                            <input
                                                className="form-control"
                                                name={question.id}
                                                value={formData[question.id] || ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            ></input>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default MyTop5Lifequestionss;
