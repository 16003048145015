import React, { useEffect, useState } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import API, { isSubscribedAccountant } from "../../utils";
import SortBio from "./personalbioteb/sortbio";
import LongBio from "./personalbioteb/longbio";
import { useNavigate } from "react-router-dom";


const Personalbio = () => {
    const [tabs, setTabs] = useState([]);
    const [activeTab, setActiveTab] = useState('My Short Bio');
    const navigate = useNavigate()
    useEffect(() => {
      if (!isSubscribedAccountant()) {
        navigate("/users/userEdit");
        window.location.reload();
      }
    }, []);
    useEffect(() => {
        fetchTabs();
    }, []);

    const fetchTabs = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('main_category', "Personal Bio");

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getSubCategory}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    },
                }
            );

            if (response.data.status === "success") {
                setTabs(response.data.sub_category);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleTabSelect = (key) => {
        setActiveTab(key);
    };

    return (
        <Container fluid={true}>
            <Tabs
                activeKey={activeTab}
                onSelect={handleTabSelect}
                id="justify-tab-example"
                className="border-tab nav-primary mb-3"
                justify
            >
                {tabs.map(tab => (
                    <Tab key={tab.id} eventKey={tab.sub_category} title={tab.sub_category}>
                        {getTabContent(tab.sub_category, activeTab)}
                    </Tab>
                ))}
            </Tabs>
        </Container>
    );
};

const getTabContent = (tabName, activeTab) => {
    switch (tabName) {
        case 'My Short Bio':
            return <SortBio activeTab={activeTab} />;
        case 'My Long Bio':
            return <LongBio activeTab={activeTab} />;
        default:
            return null;
    }
};

export default Personalbio;
