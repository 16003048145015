import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import API, { isSubscribedAccountant } from '../../utils.js';
import { useNavigate } from 'react-router-dom';

const BucketList = () => {
    const navigate = useNavigate()
    const uid = localStorage.getItem('user_id') || '';
    const [roleModal, setRoleModal] = useState([]);
    const [selectedItems, setSelectedItems] = useState({
        Travel: [],
        Learn: [],
        "Revisit the Past": [],
        "Achieve a Life Event": [],
        "Do Something Daring": [],
        "Do Something You Just Haven’t Got Around To": [],
        "Do Something for Someone Else": [],
    });
    const [customInputs, setCustomInputs] = useState({});
    const [customFieldsCount, setCustomFieldsCount] = useState({
        Travel: 0,
        Learn: 0,
        "Revisit the Past": 0,
        "Achieve a Life Event": 0,
        "Do Something Daring": 0,
        "Do Something You Just Haven’t Got Around To": 0,
        "Do Something for Someone Else": 0,
    });
    const [showCustomFields, setShowCustomFields] = useState({
        Travel: false,
        Learn: false,
        "Revisit the Past": false,
        "Achieve a Life Event": false,
        "Do Something Daring": false,
        "Do Something You Just Haven’t Got Around To": false,
        "Do Something for Someone Else": false,
    });

    const debounceTimeouts = useRef({});

    useEffect(() => {
        fetchTasks();
        fetchCheckedItems();
    }, []);
    useEffect(() => {
      
        if (!isSubscribedAccountant()) {
          navigate("/users/userEdit");
          window.location.reload();
        }
      
      }, []);

    const fetchTasks = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.getbucket}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`
                }
            });

            if (response.data.status === "success") {
                setRoleModal(response.data.info);
            } else {
                console.error("Failed to fetch bucket list items");
            }
        } catch (error) {
            console.error("Error fetching bucket list items:", error.message);
        }
    };

    const fetchCheckedItems = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.getCheckedItems}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                }
            });

            if (response.data.status === "success") {
                const checkedData = response.data.data;
                const updatedSelectedItems = {
                    Travel: [],
                    Learn: [],
                    "Revisit the Past": [],
                    "Achieve a Life Event": [],
                    "Do Something Daring": [],
                    "Do Something You Just Haven’t Got Around To": [],
                    "Do Something for Someone Else": [],
                };

                checkedData.forEach(category => {
                    category.data.forEach(item => {
                        const type = category.type;
                        updatedSelectedItems[type] = updatedSelectedItems[type] || [];
                        updatedSelectedItems[type].push(item.type_id);
                    });
                });

                setSelectedItems(updatedSelectedItems);
            } else {
                toast.error(`Failed to fetch checked items.`);
            }
        } catch (error) {
            console.error(`Error fetching checked items`, error.message);
            toast.error(`An error occurred while fetching checked items.`);
        }
    };

    const handleCheckboxChange = async (type, itemId, itemName) => {
        setSelectedItems(prevState => {
            const currentSelection = prevState[type] || [];
            let newSelection;

            if (currentSelection.includes(itemId)) {
                newSelection = currentSelection.filter(id => id !== itemId);
            } else {
                if (currentSelection.length < 100) {
                    newSelection = [...currentSelection, itemId];
                } else {
                    toast.warning("You can only select up to 100 items.");
                    return prevState; 
                }
            }

            return { ...prevState, [type]: newSelection };
        });

        // Use the previous state to get the updated selections
        const updatedSelectedItems = { ...selectedItems, [type]: [...(selectedItems[type] || []), itemId] };

        // Filter out type_ids that don't belong to the current type
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('type', type);
        Object.entries(updatedSelectedItems).forEach(([key, value]) => {
            if (key === type) {
                value.forEach(id => formDataToSend.append('type_ids[]', id));
            }
        });

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.updateBucketStatus}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                }
            });

            if (response.data.status === "success") {
                // toast.success(`Bucket list items updated successfully!`);
            } else {
                // toast.error(`Failed to update bucket list items.`);
            }
        } catch (error) {
            console.error(`Error updating bucket list items:`, error.message);
            toast.error(`An error occurred while updating bucket list items.`);
        }
    };

    const handleCustomInputChange = (type, index, value) => {
        setCustomInputs(prev => ({
            ...prev,
            [`${type}-${index}`]: value,
        }));

        if (debounceTimeouts.current[`${type}-${index}`]) {
            clearTimeout(debounceTimeouts.current[`${type}-${index}`]);
        }

        debounceTimeouts.current[`${type}-${index}`] = setTimeout(() => {
            handleCustomInputSubmit(type, index, value);
        }, 2000);
    };

    const handleCustomInputSubmit = async (type, index, value) => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        const customItems = [{
            name: value,
            type
        }];

        customItems.forEach((item, idx) => {
            formDataToSend.append(`type[${idx + 1}]`, item.type);
            formDataToSend.append(`name[${idx + 1}]`, item.name);
        });

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.bucket_insert}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    'Content-Type': 'multipart/form-data',
                }
            });

            if (response.data.status === "success") {
                // toast.success("Custom bucket list item added successfully!");
                // Clear the input field after success and hide it
                setCustomInputs(prev => ({
                    ...prev,
                    [`${type}-${index}`]: ''
                }));
                setShowCustomFields(prev => ({
                    ...prev,
                    [type]: false
                }));
                fetchTasks();
                fetchCheckedItems();
            } else {
                toast.error("Failed to add custom bucket list item.");
            }
        } catch (error) {
            console.error("Error adding custom bucket list item:", error.message);
            toast.error("An error occurred while adding custom item.");
        }
    };

    const handleAddMoreFields = (type) => {
        setCustomFieldsCount(prev => ({
            ...prev,
            [type]: prev[type] + 1,
        }));
        setShowCustomFields(prev => ({
            ...prev,
            [type]: true
        }));
    };

    const renderCustomCheckboxes = (type) => {
        if (!showCustomFields[type]) return null;

        return (
            <div className="col-lg-6 d-flex my-1">
                <div>
                    <input
                        type="checkbox"
                        className="checkbox_animated mt-2"
                        onChange={() => handleCheckboxChange(type, `custom-${type}-${customFieldsCount[type]}`, customInputs[`${type}-${customFieldsCount[type]}`] || `Custom Bucket ${customFieldsCount[type] + 1}`)}
                    />
                </div>
                <div>
                    <input
                        type="text"
                        className="bucket-list-input-field form-control"
                        placeholder="Enter custom bucket list item"
                        value={customInputs[`${type}-${customFieldsCount[type]}`] || ''}
                        onChange={(e) => handleCustomInputChange(type, customFieldsCount[type], e.target.value)}
                    />
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className="col-sm-12">
                {["Travel", "Learn", "Revisit the Past", "Achieve a Life Event", "Do Something Daring", "Do Something You Just Haven’t Got Around To", "Do Something for Someone Else"].map((type) => (
                    <div className="card mt-4" key={type}>
                        <div className="card-header">
                            <h5>{type}</h5>
                        </div>
                        <div className="card-body m-2">
                            <div className="row">
                                {roleModal.filter(item => item.type === type).map((item, index) => (
                                    <div key={index} className="col-lg-6">
                                        <input
                                            type="checkbox"
                                            className="checkbox_animated"
                                            checked={selectedItems[type].includes(item.type_id)}
                                            onChange={() => handleCheckboxChange(type, item.type_id, item.name)}
                                        />
                                        <label  className={`form-check-label ${selectedItems[type]?.includes(item.type_id) ? "completed-item" : ""}`}>{item.name}</label>
                                    </div>
                                ))}
                                {renderCustomCheckboxes(type)}
                            </div>
                            <div className="float-end mt-4">
                               <button className="btn btn-primary mt-2" onClick={() => handleAddMoreFields(type)}>Add More</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default BucketList;
