import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { format } from 'date-fns';

const ViewModal = ({ isOpen, toggle, selectedItem }) => {
  const thStyle = {
    border: "1px solid gray",
    padding: "8px",
    textAlign: "left",
    background: "black",
    color: "white"
  };
  return (
    <Modal centered={true} show={isOpen} onHide={toggle} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Those I Want to Serve</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table">
          <tbody>
            <tr>
              <th  style={thStyle} colSpan={2}>Those I Want to Serve</th>
            </tr>
            <tr>
              <td>Date</td>
              <td>{selectedItem && format(new Date(selectedItem.created_at), 'yyyy-MM-dd')}</td>
            </tr>
            <tr>
              <td>Those I Want to Serve</td>
              <td>{selectedItem && selectedItem.text}</td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-secondary" onClick={toggle}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewModal;
