import React, { useCallback, useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import 'react-circular-progressbar/dist/styles.css';
import API, { isSubscribedAccountant } from "../../utils";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";

const DynamicStep = ({ questions, formData, handleChange  }) => (
    <>
        {questions.map((question) => (
            <div className="form-group row mb-2" key={question.id}>
                <label className="col-sm-12 col-form-label mb-2 p-0"><b>{question.question}</b></label>
                <textarea
                    className={`form-control `}
                    name={question.id}
                    value={formData[question.id]}
                    onChange={handleChange}
                    rows="4"
                ></textarea>
            </div>
        ))}
    </>
);

const MyIdentity = () => {
    const [questions, setQuestions] = useState([]);
    const uid = localStorage.getItem('user_id') || '';
    const [formData, setFormData] = useState({});
    const navigate = useNavigate()
    useEffect(() => {
      if (!isSubscribedAccountant()) {
        navigate("/users/userEdit");
        window.location.reload();
      }
    }, []);
    const handleSubmitmulti = (e) => {
        e.preventDefault();
        if (validate()) {
            handleSubmit();
        }
    };

   

    useEffect(() => {
        fetchQuestions();
    }, []);

    const fetchQuestions = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('main_category_id', "6");
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getLifeQuestion}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    },
                }
            );
            if (response.data.status === "success") {
                setQuestions(response.data.question || []);
                const initialFormData = {};
                (response.data.question || []).forEach((question) => {
                    initialFormData[question.id] = question.answer || '';
                });
                setFormData(initialFormData);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const validate = () => {
        return true;
    };

    const handleSubmit = async () => {
        const formDataToSend = new FormData();
        questions.forEach((question, index) => {
            formDataToSend.append(`question_id[${index + 1}]`, question.id);
            formDataToSend.append(`answer[${index + 1}]`, formData[question.id] || '');
        });

        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.updateAnswer}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                fetchQuestions();
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message || 'Failed to update answers');
            }
        } catch (error) {
            console.error('Error updating answers:', error);
            toast.error('Failed to update answers. Please try again later.');
        }
    };

    const debouncedUpdateAnswer = useCallback(
        debounce(async (questionId, answer) => {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('question_id[${index + 1}]', questionId);
            formDataToSend.append('answer[${index + 1}]', answer);

            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.updateAnswer}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                if (response.data.status === 'success') {
                    fetchQuestions();
                    //  toast.success(response.data.message);
                } else {
                    toast.error(response.data.message || 'Failed to update answer');
                }
            } catch (error) {
                console.error('Error updating answer:', error);
                toast.error('Failed to update answer. Please try again later.');
            }
        }, 2000),
        [uid]
    );

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        debouncedUpdateAnswer(name, value);
    };

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>My Identity</h5>
                            </div>
                            <form onSubmit={handleSubmitmulti}>
                                <div className="card-body m-3 ">
                                    <div className="textnots-container mb-3">
                                        <p className="textnots-text">
                                        At thirty-five I had a great career, many friends, and everything I really wanted. During that time,it was a real struggle for me to understand why I wasn’t happy when I had everything that I thought was important in life. Was I selfish? Were my expectations too high? I honestly couldn’t understand what was missing and how to fill this huge void that gnawed at me every day.
                                        </p>
                                        <p className="textnots-text">
                                        When I look back at my life, I realize that I really had no idea who I was or what made me happy.  I kept expecting something or someone to answer this question for me.
                                        </p>
                                        <p className="textnots-text">
                                        Who am I? What do I believe in? What is my purpose? What fills me with joy and excitement? The hardest part for me was just knowing where to begin. After much therapy, meditation, self-reflection, and reading, I asked myself five big questions that served as a launch pad to begin my journey of self-discovery.If you are ready to begin the process of truly understanding who you are meant to be, start here with these questions
                                        </p>
                                       
                                    </div>
                                    <DynamicStep questions={questions} formData={formData} handleChange={handleChange}  />
                                </div>
                               
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyIdentity;
