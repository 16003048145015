import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

const AffirmationAlert = () => {
  const [alertShown, setAlertShown] = useState(false);

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      
      // Check if it's 10 PM (22:00)
      if (hours === 19 && minutes === 59 && !alertShown) {
        Swal.fire({
          title: 'Reminder',
          text: 'You need to fill your today\'s affirmations!',
          icon: 'warning',
          confirmButtonText: 'OK'
        });
        setAlertShown(true); // Set alertShown to true to prevent future alerts
      }
    };

    // Check every minute
    const intervalId = setInterval(checkTime, 60000); // 60000 ms = 1 minute

    // Clean up the interval on component unmount
    return () => clearInterval();
  }, [alertShown]); // Add alertShown as a dependency

  return null; // This component doesn't render anything
};

export default AffirmationAlert;
