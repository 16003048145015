import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { mycorevalues } from "../../constant";
import { Button, Modal } from "react-bootstrap";
import { MdDelete } from 'react-icons/md';
import DataTable from 'react-data-table-component';
import { FaEye, FaRegEdit } from "react-icons/fa";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { IoCloudDownloadOutline } from "react-icons/io5";
import API from "../../utils";
import { toast } from "react-toastify";
import axios from "axios";
import EditModal from "./personal-financemodal/editpersonalfinance";

const MyPesonalFinance = () => {
  const uid = localStorage.getItem("user_id");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editedItem, setEditedItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isViewModalOpen, setIsViewModalOpen] = useState(false); // New state for view modal

  // finance 
  const [netWorth, setNetWorth] = useState(0);

  const thStyle = {
    border: "1px solid gray",
    padding: "8px",
    textAlign: "left",
    background: "black",
    color: "white"
  };
  const handleView = (item) => {
    setSelectedItem(item);
    toggleViewModal();
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setEditedItem(item);
    toggleEditModal();
  };

  const calculateNetWorth = () => {
    setNetWorth(assets - liabilities);
  };

  const togglePopup = () => {
   

    setIsPopupOpen(!isPopupOpen);

    setinvestmentPerformance("");
    setinvestmentPerformanceerror("");
    setinvestmentPerformancebordererror(false);

    setCore("");
    setLifelinedescription("");
    // Reset validation error states
    setCoreerror("");
    setLifelinedescriptionerror("");
    setCoreBorderError(false);

    setLifelinedescriptionBorderError(false);
    setIncome("");
    setIncomeerror("");
    setIncomeBoardererror(false);

    setexpenses("");
    setexpenseserror("");
    setexpensesboarderror(false);

    setSavings("");
    setSavingserror("");
    setSavingsbordererror(false);

    setexpenses("");
    setexpenseserror("");
    setexpensesboarderror(false);

    setInvestment("");
    setInvestmenterror("");
    setInvestmentbordererror(false);

    setCash("");
    setCasherror("");
    setexpensesboarderror(false);

    setSavings("");
    setSavingserror("");
    setCashbordererror(false);

    setLiquid("");
    setLiquiderror("");
    setLiquidbordererror(false);

    setSavings("");
    setSavingserror("");
    setSavingsbordererror(false);

    setInvestments("");
    setInvestmentserrors("");
    setInvestmentsbordererrors(false);

    setReal("");
    setRealerror("");
    setRealerrorborder(false);

    setInvestments("");
    setInvestmentserrors("");
    setInvestmentsbordererrors(false);

    setReal("");
    setRealerror("");
    setRealerrorborder(false);

    setProperty("");
    setPropertyerror("");
    setPropertyerrorborder(false);

    setLoan("");
    setloanserror("");
    setloanserrorborder(false);

    setCard("");
    setCarderror("");
    setCarderrorborder(false);

    setOther("");
    setOtherserror("");
    setOtherserrorborder(false);

    setMortgages("");
    setMortgageserror("");
    setMortgageserrorborder(false);
  };

  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  const toggleViewModal = () => {
    setIsViewModalOpen(!isViewModalOpen); // Toggle view modal state
  };

  const handleDelete = (item) => {
    setSelectedItem(item);
    toggleDeleteModal();
  };

  const handleDeleteItem = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('finance_id', selectedItem.id);
    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.deletepersonalfinance}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        // toast.success(response.data.message);
        toggleDeleteModal();
        fetchData();
      } else {
        // toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed to delete Personal Finance.");
    }
  };



  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value); // Update search query when input changes
  };


  // Define gratitudeEntries here



  const columns = [
    {
      name: 'Assets',
      selector: (row) => row.assets,
      sortable: true,
    },
    // {
    //   name: 'Net Worth',
    //   selector: (row) => row.liabilities,
    //   sortable: true,
    // },
    {
      name: 'Income',
      selector: (row) => row.income,
      sortable: true,
    },
    {
      name: 'expenses',
      selector: (row) => row.expenses,
      sortable: true,
    },
    {
      name: 'Savings',
      selector: (row) => row.savings,
      sortable: true,
    },
    // {
    //   name: 'Investment Performance',
    //   selector: (row) => row.investmentperformance,
    //   sortable: true,
    // },
    {
      name: 'Cash Flow',
      selector: (row) => row.cash_flow,
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => (
        <>
          <Button className="btn-secondary btn-pill main-edit-view-buttons mx-1" onClick={() => handleView(row)}><FaEye /></Button> {/* Added onClick to open view modal */}
          <Button className="btn-secondary btn-pill main-edit-view-buttons mx-1" onClick={() => handleEdit(row)}><FaRegEdit /></Button>
          <Button variant="danger" className="btn-primary btn-pill main-delete-buttons mx-1" onClick={() => handleDelete(row)}>
            <MdDelete />
          </Button>
        </>
      ),
    },
  ];
  const [assets, setCore] = useState("");
  const [coreerror, setCoreerror] = useState("");
  const [coreBorderError, setCoreBorderError] = useState(false);

  const [liabilities, setLifelinedescription] = useState("");
  const [Lifelinedescriptionerror, setLifelinedescriptionerror] = useState("");
  const [LifelinedescriptionBorderError, setLifelinedescriptionBorderError] = useState(false);

  const [income, setIncome] = useState("");
  const [incomeerror, setIncomeerror] = useState("");
  const [incomeboardererror, setIncomeBoardererror] = useState(false);

  const [expenses, setexpenses] = useState("");
  const [expenseserror, setexpenseserror] = useState("");
  const [expensesboardererror, setexpensesboarderror] = useState(false);

  const [savings, setSavings] = useState("");
  const [savingserror, setSavingserror] = useState("");
  const [savingsbordererror, setSavingsbordererror] = useState(false);


  const [investment_performance, setinvestmentPerformance] = useState("");
  const [investmentPerformanceerror, setinvestmentPerformanceerror] = useState("");
  const [investmentPerformancebordererror, setinvestmentPerformancebordererror] = useState(false);

  const [cash_flow, setCash] = useState("");
  const [casherror, setCasherror] = useState("");
  const [cashbordererror, setCashbordererror] = useState(false);


  const [investment, setInvestment] = useState("");
  const [investmenterror, setInvestmenterror] = useState("");
  const [investmentbordererror, setInvestmentbordererror] = useState(false);


  const [liquid_assets, setLiquid] = useState("");
  const [liquiderror, setLiquiderror] = useState("");
  const [liquidborderror, setLiquidbordererror] = useState(false);

  const [investments, setInvestments] = useState("");
  const [Investmentserrors, setInvestmentserrors] = useState("");
  const [investmentsbordererrors, setInvestmentsbordererrors] = useState(false);

  const [real_estate, setReal] = useState("");
  const [realerror, setRealerror] = useState("");
  const [realerrorborder, setRealerrorborder] = useState(false);

  const [personal_property, setProperty] = useState("");
  const [propertyerror, setPropertyerror] = useState("");
  const [propertyerrorborder, setPropertyerrorborder] = useState(false);

  const [mortgages, setMortgages] = useState("");
  const [mortgageserror, setMortgageserror] = useState("");
  const [mortgageserrorborder, setMortgageserrorborder] = useState(false);

  const [loans, setLoan] = useState("");
  const [loanerror, setloanserror] = useState("");
  const [loanerrorborder, setloanserrorborder] = useState(false);

  const [credit_card_debt, setCard] = useState("");
  const [carderror, setCarderror] = useState("");
  const [carderrorborder, setCarderrorborder] = useState(false);

  const [other_liabilities, setOther] = useState("");
  const [Othererror, setOtherserror] = useState("");
  const [Othererrorborder, setOtherserrorborder] = useState(false);


  const [gratitudeEntries, setGratitudeEntries] = useState([]);

  const fetchData = async () => {
    try {

      const formDataToSend = new FormData();
      formDataToSend.append('user_id', uid);

      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.getpersonalfinance}`, formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "success") {
        setGratitudeEntries(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Fetch gratitude error', error);
      toast.error("Failed to fetch gratitudes.");
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  const filteredEntries = gratitudeEntries.filter(entry =>
    entry.other_liabilities.toLowerCase().includes(searchQuery.toLowerCase()) ||
    entry.liabilities.toLowerCase().includes(searchQuery.toLowerCase()) ||
    entry.credit_card_debt.toLowerCase().includes(searchQuery.toLowerCase()) ||
    entry.mortgages.toLowerCase().includes(searchQuery.toLowerCase()) ||
    entry.loans.toLowerCase().includes(searchQuery.toLowerCase()) ||
    entry.real_estate.toLowerCase().includes(searchQuery.toLowerCase()) ||
    entry.investments.toLowerCase().includes(searchQuery.toLowerCase()) ||
    entry.liquid_assets.toLowerCase().includes(searchQuery.toLowerCase()) ||
    entry.cash_flow.toLowerCase().includes(searchQuery.toLowerCase()) ||
    entry.investment_performance.toLowerCase().includes(searchQuery.toLowerCase()) ||
    entry.savings.toLowerCase().includes(searchQuery.toLowerCase()) ||
    entry.expenses.toLowerCase().includes(searchQuery.toLowerCase()) ||
    entry.income.toLowerCase().includes(searchQuery.toLowerCase())||
    entry.assets.toLowerCase().includes(searchQuery.toLowerCase()) 

  );

  const handleAdd = async () => {
    let isError = false;
    if (!investment_performance) {
      setinvestmentPerformanceerror("This Field Required");
      setinvestmentPerformancebordererror(true);
      isError = true;
    } else {
      setinvestmentPerformanceerror("");
      setinvestmentPerformancebordererror(false);
    }
    if (!liabilities) {
      setLifelinedescriptionerror("This Field Required");
      setLifelinedescriptionBorderError(true);
      isError = true;
    } else {
      setLifelinedescriptionerror("");
      setLifelinedescriptionBorderError(false);
    }

    if (!loans) {
      setloanserror("This Field Required");
      setloanserrorborder(true);
      isError = true;
    } else {
      setloanserror("");
      setloanserrorborder(false);
    }
    if (!other_liabilities) {
      setOtherserror("This Field Required");
      setOtherserrorborder(true);
      isError = true;
    } else {
      setOtherserror("");
      setOtherserrorborder(false);
    }
    if (!credit_card_debt) {
      setCarderror("This Field Required");
      setCarderrorborder(true);
      isError = true;
    } else {
      setCarderror("");
      setCarderrorborder(false);
    }
    if (!mortgages) {
      setMortgageserror("This Field Required");
      setMortgageserrorborder(true);
      isError = true;
    } else {
      setMortgageserror("");
      setMortgageserrorborder(false);
    }
    if (!personal_property) {
      setPropertyerror("This Field Required");
      setPropertyerrorborder(true);
      isError = true;
    } else {
      setPropertyerror("");
      setPropertyerrorborder(false);
    }
    if (!real_estate) {
      setRealerror("This Field Required");
      setRealerrorborder(true);
      isError = true;
    } else {
      setRealerror("");
      setRealerrorborder(false);
    }
    if (!investments) {
      setInvestmentserrors("This Field Required");
      setInvestmentsbordererrors(true);
      isError = true;
    } else {
      setInvestmentserrors("");
      setInvestmentsbordererrors(false);
    }
    if (!liquid_assets) {
      setLiquiderror("This Field Required");
      setLiquidbordererror(true);
      isError = true;
    } else {
      setLiquiderror("");
      setLiquidbordererror(false);
    }
    if (!cash_flow) {
      setCasherror("This Field Required");
      setCashbordererror(true);
      isError = true;
    } else {
      setCasherror("");
      setCashbordererror(false);
    }
    if (!expenses) {
      setIncomeerror("This Field Required");
      setIncomeBoardererror(true);
      isError = true;
    } else {
      setexpenseserror("");
      setexpensesboarderror(false);
    }
    if (!savings) {
      setSavingserror("This Field Required");
      setSavingsbordererror(true);
      isError = true;
    } else {
      setSavingserror("");
      setSavingsbordererror(false);
    }
    if (!income) {
      setexpenseserror("This Field Required");
      setexpensesboarderror(true);
      isError = true;
    } else {
      setIncomeerror("");
      setIncomeBoardererror(false);
    }
    if (isError) {
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    formDataToSend.append('other_liabilities', other_liabilities);
    formDataToSend.append('liabilities', liabilities);
    formDataToSend.append('credit_card_debt', credit_card_debt);
    formDataToSend.append('loans', loans);
    formDataToSend.append('mortgages', mortgages);
    formDataToSend.append('personal_property', personal_property);
    formDataToSend.append('real_estate', real_estate);
    formDataToSend.append('investments', investments);
    formDataToSend.append('liquid_assets', liquid_assets);
    formDataToSend.append('cash_flow', cash_flow);
    formDataToSend.append('investment_performance', investment_performance);
    formDataToSend.append('savings', savings);
    formDataToSend.append('expenses', expenses);
    formDataToSend.append('income', income);
    formDataToSend.append('assets', assets);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.addpersonalfinance}`, formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      console.log("respons", response.data);
      if (response.data.status === "success") {
        // toast.success(response.data.message);
        fetchData();
        togglePopup();
      } else {
        // toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error);
    }

  };



  const handleEditItem = async () => {
    let isError = false;

    // Check if any of the fields are empty
    if (!editedItem.assets || !editedItem.liabilities || !editedItem.income || !editedItem.expenses || !editedItem.savings || !editedItem.investment_performance || !editedItem.cash_flow || !editedItem.liquid_assets || !editedItem.real_estate || !editedItem.personal_property || !editedItem.other_liabilities || !editedItem.credit_card_debt || !editedItem.loans) {
      // Set error states and display error message
      setCoreerror(!editedItem.assets ? "This Field Required" : "");
      setLifelinedescriptionerror(!editedItem.liabilities ? "This Field Required" : "");
      setIncomeerror(!editedItem.income ? "This Field Required" : "");
      setexpenseserror(!editedItem.expenses ? "This Field Required" : "");
      setSavingserror(!editedItem.savings ? "This Field Required" : "");
      setinvestmentPerformanceerror(!editedItem.investment_performance ? "This Field Required" : "");
      setCasherror(!editedItem.cash_flow ? "This Field Required" : "");
      setLiquiderror(!editedItem.liquid_assets ? "This Field Required" : "");
      setInvestmentserrors(!editedItem.investments ? "This Field Required" : "");
      setRealerror(!editedItem.real_estate ? "This Field Required" : "");
      setPropertyerror(!editedItem.personal_property ? "This Field Required" : "");
      setMortgageserror(!editedItem.mortgages ? "This Field Required" : "");
      setloanserror(!editedItem.loans ? "This Field Required" : "");
      setCarderror(!editedItem.credit_card_debt ? "This Field Required" : "");
      setOtherserror(!editedItem.other_liabilities ? "This Field Required" : "");

      // Set border error for each field if empty
      setCoreBorderError(!editedItem.assets);
      setLifelinedescriptionBorderError(!editedItem.liabilities);
      setIncomeBoardererror(!editedItem.income);
      setexpensesboarderror(!editedItem.expenses);
      setSavingsbordererror(!editedItem.savings);
      setinvestmentPerformancebordererror(!editedItem.investment_performance);
      setCashbordererror(!editedItem.cash_flow);
      setLiquidbordererror(!editedItem.liquid_assets);
      setInvestmentsbordererrors(!editedItem.investments);
      setRealerrorborder(!editedItem.real_estate);
      setPropertyerrorborder(!editedItem.personal_property);
      setMortgageserrorborder(!editedItem.mortgages);
      setloanserrorborder(!editedItem.loans);
      setCarderrorborder(!editedItem.credit_card_debt);
      setOtherserrorborder(!editedItem.other_liabilities);


      // Set isError to true indicating there is an error
      isError = true;
    }

    // if (!isError) {
    //   return;
    // }

    const formDataToSend = new FormData();
    formDataToSend.append('finance_id', selectedItem.id);
    formDataToSend.append('other_liabilities', other_liabilities);
    formDataToSend.append('liabilities', liabilities);
    formDataToSend.append('credit_card_debt', credit_card_debt);
    formDataToSend.append('loans', loans);
    formDataToSend.append('mortgages', mortgages);
    formDataToSend.append('personal_property', personal_property);
    formDataToSend.append('real_estate', real_estate);
    formDataToSend.append('investments', investments);
    formDataToSend.append('liquid_assets', liquid_assets);
    formDataToSend.append('cash_flow', cash_flow);
    formDataToSend.append('investment_performance', investment_performance);
    formDataToSend.append('savings', savings);
    formDataToSend.append('expenses', expenses);
    formDataToSend.append('income', income);
    formDataToSend.append('assets', assets);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.updatepersonalfinance}`, formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      console.log("respons", response.data);
      if (response.data.status === "success") {
        toast.success(response.data.message);
        fetchData();
        toggleEditModal();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleDownloadPdf = async () => {
    const element = componentRef.current;
    const canvas = await html2canvas(element);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("PersonalFinance.pdf");
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header ">
                <div className="row">
                  <div className=" align-items-center justify-content-between d-flex">
                    <h5>My Personal Finance</h5>
                    <div className="">
                      <button className="btn btn-success main-buttons me-lg-2" onClick={handleDownloadPdf}>
                        Download Report</button>
                      <button className="btn btn-success main-buttons " onClick={togglePopup}>Add Personal Finance</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="card-body m-3">
              <div className="row">
                <div className="col-md-9"></div>
                <div className="col-md-3">
                  <div className="form-group text-end mb-3 ">
                    <input className="form-control" type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
                  </div>
                </div>
              </div>
              <div ref={componentRef}>
                <DataTable
                  columns={columns}
                  data={filteredEntries}
                  pagination
                  persistTableHead={true}
                />
              </div>
            </div>
          </div>
        </div>

      </div>
      {isPopupOpen && (
        <Modal centered={true} show={isPopupOpen} onHide={togglePopup}>
          <Modal.Header closeButton>
            <Modal.Title>Add Personal Finance</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="theme-form">
              <h5>Personal Net Worth</h5>
              <div className="form-group">
                <label className="col-form-label pt-0" htmlFor="assets">Assets</label>
                <input type="number"
                  className={`form-control ${coreerror ? 'is-invalid' : ''}`}
                  name="assets"
                  onChange={(e) => {
                    setCore(e.target.value);
                    setCoreerror("");
                    setCoreBorderError(false);
                  }}
                />
                {coreerror && <div className="text-danger">{coreerror}</div>}
              </div>

              <div className="form-group">
                <label className="col-form-label pt-0" htmlFor="liabilities">Liabilities</label>
                <input type="number"
                  className={`form-control ${Lifelinedescriptionerror ? 'is-invalid' : ''}`}
                  rows="4"
                  name="liabilities"
                  onChange={(e) => {
                    setLifelinedescription(e.target.value);
                    setLifelinedescriptionerror("");
                    setLifelinedescriptionBorderError(false);
                  }}
                />
                {Lifelinedescriptionerror && <div className="text-danger">{Lifelinedescriptionerror}</div>}
              </div>


              <hr />

              <h5>Monthly Financial Statement</h5>
              <div className="form-group">
                <label className="col-form-label pt-0" htmlFor="income">Income</label>
                <input type="number"
                  className={`form-control ${incomeerror ? 'is-invalid' : ''}`}
                  name="income"
                  onChange={(e) => {
                    setIncome(e.target.value);
                    setIncomeerror("");
                    setIncomeBoardererror(false);
                  }}
                />
                {incomeerror && <div className="text-danger">{incomeerror}</div>}
              </div>

              <div className="form-group">
                <label className="col-form-label pt-0" htmlFor="expenses">expenses</label>
                <input type="number"
                  className={`form-control ${expenseserror ? 'is-invalid' : ''}`}
                  name="expenses"
                  onChange={(e) => {
                    setexpenses(e.target.value);
                    setexpenseserror("");
                    setexpensesboarderror(false);
                  }}
                />
                {expenseserror && <div className="text-danger">{expenseserror}</div>}
              </div>

              <div className="form-group">
                <label className="col-form-label pt-0" htmlFor="savings">Savings</label>
                <input type="number"
                  className={`form-control ${savingserror ? 'is-invalid' : ''}`}
                  name="savings"
                  onChange={(e) => {
                    setSavings(e.target.value);
                    setSavingserror("");
                    setSavingsbordererror(false);
                  }}
                />
                {savingserror && <div className="text-danger">{savingserror}</div>}
              </div>

              <div className="form-group">
                <label className="col-form-label pt-0" htmlFor="investmentPerformance">Investment Performance</label>
                <input type="number"
                  className={`form-control ${investmentPerformanceerror ? 'is-invalid' : ''}`}
                  name="investment_performance"
                  onChange={(e) => {
                    setinvestmentPerformance(e.target.value);
                    setinvestmentPerformanceerror("");
                    setinvestmentPerformancebordererror(false);
                  }}
                />
                {investmentPerformanceerror && <div className="text-danger">{investmentPerformanceerror}</div>}
              </div>

              <div className="form-group">
                <label className="col-form-label pt-0" htmlFor="cashFlow">Cash Flow</label>
                <input type="number"
                  className={`form-control ${casherror ? 'is-invalid' : ''}`}
                  name="cash_flow"
                  onChange={(e) => {
                    setCash(e.target.value);
                    setCasherror("");
                    setCashbordererror(false);
                  }}
                />
                {casherror && <div className="text-danger">{casherror}</div>}
              </div>

              <hr />

              <h5>Assets</h5>
              <div className="form-group">
                <label className="col-form-label pt-0" htmlFor="liquidAssets">Liquid Assets</label>
                <input type="number"
                  className={`form-control ${liquiderror ? 'is-invalid' : ''}`}
                  name="liquid_assets"
                  onChange={(e) => {
                    setLiquid(e.target.value);
                    setLiquiderror("");
                    setLiquidbordererror(false);
                  }}
                />
                {liquiderror && <div className="text-danger">{liquiderror}</div>}
              </div>

              <div className="form-group">
                <label className="col-form-label pt-0" htmlFor="investments">Investments</label>
                <input type="number"
                  className={`form-control ${Investmentserrors ? 'is-invalid' : ''}`}
                  name="investments"
                  onChange={(e) => {
                    setInvestments(e.target.value);
                    setInvestmentserrors("");
                    setInvestmentsbordererrors(false);
                  }}
                />
                {Investmentserrors && <div className="text-danger">{Investmentserrors}</div>}
              </div>

              <div className="form-group">
                <label className="col-form-label pt-0" htmlFor="realEstate">Real Estate</label>
                <input type="number"
                  className={`form-control ${realerror ? 'is-invalid' : ''}`}
                  name="real_estate"
                  onChange={(e) => {
                    setReal(e.target.value);
                    setRealerror("");
                    setRealerrorborder(false);
                  }}
                />
                {realerror && <div className="text-danger">{realerror}</div>}
              </div>

              <div className="form-group">
                <label className="col-form-label pt-0" htmlFor="personalProperty">Personal Property</label>
                <input type="number"
                  className={`form-control ${propertyerror ? 'is-invalid' : ''}`}
                  name="personal_property"
                  onChange={(e) => {
                    setProperty(e.target.value);
                    setPropertyerror("");
                    setPropertyerrorborder(false);
                  }}
                />
                {propertyerror && <div className="text-danger">{propertyerror}</div>}
              </div>

              <hr />

              <h5>Liabilities</h5>
              <div className="form-group">
                <label className="col-form-label pt-0" htmlFor="mortgages">Mortgages</label>
                <input type="number"
                  className={`form-control ${mortgageserror ? 'is-invalid' : ''}`}
                  name="mortgages"
                  onChange={(e) => {
                    setMortgages(e.target.value);
                    setMortgageserror("");
                    setMortgageserrorborder(false);
                  }}
                />
                {mortgageserror && <div className="text-danger">{mortgageserror}</div>}
              </div>

              <div className="form-group">
                <label className="col-form-label pt-0" htmlFor="loans">loans</label>
                <input type="number"
                  className={`form-control ${loanerror ? 'is-invalid' : ''}`}
                  name="loans"
                  onChange={(e) => {
                    setLoan(e.target.value);
                    setloanserror("");
                    setloanserrorborder(false);
                  }}
                />
                {loanerror && <div className="text-danger">{loanerror}</div>}
              </div>

              <div className="form-group">
                <label className="col-form-label pt-0" htmlFor="creditCardDebt">Credit Card Debt</label>
                <input type="number"
                  className={`form-control ${carderror ? 'is-invalid' : ''}`}
                  name="credit_card_debt"
                  onChange={(e) => {
                    setCard(e.target.value);
                    setCarderror("");
                    setCarderrorborder(false);
                  }}
                />
                {carderror && <div className="text-danger">{carderror}</div>}
              </div>

              <div className="form-group">
                <label className="col-form-label pt-0" htmlFor="otherLiabilities">Other Liabilities</label>
                <input type="number"
                  className={`form-control ${Othererror ? 'is-invalid' : ''}`}
                  name="other_liabilities"
                  onChange={(e) => {
                    setOther(e.target.value);
                    setOtherserror("");
                    setOtherserrorborder(false);
                  }}
                />
                {Othererror && <div className="text-danger">{Othererror}</div>}
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary me-1" onClick={togglePopup}>Cancel</button>
            <button className="btn btn-secondary" onClick={handleAdd}>Submit</button>
          </Modal.Footer>
        </Modal>
      )}
      {isDeleteModalOpen && (
        <Modal centered={true} show={isDeleteModalOpen} onHide={toggleDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Delete My Personal Finance</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete {selectedItem && selectedItem.thoseiwanttoserve}?</p>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={toggleDeleteModal}>Cancel</button>
            <button className="btn btn-primary me-1" onClick={() => handleDeleteItem()}>Delete</button>
          </Modal.Footer>
        </Modal>
      )}
   {isEditModalOpen && (
        <EditModal
          isEditModalOpen={isEditModalOpen}
          toggleEditModal={toggleEditModal}
          selectedItem={selectedItem}
          fetchData={fetchData}
        />
      )}
      <div className="card-body btn-showcase">
        {isViewModalOpen && (
          <Modal centered={true} show={isViewModalOpen} onHide={toggleViewModal} size="lg" >
            <Modal.Header closeButton>
              <Modal.Title>View My Personal Finance</Modal.Title>
            </Modal.Header>
            <Modal.Body>


              <table className="table">

                <thead>


                </thead>
                <tbody>
                  <tr>
                    <th style={thStyle} colSpan={2}>Personal Net Worth</th>
                  </tr>
                  <tr>
                    <td>Assets</td>
                    <td>{selectedItem.assets}</td>
                  </tr>
                  <tr>
                    <td>Liabilities</td>
                    <td>{selectedItem.liabilities}</td>
                  </tr>
                  {/* <tr>
                    <td>Net Worth</td>
                    <td>{selectedItem.networth}</td>
                  </tr> */}
                  <tr>
                    <th style={thStyle} colSpan={2}>Monthly Financial Statement</th>
                  </tr>
                  <tr>
                    <td>Income</td>
                    <td>{selectedItem.income}</td>
                  </tr>
                  <tr>
                    <td>expenses</td>
                    <td>{selectedItem.expenses}</td>
                  </tr>
                  <tr>
                    <td>Savings</td>
                    <td>{selectedItem.savings}</td>
                  </tr>
                  <tr>
                    <td>Investment Performance</td>
                    <td>{selectedItem.investment_performance}</td>
                  </tr>
                  <tr>
                    <td>Cash Flow</td>
                    <td>{selectedItem.cash_flow}</td>
                  </tr>
                  <tr>
                    <th style={thStyle} colSpan={2}>Assets</th>
                  </tr>
                  <tr>
                    <td>Liquid Assets</td>
                    <td>{selectedItem.liquid_assets}</td>
                  </tr>
                  <tr>
                    <td>Investments</td>
                    <td>{selectedItem.investments}</td>
                  </tr>
                  <tr>
                    <td>Real Estate</td>
                    <td>{selectedItem.real_estate}</td>
                  </tr>
                  <tr>
                    <td>Personal Property</td>
                    <td>{selectedItem.personal_property}</td>
                  </tr>
                  <tr>
                    <th style={thStyle} colSpan={2}>Liabilities</th>
                  </tr>
                  <tr>
                    <td>Mortgages</td>
                    <td>{selectedItem.mortgages}</td>
                  </tr>
                  <tr>
                    <td>loans</td>
                    <td>{selectedItem.loans}</td>
                  </tr>
                  <tr>
                    <td>Credit Card Debt</td>
                    <td>{selectedItem.credit_card_debt}</td>
                  </tr>
                  <tr>
                    <td>Other Liabilities</td>
                    <td>{selectedItem.other_liabilities}</td>
                  </tr>
                </tbody>
              </table>

            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-secondary" onClick={toggleViewModal}>Close</button> {/* Close button */}
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div>
  );
}

export default MyPesonalFinance;
