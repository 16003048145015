import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DeleteModal = ({ isOpen, toggle, handleDeleteItem, selectedItem }) => {
  return (
    <Modal centered={true} show={isOpen} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>Those I Want To Serve Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete {selectedItem && selectedItem.text}?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-secondary" onClick={toggle}>Cancel</Button>
        <Button className="btn btn-danger me-1" onClick={handleDeleteItem}>Delete</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
