import React, { Fragment, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Col, Row } from 'react-bootstrap';
import { Progress } from 'reactstrap';
import BarChart from '../dashboard/defaultCompo/lifePlanBarChart';

const ChartJs = () => {
  

  return (
    <Fragment>
      {/* <Breadcrumb title="Life 360 Progress" parent="My Reports" /> */}
      <div className="col-sm-12">
      <BarChart/>
      </div>
    </Fragment>
  );
};

export default ChartJs;
