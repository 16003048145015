import React, { useEffect } from 'react';
import Swal from 'sweetalert2';

const DailyAlert = () => {
  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      
      // Check if it's 1 PM (13:00)
      if (hours === 21 && minutes === 59) {
        Swal.fire({
          title: 'Reminder',
          text: 'You need to fill your today\'s gratitude!',
          icon: 'info',
          confirmButtonText: 'OK'
        });
      }
    };

    // Check every minute
    const intervalId = setInterval(checkTime, 60000); // 60000 ms = 1 minute

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return null;
};

export default DailyAlert;
