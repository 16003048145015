import React, { useState, useEffect } from "react";
import axios from "axios";
import { Col, Nav, NavItem } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from "../../utils";
import PersonalLearningPath from "./My-notestab/PersonalLearning";

const IndexNotes = () => {
    const [activeTab, setActiveTab] = useState('1');
    const [state, setState] = useState({
        questions: [],
        errors: {}
    });

    const fetchData = async () => {
        try {
            const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.get_question_from_notes}`, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                setState(prevState => ({
                    ...prevState,
                    questions: response.data.questions
                }));
            } else {
                setState(prevState => ({
                    ...prevState,
                    errors: { ...prevState.errors, answer: response.data.answer }
                }));
            }
        } catch (error) {
            console.error('Error fetching data', error);
            toast.error('Failed to fetch data');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Find the current question based on activeTab
    const currentQuestion = state.questions.find(question => question.id === activeTab);

    return (
        <div>
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <Col className="user-edit-sublink d-none d-md-flex" sm="2" xs="12">
                            <Nav className="nav nav-notes flex-column nav-pills">
                                {state.questions.map(question => (
                                    <NavItem className="my-2" key={question.id}>
                                        <Nav.Link
                                            className={activeTab === question.id ? 'active' : ''}
                                            onClick={() => setActiveTab(question.id)}
                                        >
                                            {question.question}
                                        </Nav.Link>
                                    </NavItem>
                                ))}
                            </Nav>
                        </Col>

                        <Col sm="10" xs="12">
                            <PersonalLearningPath
                                activeTab={activeTab}
                                question={currentQuestion} 
                            />
                        </Col>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IndexNotes;
