import React from "react";
import { Modal, Button } from "react-bootstrap";
import { format } from "date-fns";

const ViewModal = ({ isViewModalOpen, toggleViewModal, selectedItem }) => {
  const thStyle = {
    border: "1px solid gray",
    padding: "8px",
    textAlign: "left",
    background: "black",
    color: "white"
  };

  return (
    <Modal centered={true} show={isViewModalOpen} onHide={toggleViewModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Gratitude Detail</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table">
          <thead></thead>
          <tbody>
            <tr>
              <th style={thStyle} colSpan={2}>Gratitude Detail</th>
            </tr>
            <tr>
              <td>Date</td>
              <td>{selectedItem && format(new Date(selectedItem.created_at), 'yyyy-MM-dd')}</td>
            </tr>
            <tr>
              <td>Daily Gratitude 1</td>
              <td>{selectedItem.gratitude[0]}</td>
            </tr>
            <tr>
              <td>Daily Gratitude 2</td>
              <td>{selectedItem.gratitude[1]}</td>
            </tr>
            <tr>
              <td>Daily Gratitude 3</td>
              <td>{selectedItem.gratitude[2]}</td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={toggleViewModal}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewModal;
