import React, { useState, useEffect } from "react";
import { Button } from 'reactstrap';
import { MdDelete } from 'react-icons/md';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NoTasksFound } from "../../../constant";
import DataTable from "react-data-table-component";
import { FaEye, FaRegEdit } from "react-icons/fa";
import axios from "axios";
import API from "../../../utils";
import { parseISO } from 'date-fns';
import { differenceInDays, format } from 'date-fns';

const CreatedByme = ({ RemoveTask, openEditModal, openViewModal, mytasks, getDetails }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [tasks, setTasks] = useState(mytasks);

  useEffect(() => {
    setTasks(mytasks);
  }, [mytasks]);

  const handleCheckboxCheck = async (taskid, isChecked) => {
    const newTasks = tasks.map(task =>
      task.id === taskid ? { ...task, is_checked: isChecked ? "1" : "0" } : task
    );
    setTasks(newTasks);

    const formDataToSend = new FormData();
    formDataToSend.append('id', taskid);
    formDataToSend.append('is_checked', isChecked ? "1" : "0");

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.update_dashboard_task}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        // toast.success(response.data.message);
        getDetails();
      } else {
        toast.error(response.data.message);
        const revertedTasks = tasks.map(task =>
          task.id === taskid ? { ...task, is_checked: isChecked ? "0" : "1" } : task
        );
        setTasks(revertedTasks);
      }
    } catch (error) {
      console.error("Error during update:", error);
      toast.error('An error occurred while updating the task.');
      const revertedTasks = tasks.map(task =>
        task.id === taskid ? { ...task, is_checked: isChecked ? "0" : "1" } : task
      );
      setTasks(revertedTasks);
    }
  };

  const columns = [
    {
      name: 'Completed',
      selector: row => row.status,
      cell: row => (
        <input
          className="checkbox_animated"
          id="chk-ani1"
          type="checkbox"
          checked={row.status === 'complete'}
          onChange={(e) => handleCheckboxCheck(row.id, e.target.checked)}
        />
      ),
      width: "11%",
      sortable: true,
    },
    {
      name: 'Task Name',
      selector: row => row.title || 'N/A',
      width: "25%",
      sortable: true,
    },
    {
      name: 'Criticality',
      selector: row => <div className="criticality-div">{row.criticality || 'N/A'}</div>,
      width: "11%",
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => (
        <div
          className={`status-div ${row.status}`}
          style={{
            backgroundColor:
              row.status === 'not-started' ? '#F9C74F' :
              row.status === 'In Process' ? '#90BE6D' :
              row.status === 'on-hold' ? '#F8961E' :
              row.status === 'complete' ? '#43AA8B' : 'transparent',
            padding: '5px',
            color: 'black',
            textAlign: 'center',
            borderRadius: '4px',
          }}
        >
          {row.status || 'N/A'}
        </div>
      ),
      sortable: true,
      width: "11%"
    }    ,
    
    {
      name: 'Life Area',
      selector: row => row.life_area || 'N/A',
      sortable: true,
      width: "11%"
    },
    {
      name: 'Due Date',
      selector: row => {
        const dueDate = row.due_date ? new Date(row.due_date) : null;
        if (!dueDate) return 'N/A';
  
        const today = new Date();
        const daysDifference = differenceInDays(dueDate, today);
  
        if (daysDifference === 0) {
          return "Due Today";
        } else if (daysDifference > 0) {
          return `Due in ${daysDifference} Days`;
        } else {
          return `Overdue by ${Math.abs(daysDifference)} Days`;
        }
      },
      sortable: true,
      width: "16%"
    },
    {
      name: 'Action',
      cell: row => (
        <div className="d-flex justify-content-center">
          <Button variant="primary" className="mx-1 btn-primary btn-pill main-edit-view-buttons" onClick={() => openViewModal(row)}><FaEye /></Button>
          <Button variant="primary" className="mx-1 btn-primary btn-pill main-edit-view-buttons" onClick={() => openEditModal(row)}><FaRegEdit /></Button>
          <Button className="mx-1 btn-danger btn-pill main-delete-buttons" onClick={() => RemoveTask(row)}><MdDelete /></Button>
        </div>
      ),
      width: "9%"
    },
  ];
  

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredTasks = tasks.filter(task =>
    task.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    task.criticality.toLowerCase().includes(searchQuery.toLowerCase()) ||
    task.life_area.toLowerCase().includes(searchQuery.toLowerCase()) ||
    task.status.toLowerCase().includes(searchQuery.toLowerCase()) 
  );

  return (
    <div>
      <div className='row'>
        <div className="col-md-9">
        </div>
        <div className="col-md-3 col-sm-12 text-end taskapp-table-Search">
          <input className="form-control" type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
        </div>
      </div>
      <div>
        <DataTable
          columns={columns}
          data={filteredTasks}
          className='taskapp-tables'
          pagination
          persistTableHead={true}
        />
      </div>
    </div>
  );
};

export default CreatedByme;
