import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input, Label } from 'reactstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import API from '../../../utils';

const EditTaskModal = ({ isOpen, toggle, task, getDetails }) => {
  const [tasklist, setTasklist] = useState([]);
  const [formData, setFormData] = useState({
    title: '',
    criticality: '',
    due_date: '',
    description: '',
    status: '',
    life_area: '',
  });

  const fetchTasks = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.allTask}/${uid}`, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        }
      });
      const data = response.data;
      if (data.status === "success") {
        setTasklist(response.data.tasks);
      } else {
        console.error("Failed to fetch tasks");
      }
    } catch (error) {
      console.error("Error fetching tasks:", error.message);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  const uid = localStorage.getItem("user_id");

  useEffect(() => {
    if (task) {
      setFormData({
        title: task.title || '',
        criticality: task.criticality || '',
        due_date: task.due_date || '',
        description: task.description || '',
        status: task.status || '',
        life_area: task.life_area || '',
      });
    }
  }, [task]);

  const handleModalToggle = () => {
    toggle();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('title', formData.title);
    formDataToSend.append('description', formData.description);
    formDataToSend.append('criticality', formData.criticality);
    formDataToSend.append('status', formData.status);
    formDataToSend.append('life_area', formData.life_area);
    formDataToSend.append('due_date', formData.due_date);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.edittasks}/${task.id}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data && response.data.status === "success") {
        // toast.success(response.data.message);
        toggle();
        getDetails();
        const updatedEntries = tasklist.map(entry => {
          if (entry.id === task.id) {
            return {
              ...entry,
              title: formData.title,
              description: formData.description,
              criticality: formData.criticality,
              status: formData.status,
              life_area: formData.life_area,
              due_date: formData.due_date,
            };
          }
          return entry;
        });
        setTasklist(updatedEntries);
      } else {
        // toast.error(response.data.message || "Failed to update task.");
      }
    } catch (error) {
      console.error('Task edit error', error);
      toast.error("Failed to update task.");
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={handleModalToggle} size="lg">
      <ModalHeader toggle={handleModalToggle}>Edit Task</ModalHeader>
      <ModalBody>
        <Form className="form-bookmark" onSubmit={onSubmit}>
          <div className="form-row">
            <FormGroup className="col-md-12">
              <Label>Title</Label>
              <Input
                type="text"
                name="title"
                id="title"
                placeholder="Enter Title"
                value={formData.title}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup className="col-md-12">
              <Label>Description</Label>
              <Input
                name="description"
                type="textarea"
                rows="4"
                placeholder="Enter Description"
                value={formData.description}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup className="col-md-12">
              <Label>Criticality</Label>
              <Input
                name="criticality"
                type="select"
                value={formData.criticality}
                onChange={handleInputChange}
              >
                <option value="">Choose Option</option>
                <option value="critical">Critical</option>
                <option value="high">High</option>
                <option value="medium">Medium</option>
                <option value="low">Low</option>
              </Input>
            </FormGroup>
            <FormGroup className="col-md-12">
              <Label>Status</Label>
              <Input
                name="status"
                type="select"
                value={formData.status}
                onChange={handleInputChange}
              >
                <option value="">Choose Option</option>
                <option value="not-started">Not Started</option>
                <option value="In Process">In Process</option>
                <option value="on-hold">On Hold</option>
                <option value="complete">Complete</option>
              </Input>
            </FormGroup>
            <FormGroup className="col-md-12">
              <Label>Life Area</Label>
              <Input
                name="life_area"
                type="select"
                value={formData.life_area}
                onChange={handleInputChange}
              >
                <option value="general">Choose Option</option>
                <option value="physical-health">Physical Health</option>
                <option value="mental-health">Mental Health</option>
                <option value="romanitc-life">Romantic Life</option>
                <option value="personal-finance">Personal Finances</option>
                <option value="family">Family</option>
                <option value="social-life">Social Life</option>
                <option value="career">Career</option>
                <option value="business">Business</option>
                <option value="personal-mission">Personal Mission</option>
                <option value="personal-growth">Personal Growth</option>
                <option value="home-environment">Home Environment</option>
                <option value="fun-recreation">Fun & Recreation</option>
                <option value="passion-hobbies">Passion & Hobbies</option>
                <option value="travel">Travel</option>
                <option value="spiritual">Spiritual</option>
              </Input>
            </FormGroup>
            <FormGroup className="col-md-12">
              <Label>Due Date</Label>
              <Input
                type="date"
                name="due_date"
                value={formData.due_date}
                onChange={handleInputChange}
              />
            </FormGroup>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-primary me-1" onClick={handleModalToggle}>Cancel</button>
        <button className="btn btn-success" type="submit" onClick={onSubmit}>Save</button>
      </ModalFooter>
    </Modal>
  );
};

export default EditTaskModal;
