import React, { useEffect, useState } from 'react';
import { FaBook } from 'react-icons/fa';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from '../../../utils';
import { isToday } from 'date-fns';
import { ShoppingBag } from 'react-feather';

const DailyAffirmation = () => {
    // State to store affirmation 
    const [affirmationEntries, setAffirmationEntries] = useState([]);
    const uid = localStorage.getItem("user_id");

    // Function to fetch affirmation data
    const fetchData = async () => {
        try {
            console.log('Fetching data with UID:', uid);
            const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.getaffirmations}/${uid}`, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            console.log('API Response:', response.data);

            if (response.data.status === "success") {
                setAffirmationEntries(response.data.tasks);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch affirmation error:', error);
            toast.error("Failed to fetch affirmations.");
        }
    };

    // Fetch data when the component mounts
    useEffect(() => {
        if (uid) {
            fetchData();
        } else {
            toast.error("User ID not found.");
        }
    }, [uid]);

    // Filter affirmations for today and remove empty ones
    const todayAffirmations = affirmationEntries
        .flatMap(entry => {
            // Consider affirmations even if date is null
            const isValidDate = entry.date ? isToday(new Date(entry.date)) : true;
            return isValidDate ? (entry.affirmation || []).filter(aff => aff.trim() !== '') : [];
        });

    return (
        <>
            <div className="card height-equal">
                <div className="card-header card-header-border">
                    <div className="row">
                        <div className="col-sm-8">
                            <h5 className="without-btn"><FaBook /> My Daily Affirmations</h5>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="activity">
                        {todayAffirmations.length > 0 ? (
                            todayAffirmations.map((aff, index) => (
                                <div key={index} className="mb-3 d-flex">
                                    <div className="flex-shrink-0 gradient-round m-r-30 gradient-line-1">
                                        <ShoppingBag />
                                    </div>
                                    <div className="flex-grow-1">
                                        <h6><b>{aff}</b></h6>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div>No affirmations for today.</div>
                        )}
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default DailyAffirmation;
