import { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import API from '../../utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import MyLoader from '../../components/common/myloader';

const RenewalModal = ({ show, handleClose, fetchData }) => {
    const [isLoading, setIsLoading] = useState(false);  
    const uid = localStorage.getItem("user_id");
    const [cardDetails, setCardDetails] = useState({
        card_number: '',
        exp_month: '',
        exp_year: '',
        cvc: '',
    });
    const [validationErrors, setValidationErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCardDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value
        }));
        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const validate = () => {
        const errors = {};

        if (!cardDetails.card_number) {
            errors.card_number = 'Card Number is required';
        } else if (!/^\d{16}$/.test(cardDetails.card_number)) {
            errors.card_number = 'Card Number must be 16 digits';
        }

        if (!cardDetails.exp_month) {
            errors.exp_month = 'Expiration Month is required';
        } else if (!/^(0[1-9]|1[0-2])$/.test(cardDetails.exp_month)) {
            errors.exp_month = 'Expiration Month must be between 01 and 12';
        }

        if (!cardDetails.exp_year) {
            errors.exp_year = 'Expiration Year is required';
        } else if (!/^\d{4}$/.test(cardDetails.exp_year)) {
            errors.exp_year = 'Expiration Year must be a 4-digit year';
        }

        if (!cardDetails.cvc) {
            errors.cvc = 'CVC is required';
        } else if (!/^\d{3,4}$/.test(cardDetails.cvc)) {
            errors.cvc = 'CVC must be 3 or 4 digits';
        }

        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        if (validate()) {
            handleModalSubmit(cardDetails);
        }
    };

    const handleModalSubmit = async (cardDetails) => {
        setIsLoading(true);  
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('price', 100);
        formDataToSend.append('card_number', cardDetails.card_number);
        formDataToSend.append('exp_month', cardDetails.exp_month);
        formDataToSend.append('exp_year', cardDetails.exp_year);
        formDataToSend.append('cvc', cardDetails.cvc);

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.renew_subscription}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`
                },
            });

            if (response.data.status === "success") {
                localStorage.setItem("isSubscriptionValid", true);
                // toast.success(response.data.message);
                handleCloseAndReset();
                fetchData();
            } else {
                // toast.error(response.data.message);
            }
        } catch (error) {
            // toast.error("Subscription renewal failed.");
        }finally {
            setIsLoading(false);  // Hide loader
        }
    };

    const handleCloseAndReset = () => {
        // Reset form and validation states
        setCardDetails({
            card_number: '',
            exp_month: '',
            exp_year: '',
            cvc: '',
        });
        setValidationErrors({});
        setIsSubmitted(false);
        handleClose(); // Call the passed handleClose function
    };

    return (
        <>
        <MyLoader/>
        <Modal show={show} onHide={handleCloseAndReset}>
            <Modal.Header closeButton>
                <Modal.Title>Renew Your Subscription</Modal.Title>
            </Modal.Header>
            <Form onSubmit={onSubmit}>
                <Modal.Body>
                    <p>Your subscription has expired. Please renew it to continue using our services.</p>
                    <Form.Group className="mb-3" controlId="formCardNumber">
                        <Form.Label>Card Number</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Card Number"
                            name="card_number"
                            value={cardDetails.card_number}
                            onChange={handleChange}
                            isInvalid={isSubmitted && !!validationErrors.card_number}
                        />
                        {isSubmitted && validationErrors.card_number && (
                            <Form.Control.Feedback type="invalid">
                                {validationErrors.card_number}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <Form.Group className="mb-3" controlId="formExpMonth">
                                <Form.Label>Expiration Month</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="MM"
                                    name="exp_month"
                                    value={cardDetails.exp_month}
                                    onChange={handleChange}
                                    isInvalid={isSubmitted && !!validationErrors.exp_month}
                                />
                                {isSubmitted && validationErrors.exp_month && (
                                    <Form.Control.Feedback type="invalid">
                                        {validationErrors.exp_month}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <Form.Group className="mb-3" controlId="formExpYear">
                                <Form.Label>Expiration Year</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="YYYY"
                                    name="exp_year"
                                    value={cardDetails.exp_year}
                                    onChange={handleChange}
                                    isInvalid={isSubmitted && !!validationErrors.exp_year}
                                />
                                {isSubmitted && validationErrors.exp_year && (
                                    <Form.Control.Feedback type="invalid">
                                        {validationErrors.exp_year}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className="mb-3" controlId="formCVC">
                        <Form.Label>CVC</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="CVC"
                            name="cvc"
                            value={cardDetails.cvc}
                            onChange={handleChange}
                            isInvalid={isSubmitted && !!validationErrors.cvc}
                        />
                        {isSubmitted && validationErrors.cvc && (
                            <Form.Control.Feedback type="invalid">
                                {validationErrors.cvc}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button className='w-100' variant="primary" type="submit">
                        Pay
                    </Button>
                </Modal.Footer>
            </Form>

        </Modal >
        </>
    );
};

export default RenewalModal;
