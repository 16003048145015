import React, { useEffect, useState, useCallback } from "react";
import axios from 'axios';

import { debounce } from 'lodash';
import API from "../../../utils";

const Accomplishment = () => {
  const uid = localStorage.getItem('user_id') || '';
  const [fields, setFields] = useState([{ id: null, love: '', hate: '' }]); 

  const fetchPeopleData = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('user_id', uid);

      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_all_accomplishments}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        const peopleData = response.data.data;

        if (peopleData && peopleData.length > 0) {
          setFields(peopleData.map(person => ({
            id: person.id, 
            love: person.year_or_age || '',
            hate: person.your_life_to_date || '',
          })));
        } else {
          setFields([{ id: null, love: '', hate: '' }]);
        }
      } else {
      }
    } catch (error) {
      console.error('Fetch people data error', error);
    }
  };

  const savePeopleData = async (updatedFields) => {
    const formDataToSend = new FormData();
    updatedFields.forEach((field, index) => {
      formDataToSend.append(`year_or_age[${index + 1}]`, field.love);
      formDataToSend.append(`your_life_to_date[${index + 1}]`, field.hate);
      formDataToSend.append(`id[${index + 1}]`, field.id); 
    });

    formDataToSend.append('user_id', uid);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.insert_update_accomplishments}`,
        formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });

      if (response.data.status === "success") {
      } else {
      }
    } catch (error) {
      console.error('Save data error', error);
    }
  };

  // Debounced version of savePeopleData
  const debouncedSavePeopleData = useCallback(debounce(savePeopleData, 2000), []);

  const handleInputChange = (index, event) => {
    const values = [...fields];
    values[index][event.target.name] = event.target.value;
    setFields(values);

    // Call the debounced save function
    debouncedSavePeopleData(values);
  };

  const handleAddRow = () => {
    setFields([...fields, { id: null, love: '', hate: '' }]);
  };

  useEffect(() => {
    fetchPeopleData();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>Accomplishments</h5>
            </div>
            <div className="card-body m-3">
              <div className="textnots-container-valueandpurpose mb-3">
                <p className="textnots-text">
                  <b>What things are you most proud of in your life to date?</b>
                </p>
              </div>
              <div className="row m-2">
                <table className="table">
                  <thead>
                    <tr>
                      <th style={{width: "20%"}}>Year Or Age</th>
                      <th style={{width: "80%"}}>What things are you most proud of in your life to date?</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map((field, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="text"
                            name="love"
                            value={field.love}
                            onChange={event => handleInputChange(index, event)}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name="hate"
                            value={field.hate}
                            onChange={event => handleInputChange(index, event)}
                            className="form-control"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className='d-flex justify-content-end'>
                  <button className="btn btn-primary mx-2" onClick={handleAddRow}>
                    Add New Row
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accomplishment;
