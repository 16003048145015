import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ShoppingBag } from 'react-feather';
import { FaHistory, FaRegEdit } from 'react-icons/fa';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from '../../../utils';

const MyLifeTheme = () => {
    const uid = localStorage.getItem("user_id");
    const [validationErrors, setValidationErrors] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [lifeThemes, setLifeThemes] = useState([]);
  
    const [formData, setFormData] = useState({
        title: "",
        details: ""
    });

    const toggleModal = () => {
        if (showModal) {
            setFormData({
                title: "",
                details: ""
            });
            setValidationErrors({});
        }
        setShowModal(!showModal);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        const errorsCopy = { ...validationErrors };
        switch (name) {
          case "title":
            errorsCopy.title = value.trim() ? "" : "This field is required";
            break;
          case "details":
            errorsCopy.details = value.trim() ? "" : "This field is required";
            break;
          default:
            break;
        }
        setValidationErrors(errorsCopy);
    };

    const handleSubmit = async () => {
        // Validation
        const errors = {};
        if (!formData.title.trim()) {
            errors.title = "This field is required";
        }
        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            return; // Prevent API call
        }
    
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('title', formData.title);
        formDataToSend.append('details', formData.details);
    
        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.addLifeTheme}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            if (response.data.status === "success") {
                toast.success(response.data.message);
                setShowModal(false);
                fetchLifeThemes(); // Optionally refresh the life themes list
            } else {
                console.error("Failed to add life theme");
                toast.error("Failed to add life theme");
            }
        } catch (error) {
            console.error("Error:", error.message);
            toast.error("Error adding life theme");
        }
    };

    useEffect(() => {
        fetchLifeThemes();
    }, []);
    
    const fetchLifeThemes = async () => {
        try {
            const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.getLifeThemes}/${uid}`, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                }
            });
            if (response.data.status === "success") {
                setLifeThemes(response.data.life_theme);
            } else {
                console.error("Failed to fetch life themes");
            }
        } catch (error) {
            console.error("Error fetching life themes:", error.message);
            toast.error("Error fetching life themes");
        }
    };

    return (
        <>
            <div className="card">
                <div className="card-header life-theme-card-header d-flex justify-content-between align-items-center">
                    <h5 className="mt-2"><FaHistory /> My Life Theme for this Year </h5>
                    <FaRegEdit onClick={toggleModal} className="edit-icon-button" />
                </div>
                <div className="card-body">
                    <div className="activity">
                        {lifeThemes!= null && lifeThemes!=undefined && lifeThemes.length > 0 ? lifeThemes.map((theme, index) => (
                            <div className="mb-3 d-flex" key={index}>
                              {/* <div className="activite-label">{format(new Date(theme.created_at), 'yyyy-MM-dd')}</div> */}
                                <div className="flex-shrink-0 gradient-round m-r-30 gradient-line-1">
                                    <ShoppingBag />
                                </div>
                                <div className="flex-grow-1">
                                    <h6>{theme.title}</h6>
                                    {/* <p>{theme.details}</p> */}
                                </div>
                            </div>
                        )) : <p>No life themes available.</p>}
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Life Theme For This Year</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label>My Life Theme Title</label>
                        <textarea
                            className={`form-control ${validationErrors.title ? 'is-invalid' : ''}`}
                            value={formData.title}
                            rows="2"
                            name="title"
                            onChange={handleInputChange}
                        />
                        {validationErrors.title && <div className="text-danger">{validationErrors.title}</div>}
                    </div>
                    <div className="form-group mt-3">
                        <label>Theme Details</label>
                        <textarea
                            className={`form-control ${validationErrors.details ? 'is-invalid' : ''}`}
                            value={formData.details}
                            rows="4"
                            name="details"
                            onChange={handleInputChange}
                        />
                        {validationErrors.details && <div className="text-danger">{validationErrors.details}</div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={toggleModal}>Close</Button>
                    <Button variant="primary" onClick={handleSubmit}>Submit</Button>
                </Modal.Footer>
            </Modal>

            <ToastContainer />
        </>
    );
};

export default MyLifeTheme;
