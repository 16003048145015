import React, { Fragment, useState, useEffect } from 'react';
import man from '../../../assets/images/user/user_default.jpg'
import { Link, useNavigate } from 'react-router-dom';
import { Edit } from 'react-feather';
import { MARKS, GeneralManager } from '../../../constant'
import { toast } from 'react-toastify';
import API from '../../../utils';
import axios from 'axios';

const UserPanel = () => {
    const navigate=useNavigate();
    const user_id = localStorage.getItem("user_id");
    const profileURL = localStorage.getItem('profileURL');
    const name = localStorage.getItem('name');
    const user_name = localStorage.getItem('user_name');
    const [formData, setFormData] = useState({
        profile: null,
        profileURL: profileURL || man,
    });

    const fetchData = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append("user_id", user_id);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getuserdetails}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                const userData = response.data.user_data;
                setFormData({
                    profile: null,
                    profileURL: userData.profile || profileURL || man,
                });
            } else {
                toast.error(response.data.message);
                localStorage.removeItem("token");
                localStorage.removeItem("login");
                localStorage.removeItem("user_id");
                localStorage.removeItem("user_name");
                localStorage.removeItem("email");
                localStorage.removeItem("authenticated");
                localStorage.removeItem("auth0_profile");
                localStorage.removeItem("selectedCoreValues");
                localStorage.removeItem("selectedFiveCoreValues");
                localStorage.removeItem("profileURL");
                localStorage.removeItem("profile");
                localStorage.removeItem("formData");
                localStorage.removeItem("checked_items");
                localStorage.removeItem("lifeWheelFormData");
                localStorage.removeItem("loveLoatheFields");
                localStorage.removeItem("loveLoatheRemoveTopHated");
                localStorage.removeItem("comfortZoneFormData");
                localStorage.removeItem("googlelogin");
            
                navigate(`/login`);
            }
        } catch (error) {
            console.log("Failed to fetch user data");
        }
    };

    useEffect(() => {
        fetchData();
      }, [user_id]);
      
    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <div className=' d-flex justify-content-between'>
                    {/* <div className='col-lg-6 col-md-12 col-sm-12'> */}
                    <div className='img-user'>
                        {/* <img className="img-45 rounded-circle lazyloaded blur-up" src={profileURL ? profileURL : man} alt="#" /> */}
                        <img
                            className="img-45 rounded-circle lazyloaded blur-up"
                            alt=""
                            src={
                                formData.profile
                                    ? URL.createObjectURL(formData.profile)
                                    : formData.profileURL
                            }
                        />
                    </div>
                    <div className="profile-edit">
                        <Link to={'/users/userEdit'}>
                           
                        </Link>
                    </div>
                    <div >
                        <h6 className="m-2">{user_name}</h6>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default UserPanel;
