import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const EditModal = ({ isOpen, toggle, handleEditItem, editedItem, setEditedItem, editedItemError, setEditedItemError }) => {

  const handleInputChange = (e) => {
    setEditedItem(e.target.value);
    if (editedItemError) {
      setEditedItemError("");  // Clear the error when the user starts typing
    }
  };

  const handleSaveClick = () => {
    handleEditItem();
    if (!editedItemError) {
      setEditedItem("");  // Clear the input field
      setEditedItemError("");  // Clear any validation errors
    }
  };

  return (
    <Modal show={isOpen} onHide={toggle} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Those I Want To Serve</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="form-group">
            <label>Those I Want To Serve <span className="text-danger">*</span></label>
            <input
              className={`form-control ${editedItemError ? "is-invalid" : ""}`}
              type="text"
              value={editedItem}
              onChange={handleInputChange}
            />
            {editedItemError && <div className="invalid-feedback">{editedItemError}</div>}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={toggle}>Cancel</Button>
        <Button variant="primary" onClick={handleSaveClick}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditModal;
