import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { MdDelete } from 'react-icons/md';
import DataTable from 'react-data-table-component';
import { FaEye, FaRegEdit } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import API from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";

const MyLifeline = () => {
    const uid = localStorage.getItem('user_id');
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [editedItem, setEditedItem] = useState({
        age: "",
        moments: "",
        impact: ""
    });
    const [searchQuery, setSearchQuery] = useState("");
    const [gratitudeEntries, setGratitudeEntries] = useState([]);

    const fetchData = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);

            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.getlifeline_milestones}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                setGratitudeEntries(response.data.data);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch gratitude error', error);
            toast.error("Failed to fetch gratitudes.");
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const togglePopup = () => {

        setEditedItem({
            age: "",
            moments: "",
            impact: ""
        });

        setMomentsError("");
        setMomentsBorderError(false);

        setTitleError("");
        setTitleBorderError(false);

        setCoreError("");
        setCoreBorderError(false);
        
        setIsPopupOpen(!isPopupOpen);
    };

    const toggleDeleteModal = () => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
    };

    const toggleEditModal = () => {
        setIsEditModalOpen(!isEditModalOpen);
    };

    const handleDelete = (item) => {
        setSelectedItem(item);
        toggleDeleteModal();
    };

    const handleEdit = (item) => {
        setSelectedItem(item);
        setEditedItem({
            age: item.age,
            moments: item.moments,
            impact: item.impact
        });
        toggleEditModal();
    };

    const handleDeleteItem = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('id', selectedItem.id);
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.deletelifelinemilestone}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === "success") {
                // toast.success(response.data.message);
                toggleDeleteModal();
                fetchData();
            } else {
                // toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Failed to delete my reality.");
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredEntries = gratitudeEntries?.filter(entry =>
        entry.age.toLowerCase().includes(searchQuery.toLowerCase()) ||
        entry.moments.toLowerCase().includes(searchQuery.toLowerCase()) ||
        entry.impact.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const [titleBorderError, setTitleBorderError] = useState(false);
    const [titleError, setTitleError] = useState("");

    const [momentsBorderError, setMomentsBorderError] = useState(false);
    const [momentsError, setMomentsError] = useState("");

    const [coreBorderError, setCoreBorderError] = useState(false);
    const [coreError, setCoreError] = useState("");

    const handleAgeChange = (e) => {
        const value = e.target.value;
        setEditedItem(prevState => ({
            ...prevState,
            age: value
        }));

        if (!value) {
            setTitleError("This Field Required");
            setTitleBorderError(true);
        } else {
            setTitleError("");
            setTitleBorderError(false);
        }
    };

    const handleMomentsChange = (e) => {
        const value = e.target.value;
        setEditedItem(prevState => ({
            ...prevState,
            moments: value
        }));

        if (!value) {
            setMomentsError("This Field Required");
            setMomentsBorderError(true);
        } else {
            setMomentsError("");
            setMomentsBorderError(false);
        }
    };

    const handleImpactChange = (e) => {
        const value = e.target.value;
        setEditedItem(prevState => ({
            ...prevState,
            impact: value
        }));

        if (!value || isNaN(value) || value < -10 || value > 10) {
            setCoreError("This Field Requires a number between -10 and 10");
            setCoreBorderError(true);
        } else {
            setCoreError("");
            setCoreBorderError(false);
        }
    };

    const handleAdd = async () => {
        if (!editedItem.age || !editedItem.moments || !editedItem.impact) {
            setTitleError(!editedItem.age ? "This Field Required" : "");
            setMomentsError(!editedItem.moments ? "This Field Required" : "");
            setCoreError(!editedItem.impact ? "This Field Required" : "");

            setTitleBorderError(!editedItem.age);
            setMomentsBorderError(!editedItem.moments);
            setCoreBorderError(!editedItem.impact);

            return;
        }

        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('age', editedItem.age);
        formDataToSend.append('moments', editedItem.moments);
        formDataToSend.append('impact', editedItem.impact);

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.addlifelinemilestone}`,
                formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                // toast.success(response.data.message);
                fetchData();
                togglePopup();
            } else {
                // toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error);
        }
    };

    const handleEditItem = async () => {
        if (!editedItem.age || !editedItem.moments || !editedItem.impact) {
            setTitleError(!editedItem.age ? "This Field Required" : "");
            setMomentsError(!editedItem.moments ? "This Field Required" : "");
            setCoreError(!editedItem.impact ? "This Field Required" : "");

            setTitleBorderError(!editedItem.age);
            setMomentsBorderError(!editedItem.moments);
            setCoreBorderError(!editedItem.impact);

            return;
        }

        const formDataToSend = new FormData();
        formDataToSend.append('id', selectedItem.id);
        formDataToSend.append('age', editedItem.age);
        formDataToSend.append('moments', editedItem.moments);
        formDataToSend.append('impact', editedItem.impact);

        try {
            const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.updatelifelinemilestone}`, formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                // toast.success(response.data.message);
                fetchData();
                toggleEditModal();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error);
        }
    };

    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const handleView = (item) => {
        setSelectedItem(item);
        toggleViewModal();
    };
    const toggleViewModal = () => {
        setIsViewModalOpen(!isViewModalOpen);
    };

    const thStyle = {
        border: "1px solid gray",
        padding: "8px",
        textAlign: "left",
        background: "black",
        color: "white"
    };

    const columns = [
        {
            name: 'Age',
            selector: (row) => row.age,
            sortable: true,
        },
        {
            name: 'Defining Moments',
            selector: (row) => row.moments,
            sortable: true,
        },
        {
            name: 'Impact -10 to 10',
            selector: (row) => row.impact,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row) => (
                <>
                    <Button className="btn-secondary btn-pill main-edit-view-buttons mx-1" onClick={() => handleView(row)} ><FaEye /></Button>
                    <Button className="btn-secondary btn-pill main-edit-view-buttons mx-1" onClick={() => handleEdit(row)}><FaRegEdit /></Button>
                    <Button variant="danger" className="btn-primary btn-pill main-delete-buttons mx-1" onClick={() => handleDelete(row)}><MdDelete /></Button>
                </>
            ),
        },
    ];

    return (
        <div>
            <div className="row ">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header ">
                            <div className="row">
                                <div className=" align-items-center justify-content-between d-flex">
                                    <h5>My Lifeline</h5>
                                    <Button className="btn-success main-buttons" onClick={togglePopup}>Add Lifeline </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="card-body ">
                        <div className="row">
                            <div className="col-md-9"></div>
                            <div className="col-md-3">
                                <div className="form-group text-end mb-3 ">
                                    <input className="form-control" type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
                                </div>
                            </div>
                        </div>
                        <DataTable
                            columns={columns}
                            data={filteredEntries}
                            pagination
                            className="text-center"
                            persistTableHead={true}
                        />
                    </div>
                </div>
            </div>

            <Modal show={isPopupOpen} onHide={togglePopup}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Milestones</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="form theme-form">
                        <div className="card-body">
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="">
                                        <div className="form-group mb-2 mx-1">
                                            <label>Age:</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={editedItem.age}
                                                onChange={handleAgeChange}
                                                style={{ borderColor: titleBorderError ? 'red' : '' }}
                                            />
                                            {titleError && <p style={{ color: 'red' }}>{titleError}</p>}
                                        </div>


                                        <div className="form-group mb-2 mx-1">
                                            <label>Defining Moments:</label>
                                            <textarea
                                                className="form-control"
                                                value={editedItem.moments}
                                                onChange={handleMomentsChange}
                                                style={{ borderColor: momentsBorderError ? 'red' : '' }}
                                            ></textarea>
                                            {momentsError && <p style={{ color: 'red' }}>{momentsError}</p>}
                                        </div>

                                        <div className="form-group mb-2 mx-1">
                                            <label>Impact:</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                value={editedItem.impact}
                                                onChange={handleImpactChange}
                                                style={{ borderColor: coreBorderError ? 'red' : '' }}
                                            />
                                            {coreError && <p style={{ color: 'red' }}>{coreError}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={togglePopup}>Close</Button>
                    <Button variant="primary" onClick={handleAdd}>Save</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isDeleteModalOpen} onHide={toggleDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Milestones</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={toggleDeleteModal}>Close</Button>
                    <Button variant="primary" onClick={handleDeleteItem}>Delete</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isEditModalOpen} onHide={toggleEditModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Milestones</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="form theme-form">
                        <div className="card-body">
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="">
                                        <div className="form-group mb-2 mx-1">
                                            <label>Age:</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={editedItem.age}
                                                onChange={handleAgeChange}
                                                style={{ borderColor: titleBorderError ? 'red' : '' }}
                                            />
                                            {titleError && <p style={{ color: 'red' }}>{titleError}</p>}
                                        </div>


                                        <div className="form-group mb-2 mx-1">
                                            <label>Defining Moments:</label>
                                            <textarea
                                                className="form-control"
                                                value={editedItem.moments}
                                                onChange={handleMomentsChange}
                                                style={{ borderColor: momentsBorderError ? 'red' : '' }}
                                            ></textarea>
                                            {momentsError && <p style={{ color: 'red' }}>{momentsError}</p>}
                                        </div>

                                        <div className="form-group mb-2 mx-1">
                                            <label>Impact:</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                value={editedItem.impact}
                                                onChange={handleImpactChange}
                                                style={{ borderColor: coreBorderError ? 'red' : '' }}
                                            />
                                            {coreError && <p style={{ color: 'red' }}>{coreError}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={toggleEditModal}>Close</Button>
                    <Button variant="primary" onClick={handleEditItem}>Save</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isViewModalOpen} onHide={toggleViewModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Lifeline / Milestones</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <table className="table">

                        <thead>


                        </thead>
                        <tbody>
                            <tr>
                                <th style={thStyle} colSpan={2}>Lifeline / Milestones</th>
                            </tr>
                            <tr>
                                <td>Age</td>
                                <td>{selectedItem?.age}</td>
                            </tr>
                            <tr>
                                <td>Defining Moments</td>
                                <td>{selectedItem?.moments}</td>
                            </tr>
                            <tr>
                                <td>Impact</td>
                                <td>{selectedItem?.impact}</td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={toggleViewModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default MyLifeline;
