import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../../utils';
import axios from 'axios';
import { debounce } from 'lodash';

const DevelopYourStory = () => {
    const uid = localStorage.getItem('user_id') || '';
    const [validated, setValidated] = useState(false);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({});
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        fetchQuestions();

        const clearErrors = () => setErrors({});
        window.addEventListener('clearValidationErrors', clearErrors);

        return () => {
            window.removeEventListener('clearValidationErrors', clearErrors);
        };
    }, []);

    const fetchQuestions = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('main_category_id', '9');
        formDataToSend.append('sub_category_id', '22');
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getLifeQuestion}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );
            if (response.data.status === 'success') {
                setQuestions(response.data.question || []);
                const initialFormData = {};
                (response.data.question || []).forEach((question) => {
                    initialFormData[question.id] = question.answer || '';
                });
                setFormData(initialFormData);
            } else {
                toast.error(response.data.message || 'Failed to fetch questions');
            }
        } catch (error) {
            console.error('Error fetching questions:', error);
            toast.error('Failed to fetch questions. Please try again later.');
        }
    };

    const handleDevelopYourStory = (e) => {
        e.preventDefault();

        const validationErrors = {};
        questions.forEach((question) => {
            if ((formData[question.id] || '').trim() === '') {
                validationErrors[question.id] = 'This field is required';
            }
        });

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        } else {
            setValidated(true);
            handleSubmit();
        }
    };


    const handleSubmit = async () => {
        const formDataToSend = new FormData();
        questions.forEach((question, index) => {
            formDataToSend.append(`question_id[${index + 1}]`, question.id);
            formDataToSend.append(`answer[${index + 1}]`, formData[question.id] || '');
        });

        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.updateAnswer}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                fetchQuestions();
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message || 'Failed to update answers');
            }
        } catch (error) {
            console.error('Error updating answers:', error);
            toast.error('Failed to update answers. Please try again later.');
        }
    };
    const debouncedUpdateAnswer = useCallback(
        debounce(async (questionId, answer) => {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('question_id[${index + 1}]', questionId);
            formDataToSend.append('answer[${index + 1}]', answer);

            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.updateAnswer}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                if (response.data.status === 'success') {
                    //  toast.success(response.data.message);
                } else {
                    toast.error(response.data.message || 'Failed to update answer');
                }
            } catch (error) {
                console.error('Error updating answer:', error);
                toast.error('Failed to update answer. Please try again later.');
            }
        }, 2000),
        [uid]
    );

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        debouncedUpdateAnswer(name, value);
    };
    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <h5>Build : Develop Your Story</h5>
                    </div>

                    <form className="form theme-form" noValidate validated={validated} onSubmit={handleDevelopYourStory}>
                        <div className="card-body m-3">
                            <div className="textnots-container-persomality mb-3">
                                <p className="textnots-text">
                                    In this section, we prepare you to tell your story. Part of this preparation is identifying the evidence thatsupports your purpose and story.  What proofs do you have of your competencies?  Developing proofsand then learning to present them well are the keys to gaining recognition for your strengths.  Theseproofs will help you develop a professional portfolio. Your professional portfolio should bring your skillsand interests to life, demonstrate your credibility and attract attention from your target audience.  Theelements of this section include documenting your skills inventory, updating your professional bio, andmastering storytelling.
                                </p>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    {questions.map((question) => (
                                        <div className="form-group row mb-2 mx-1" key={question.id}>
                                            <label className="col-sm-12 col-form-label  mb-2 p-0"><b>{question.question}</b></label>
                                            <textarea
                                                className={`form-control ${errors[question.id] ? 'is-invalid' : ''}`}
                                                name={question.id}
                                                value={formData[question.id] || ''}
                                                onChange={handleChange}
                                                rows="4"
                                            ></textarea>
                                            {errors[question.id] && <div className="text-danger">{errors[question.id]}</div>}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                       
                    </form>
                </div>
            </div>
        </div>
    );
};

export default DevelopYourStory;
