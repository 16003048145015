import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../../utils';
import axios from 'axios';
import { Button, Container, Modal, ProgressBar, Tab, Tabs } from "react-bootstrap";
import { debounce } from 'lodash';

const DynamicStep = ({ questions, formData, handleChange }) => (
    <>
        <div className='row'>
            {questions.map((question, index) => (
                <div key={index} className="col-md-6 col-lg-6 col-sm-12">
                    <div className="form-group mb-2">
                        <label><b>{question.question}</b></label>
                        <textarea
                            className="form-control"
                            name={question.id}
                            value={formData[question.id]}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            ))}
        </div>
    </>
);

const Personality = () => {
    const [questions, setQuestions] = useState([]);
    const uid = localStorage.getItem('user_id') || '';
    const [formData, setFormData] = useState({});

    const handleSubmitsPersonality = (e) => {
        e.preventDefault();
        handleSubmit();
    };

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         [name]: value
    //     }));
    // };

    useEffect(() => {
        fetchQuestions();
    }, []);

    const fetchQuestions = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('main_category_id', "7");
        formDataToSend.append('sub_category_id', "13");
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getLifeQuestion}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    },
                }
            );
            if (response.data.status === "success") {
                setQuestions(response.data.question || []);
                const initialFormData = {};
                (response.data.question || []).forEach((question) => {
                    initialFormData[question.id] = question.answer || '';
                });
                setFormData(initialFormData);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSubmit = async () => {
        const formDataToSend = new FormData();
        questions.forEach((question, index) => {
            formDataToSend.append(`question_id[${index + 1}]`, question.id);
            formDataToSend.append(`answer[${index + 1}]`, formData[question.id] || '');
        });

        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.updateAnswer}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                fetchQuestions();
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message || 'Failed to update answers');
            }
        } catch (error) {
            console.error('Error updating answers:', error);
            toast.error('Failed to update answers. Please try again later.');
        }
    };
    const debouncedUpdateAnswer = useCallback(
        debounce(async (questionId, answer) => {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('question_id[${index + 1}]', questionId);
            formDataToSend.append('answer[${index + 1}]', answer);

            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.updateAnswer}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                if (response.data.status === 'success') {
                    //  toast.success(response.data.message);
                } else {
                    toast.error(response.data.message || 'Failed to update answer');
                }
            } catch (error) {
                console.error('Error updating answer:', error);
                toast.error('Failed to update answer. Please try again later.');
            }
        }, 2000),
        [uid]
    );

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        // Trigger the debounced API call
        debouncedUpdateAnswer(name, value);
    };
    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <h5>16 Personalities </h5>
                    </div>
                    <form onSubmit={handleSubmitsPersonality}>
                        <div className="card-body m-3">
                            <div className="textnots-container-personality mb-3">
                                <strong>Please go to <a target="_blank" href='https://www.16personalities.com'>https://www.16personalities.com</a> and take the free personality test. Enter your results below.</strong>
                            </div>
                            <DynamicStep questions={questions} formData={formData} handleChange={handleChange} />
                        </div>
                        {/* <div className="card-footer text-end">
                            <button type="submit" className="btn btn-success mx-1">Save</button>
                        </div> */}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Personality;
