import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FaTasks } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import axios from 'axios';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import "react-toastify/dist/ReactToastify.css";
import API from '../../../utils';
import { Dropdown, DropdownButton } from 'react-bootstrap';

const TrackLifeGoals = () => {
  const uid = localStorage.getItem("user_id");
  const [tasks, setTasks] = useState([]);
  const [checkedTasks, setCheckedTasks] = useState({});

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {


    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.get_outcome_for_deshboard}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`
          },
        }
      );

      const data = response.data;
      if (data.status === "success") {
        setTasks(data.outcomes || []);
        const initialCheckedTasks = data.outcomes.reduce((acc, task) => {
          acc[task.id] = false;
          return acc;
        }, {});
        setCheckedTasks(initialCheckedTasks);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      // toast.error("Failed to fetch questions.");
    }
  
  };

 



  const handleCheckboxChange = async (taskId) => {
    const updatedCheckedTasks = {
      ...checkedTasks,
      [taskId]: !checkedTasks[taskId]
    };
    setCheckedTasks(updatedCheckedTasks);
    const formDataToSend = new FormData();
    formDataToSend.append('outcomes_id', taskId);
    formDataToSend.append('status', checkedTasks ? 1 : 0);
    try {
      const response = await axios.post(
          `${API.BASE_URL}${API.ENDPOINTS.update_check_status_outcomes}`,
           formDataToSend,
          {
              headers: {
                  Authorization: `${API.AUTHORIZATION_TOKEN}`,
              },
          }
      );
      if (response.data.status === 'success') {
        // toast.success(response.data.message)
        fetchTasks();
      } else {
          toast.error(response.data.message || 'Failed to fetch tasks');
      }
  } catch (error) {
      console.error('Error fetching tasks:', error);
      toast.error('Failed to fetch tasks. Please try again later.');
  }

   
    
    }

  return (
    <>
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5 className="without-btn"><FaTasks /> Tracking Against this Year’s Goals</h5>
    
        </div>
        <div className="card-body ">
          <div className="activity">
            {tasks && tasks.length > 0 ? (
              tasks.slice(0, 16).map((task, index) => (
                <div className="d-flex" key={index}>

                  <div className='mb-3'>
                    <input
                      type="checkbox"
                      className="checkbox_animated"
                      checked={!!checkedTasks[task.id]}
                      onChange={() => handleCheckboxChange(task.id)}
                    />

                  </div>
                  {/* <div className="activite-label">{format(new Date(task.update_at), 'yyyy-MM-dd')}</div> */}
                  <div className="flex-shrink-0 gradient-round NewMessage m-r-30 gradient-line-1"></div>
                  <div className="flex-grow-1 task-titles d-flex justify-content-between">

                    <h6 className="ml-2">{task.reason}</h6>
                  
                  </div>
                </div>
              ))
            ) : (
              <p>No Goals available.</p>
            )}
          </div>
        </div>
      </div>

    
    </>
  );
};

export default TrackLifeGoals;
