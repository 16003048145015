import React, { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import API from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import { debounce } from "lodash";


const EarlyExperiencesWorksheet = () => {
  const uid = localStorage.getItem('user_id') || '';
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({});
  const [questions, setQuestions] = useState([]);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    fetchQuestions();
  }, []);
  useEffect(() => {
    fetchQuestions();
    const clearErrors = () => setErrors({});
    window.addEventListener('clearValidationErrors', clearErrors);

    return () => {
      window.removeEventListener('clearValidationErrors', clearErrors);
    };
  }, []);

  const fetchQuestions = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    formDataToSend.append('main_category_id', '2');
    formDataToSend.append('sub_category_id', '5');

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.getquestionbymainandsub}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );
      if (response.data.status === 'success') {
        setQuestions(response.data.question);
        const initialFormData = {};
        (response.data.question || []).forEach((question) => {
          initialFormData[question.id] = question.answer || '';
        });
        setFormData(initialFormData);
      } else {
        toast.error(response.data.message || 'Failed to fetch questions');
      }
    } catch (error) {
      console.error('Error fetching questions:', error);
      toast.error('Failed to fetch questions. Please try again later.');
    }
  };

  const handleSubmitwork = (e) => {

    e.preventDefault();

    const validationErrors = {};
    questions.forEach((question) => {
      if ((formData[question.id] || '').trim() === '') {
        validationErrors[question.id] = 'This field is required';
      }
    });

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setValidated(true);
    handleSubmit();
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setErrors((prevErrors) => ({
  //     ...prevErrors,
  //     [name]: '',
  //   }));
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  const handleSubmit = async () => {
    const formDataToSend = new FormData();
    questions.forEach((question, index) => {
      formDataToSend.append(`question_id[${index + 1}]`, question.id);
      formDataToSend.append(`answer[${index + 1}]`, formData[question.id] || '');
    });

    formDataToSend.append('user_id', uid);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.addupdateanswermybelief}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === 'success') {
        fetchQuestions();
        // toast.success(response.data.message);
      } else {
        // toast.error(response.data.message || 'Failed to update answers');
      }
    } catch (error) {
      console.error('Error updating answers:', error);
      toast.error('Failed to update answers. Please try again later.');
    }
  };

  const debouncedUpdateAnswer = useCallback(
    debounce(async (questionId, answer) => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('question_id[${index + 1}]', questionId);
        formDataToSend.append('answer[${index + 1}]', answer);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.addupdateanswermybelief}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                //  toast.success(response.data.message);
            } else {
                toast.error(response.data.message || 'Failed to update answer');
            }
        } catch (error) {
            console.error('Error updating answer:', error);
            toast.error('Failed to update answer. Please try again later.');
        }
    }, 2000),
    [uid]
);

const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
        ...prevData,
        [name]: value,
    }));

    // Trigger the debounced API call
    debouncedUpdateAnswer(name, value);
};


  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>Origins of Core Beliefs </h5>
            </div>
            <form className="form theme-form" >

              <div className="card-body m-3">
                <div className="textnots-container-persomality mb-3">
                  <p className="textnots-text">
                    Although you may be able to accurately attribute certain core beliefs you hold to events and/or the actions of people from your past, this is not an exercise in assigning blame.  If you blame your past or your parents for your current problems as an adult, you’re likely to stop yourself from moving on and getting better.  Past aspects of your life may have contributed to the unhelpful ways you think and act in the present.  However, you are the one who now has the choice to revamp your ideas and live in a new and healthier way.  Don’t let yourself get sucked into the quicksand of your past.  Instead, review your past with the purpose of better understanding and improving your life.
                  </p>
                  <p className="textnots-text">
                    Defining Your Core Beliefs
                  </p>
                  <p className="textnots-text">Use the questions below to help you understand which significant people in your life have contributed to your core beliefs.  Also, by recording recurring experiences in your life (or themes) can help you recognize how specific events may have further contributed to the core beliefs you hold.</p>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    {questions.map((question) => (
                      <div className="form-group row mb-2" key={question.id}>
                        <label className="col-sm-12 col-form-label  mb-2 p-0"><b>{question.question}</b></label>
                        <textarea
                          className={`form-control ${errors[question.id] ? 'is-invalid' : ''}`}
                          name={question.id}
                          value={formData[question.id] || ''}
                          onChange={handleChange}
                          rows="4"
                        ></textarea>
                        {errors[question.id] && <div className="text-danger">{errors[question.id]}</div>}
                      </div>
                    ))}


                  </div>
                </div>
              </div>

              {/* <div className="card-footer ">
                <button type="submit" className="btn btn-success main-buttons  float-end">
                  Save
                </button>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );

}

export default EarlyExperiencesWorksheet;
