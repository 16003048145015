import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../../utils';
import axios from 'axios';
import { debounce } from 'lodash';
import Motivatorss from '../../../assets/images/testresults/Motivators.png';
import Score from '../../../assets/images/testresults/Score.png';
import Mbti from '../../../assets/images/testresults/Mbti.png';
const Motivators = ({ activeTab }) => {
    const uid = localStorage.getItem('user_id') || '';

    const [formData, setFormData] = useState({});
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        fetchQuestions();
    }, []);

    useEffect(() => {
        setFormData({});
    }, [activeTab]);

    const fetchQuestions = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('main_category_id', '7');
        formDataToSend.append('sub_category_id', '12');
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getLifeQuestion}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );
            if (response.data.status === 'success') {
                setQuestions(response.data.question || []);
                const initialFormData = {};
                (response.data.question || []).forEach((question) => {
                    initialFormData[question.id] = question.answer || '';
                });
                setFormData(initialFormData);
            } else {
                toast.error(response.data.message || 'Failed to fetch questions');
            }
        } catch (error) {
            console.error('Error fetching questions:', error);
            toast.error('Failed to fetch questions. Please try again later.');
        }
    };

    const handleSubmitmotivators = (e) => {
        e.preventDefault();
        handleSubmit();
    };

    const handleSubmit = async () => {
        const formDataToSend = new FormData();
        questions.forEach((question, index) => {
            formDataToSend.append(`question_id[${index + 1}]`, question.id);
            formDataToSend.append(`answer[${index + 1}]`, formData[question.id] || '');
        });

        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.updateAnswer}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                fetchQuestions();
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message || 'Failed to update answers');
            }
        } catch (error) {
            console.error('Error updating answers:', error);
            toast.error('Failed to update answers. Please try again later.');
        }
    };

    const debouncedUpdateAnswer = useCallback(
        debounce(async (questionId, answer) => {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append(`question_id[${questionId}]`, questionId);
            formDataToSend.append(`answer[${questionId}]`, answer);

            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.updateAnswer}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                if (response.data.status === 'success') {
                    // toast.success(response.data.message);
                } else {
                    toast.error(response.data.message || 'Failed to update answer');
                }
            } catch (error) {
                console.error('Error updating answer:', error);
                toast.error('Failed to update answer. Please try again later.');
            }
        }, 2000),
        [uid]
    );

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        debouncedUpdateAnswer(name, value);
    };

    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <h5>Myer Briggs</h5>
                    </div>
                    <form className="form theme-form" noValidate onSubmit={handleSubmitmotivators}>
                        <div className="card-body m-3">
                        <div className="textnots-container mb-3">
                                <strong>Please Myer Briggs Personality test go to: <a target="_blank" href='https://www.truity.com/test/type-finder-personality-test-new'>https://www.truity.com/test/type-finder-personality-test-new</a></strong>
                                <strong>What’s your personality type from below:</strong>
                                <strong>MBTI – Myer Briggs Type Indicator</strong>
                                <p className="textnots-text">
                                    The Myers–Briggs Type Indicator (MBTI) is a personality assessment model that is based on the conceptual theory proposed by Swiss psychiatrist Carl Jung.
                                </p>
                                <div className='d-flex justify-content-center my-3'>
                                    <img className='w-50 h-50' src={Motivatorss} alt="Motivators" />
                                </div>
                                <p className="textnots-text">
                                    How do you organize your life? To-do lists and instructions (J) or flexibility and improvisation (P).
                                </p>
                                <div className='d-flex justify-content-center my-3'>
                                    <img className='w-50 h-50' src={Score} alt="Score" />
                                </div>
                                <strong>What is your MBTI:</strong>
                                <div className='d-flex justify-content-center my-3'>
                                    <img className='w-50 h-50' src={Mbti} alt="MBTI" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 my-4">
                                    <h5>Individual Traits</h5>
                                </div>
                            </div>

                            <div className="row">
                                {questions.map((question) => (
                                    <div className="col-lg-3 col-md-3 col-sm-4" key={question.id}>
                                        <div className="form-group mb-2">
                                            <label className="col-form-label mb-2 p-0"><b>{question.question}</b></label>
                                            <div className="d-flex align-items-center">
                                                <input  
                                                    type="number"
                                                    className="form-control text-center"
                                                    name={question.id}
                                                    value={formData[question.id] || ''}
                                                    onChange={handleChange}
                                                    style={{ width: '80px' }} 
                                                />
                                                <span className="mx-1 percentage">%</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Motivators;
