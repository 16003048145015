import React, { useState, useEffect, useCallback } from "react";
import Breadcrumb from "../common/breadcrumb";
import { roleModel } from "../../constant";
import { Button } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import API, { isSubscribedAccountant } from '../../utils.js';
import { useNavigate } from 'react-router-dom';
import Visionbord from '../../assets/images/visionbord/Visionbord.png';
import { debounce } from "lodash";

const VisionBoard = () => {
  const navigate = useNavigate()
  const uid = localStorage.getItem('user_id');
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({});
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    // fetchget();
    fetchQuestions();
  }, []);
  useEffect(() => {
    if (!isSubscribedAccountant()) {
        navigate("/users/userEdit");
        window.location.reload();
      }
    
    }, []);
  // const fetchget = async () => {
  //   const formDataToSend = new FormData();
  //   formDataToSend.append('main_category', 'My Vision Board');
  //   formDataToSend.append('user_id', uid);

  //   try {
  //     const response = await axios.post(
  //       `${API.BASE_URL}${API.ENDPOINTS.futureSUb}`,
  //       formDataToSend,
  //       {
  //         headers: {
  //           Authorization: `${API.AUTHORIZATION_TOKEN}`,
  //         },
  //       }
  //     );
  //     if (response.data.status === 'success') {
  //       setQuestions(response.data.question || []);
  //       const initialFormData = {};
  //       (response.data.question || []).forEach((question) => {
  //         initialFormData[question.id] = question.answer || '';
  //       });
  //       setFormData(initialFormData);
  //     } else {
  //       toast.error(response.data.message || 'Failed to fetch questions');
  //     }
  //   } catch (error) {
  //     console.error('Error fetching questions:', error);
  //     toast.error('Failed to fetch questions. Please try again later.');
  //   }
  // };

  const fetchQuestions = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    formDataToSend.append('main_category_id', '8');
    formDataToSend.append('sub_category_id', '0');

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.getQuestions}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );
      if (response.data.status === 'success') {
        setQuestions(response.data.question);
        const initialFormData = {};
        (response.data.question || []).forEach((question) => {
          initialFormData[question.id] = question.answer || '';
        });
        setFormData(initialFormData);
      } else {
        toast.error(response.data.message || 'Failed to fetch questions');
      }
    } catch (error) {
      console.error('Error fetching questions:', error);
      toast.error('Failed to fetch questions. Please try again later.');
    }
  };


  const debouncedUpdateAnswer = useCallback(
    debounce(async (questionId, answer) => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('question_id[]', questionId);
        formDataToSend.append('answer[]', answer);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.addAnswer}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                //  toast.success(response.data.message);
            } else {
                toast.error(response.data.message || 'Failed to update answer');
            }
        } catch (error) {
            console.error('Error updating answer:', error);
            toast.error('Failed to update answer. Please try again later.');
        }
    }, 2000),
    [uid]
);

const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
        ...prevData,
        [name]: value,
    }));

    // Trigger the debounced API call
    debouncedUpdateAnswer(name, value);
};


  const handleSubmit = async (e) => {


    const formDataToSend = new FormData();
    questions.forEach((question, index) => {
      formDataToSend.append(`question_id[]`, question.id);
      formDataToSend.append(`answer[]`, formData[question.id] || '');
    });

    formDataToSend.append('user_id', uid);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.addAnswer}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === 'success') {
        // toast.success(response.data.message);
        fetchQuestions();
      } else {
        // toast.error(response.data.message || 'Failed to update answers');
      }
    } catch (error) {
      console.error('Error updating answers:', error);
      toast.error('Failed to update answers. Please try again later.');
    }
  };
  const handleSubmitPurpose = (e) => {
    e.preventDefault();

    const validationErrors = {};
    questions.forEach((question) => {
      if ((formData[question.id] || '').trim() === '') {
        validationErrors[question.id] = 'This field is required';
      }
    });

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    } else {
      setValidated(true);
      handleSubmit();
    }
  };
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card mt-4">
              <div className="card-header">
                <h5>My Vision Board</h5>
              </div>
              <form className="form theme-form" validated={validated.toString()} onSubmit={handleSubmitPurpose}>
                <div className="card-body">
                  <div className="textnots-container mb-3">
                    <div className='d-flex justify-content-center my-3'>
                      <img className='w-50 h-50' src={Visionbord}></img>
                    </div>  
                    <p className="textnots-text">
                      Poster/Cork board, Magazines, Additional images printed from the internet, Scissors, Glue Sticks/Thumbtacks, Paper, Pen, Art supplies (optional), such as Sharpies, paint, glitter, or sequins
                    </p>

                    <strong>Set goals and prioritize</strong>
                    <p className="textnots-text">
                      This initial step is your big brain dump and includes everything from the big goals and dreams (write a book, learn how to meditate, take the family on vacation), to the little wish list type of stuff (get a new pair of J’s), intentions (be more mindful, connect with my family better), and words for the year (clarity, happiness, peace).
                    </p>
                    <p className="textnots-text">
                      After the first brain dump, review your thoughts and notes and figure out what your priorities are and either circle them or create a new list.
                    </p>
                    <p className="textnots-text">
                      What are the things you really, really want to happen above all else?  Those are definitely going to be included on you vision board.  A lot of the other things will end up on your board, too, but make sure the biggies get their place.
                    </p>
                    <p className="textnots-text">
                      After the first brain dump, review your thoughts and notes and figure out what your priorities are and either circle them or create a new list.
                    </p>
                    <p className="textnots-text">
                      Now it’s time to search for and cut out images and words that embody your goals and/or just speak to you.
                      Go through a stack of magazines (generally from your home already or bought for a quarter each from Goodwill), or print the images from the Internet.  Then I clip everything you like—colors, words, interesting images.  But, also be on a hunt for images and words that relate to my goals.
                    </p>
                    <p className="textnots-text">
                      You will cut out way more than you need or will ultimately use!
                      You will often find so many images, that you will need to take some time to sort through and filter my stack.  Ultimately, you are looking for one or more images that capture the essence and feeling of each goal.
                    </p>
                    <p className="textnots-text">
                      <strong>Create a Basic Structure for the Vision Board</strong>
                    </p>

                    <p className="textnots-text">
                      This step is optional, but I often do it and really like it.
                      Sometimes I divide the board into sections (for each of my life areas) and sometimes I draw radiating lines from a central point and use the different triangular shapes as life area sections.
                    </p>

                    <p className="textnots-text">
                      Again, it’s optional, but can provide a nice framework and some general life categories to keep your goals and vision board well rounded.
                      After dividing the poster board into life areas if you choose to do this, write your goals and intentions for each area directly on the board. It will get covered over later with collage images, so don’t worry about how it looks.
                    </p>

                    <p className="textnots-text">
                      <strong>Assemble the Vision Board</strong>
                    </p>
                    <p className="textnots-text">
                      Now it’s time to arrange your images and words on your board.
                      So, with your board in front of you, and your pile of images at hand, go through the images and decide what belongs on the board and place it roughly in the section it will go.  Trim backgrounds away or crop images as you go if you like.  Then continue through the pile of clippings
                    </p>
                    <p className="textnots-text">
                      Some images will go on the board, some will go in a “later” pile to be used in a future collage, and some will just be recycled.
                      Once you have all of your goals and dreams represented on the board, stand back and take a look at it.  How does it look?
                      If you’re happy with the board, proceed to the next step. Otherwise continue to arrange, add images or words, and trim around others until you’re satisfied.
                    </p>
                    <p className="textnots-text">
                      <strong>Glue Everything Together</strong>
                    </p>

                    <p className="textnots-text">
                      Once you’re satisfied with the arrangement, begin to glue everything down. Working with one image or word at a time, turn it over, apply a layer of glue with a glue stick, then stick it to the board. Rub over it with your hands to smooth it out as much as possible.
                    </p>

                    <p className="textnots-text">
                      <strong>Display Your Board</strong>
                    </p>
                    <p className="textnots-text">
                      Once your vision board is complete, hang it on the wall where you will see it regularly. Think–your office, bedroom, or living room. It’s important to create a vision board, but equally important to see it regularly.
                      You can frame it first, or simply tape it to the wall.
                      Also, take a photo of the vision board. You might use this digital version as wallpaper on your computer or phone.
                    </p>

                  </div>
                  <div className="card mt-4">
              <div className="card-header">
                <h5>Ideas for Goals ByLife Area and Inspiration for Creating Your Vision Board</h5>
              </div>
              <div className="card-body">
                  {questions.map((question) => (
                    <div className="form-group row" key={question.id}>
                      <label className="col-sm-3 col-form-label mynotes-label"><b>{question.question}</b></label>
                      <div className="col-sm-9">
                        <textarea
                          className={`form-control ${errors[question.id] ? 'is-invalid' : ''}`}
                          name={question.id}
                          value={formData[question.id]}
                          onChange={handleChange}
                          placeholder="Insert Here..."
                          rows="4"
                        ></textarea>
                        {errors[question.id] && <div className="text-danger">{errors[question.id]}</div>}
                      </div>
                    </div>
                  ))}
                         </div>
                </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisionBoard;
