import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { MdDelete } from 'react-icons/md';
import DataTable from 'react-data-table-component';
import { FaEye, FaRegEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import axios from "axios";
import API, { isSubscribedAccountant } from "../../utils";
import { format } from 'date-fns';
import { useNavigate } from "react-router-dom";
const MyLifeMotto = () => {
    const uid = localStorage.getItem("user_id");
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [gratitudeEntries, setGratitudeEntries] = useState([]);
    const navigate = useNavigate()

    useEffect(() => {
        if (!isSubscribedAccountant()) {
            navigate("/users/userEdit");
            window.location.reload();
          }
        fetchmoto();
    }, []);

    const fetchmoto = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.lifemotoall}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        'Content-Type': 'multipart/form-data'
                    },
                }
            );

            console.log("task", response.data)

            if (response.data.status == "success") {
                setGratitudeEntries(response.data.data);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error during login:", error);
            toast.error("An error occurred. Please try again.");
        }
    };

    const toggleDeleteModal = () => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
    };

    const toggleEditModal = () => {
        setIsEditModalOpen(!isEditModalOpen);
        setLifeMotoError("");
        setmottoError("")
    };

    const handleDelete = (item) => {
        setSelectedItem(item);
        toggleDeleteModal();
    };

    const handleDeleteItem = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('motto_id', selectedItem.id);

            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.lifemotodelete}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        'Content-Type': 'multipart/form-data'
                    },
                }
            );

            if (response.data.status === "success") {
                // toast.success(response.data.message);
                toggleDeleteModal();
                fetchmoto();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("An error occurred while deleting. Please try again.");
        }
    };

    const handleEdit = (item) => {
        setSelectedItem(item);
        setLifeMoto(item.motto);
        setmotto(item.reason);
        toggleEditModal();
    };

    const handleEditItem = async () => {
        let isValid = true;

        if (!lifeMoto.trim()) {
            setLifeMotoError('This feild is required.');
            isValid = false;
        } else {
            setLifeMotoError('');
        }

        if (!motto.trim()) {
            setmottoError('This feild is required.');
            isValid = false;
        } else {
            setmottoError('');
        }

        if (isValid) {
            try {
                const formDataToSend = new FormData();
                formDataToSend.append('motto_id', selectedItem.id);
                formDataToSend.append('motto', lifeMoto);
                formDataToSend.append('reason', motto);

                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.lifemotoupdate}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                            'Content-Type': 'multipart/form-data'
                        },
                    }
                );

                if (response.data.status === "success") {
                    // toast.success(response.data.message);
                    toggleEditModal();
                    fetchmoto();
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error("Error updating core value:", error);
                toast.error("An error occurred while updating. Please try again.");
            }
        }

       
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value); 
    };

    const filteredEntries = gratitudeEntries.filter(entry =>
        entry?.date?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        entry?.motto?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        entry?.reason?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const [reason, setreason] = useState("");
    const [reasonBorderError, setreasonBorderError] = useState(false);
    const [reasonerror, setreasonerror] = useState("");
    const [motto, setmotto] = useState("");
    const [motoBorderError, setMotoBorderError] = useState(false);
    const [motoerror, setMotoerror] = useState("");
    const [lifeMoto, setLifeMoto] = useState(null);
    const [lifeMotoError, setLifeMotoError] = useState('');
    const [mottoError, setmottoError] = useState('');

    const handleAdd = async () => {
        let isError = false;

        if (!reason) {
            setreasonerror("This field is required");
            setreasonBorderError(true);
            isError = true;
        } else {
            setreasonerror("");
            setreasonBorderError(false);
        }

        if (!motto) {
            setMotoerror("This field is required");
            setMotoBorderError(true);
            isError = true;
        } else {
            setMotoerror("");
            setMotoBorderError(false);
        }

        if (isError) {
            return;
        } else {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('reason', reason);
            formDataToSend.append('motto', motto);

            try {
                const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.lifemotoadd}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                            'Content-Type': 'multipart/form-data'
                        },
                    }
                );
                if (response.data.status == "success") {
                    // toast.success(response.data.message);
                    togglePopup();
                    fetchmoto();
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                toast.error("An error occurred. Please try again.");
            }
        }
    };

    const togglePopup = () => {
        setreason("");
        setreasonerror("");
        setreasonBorderError(false);
        setmotto("");
        setMotoerror("");
        setMotoBorderError(false);
        setIsPopupOpen(!isPopupOpen);
    };

    const [isViewModalOpen, setIsViewModalOpen] = useState(false);

    const handleView = (item) => {
        setSelectedItem(item);
        toggleViewModal(); 
    };
    const toggleViewModal = () => {
        setIsViewModalOpen(!isViewModalOpen); 
    };
    const thStyle = {
        border: "1px solid gray",
        padding: "8px",
        textAlign: "left",
        background: "black",
        color: "white"
    };

    const columns = [
        {
            name: 'Sr No.',
            selector: (row ,index) => index+1,
            sortable: true,
          },
        {
            name: 'Date',
            selector: (row) => format(new Date(row.created_at), 'yyyy-MM-dd'),
            sortable: true,
        },
        {
            name: 'My Life Motto',
            selector: (row) => row.motto,
            sortable: true,
        },
        {
            name: 'Reason',
            selector: (row) => row.reason,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row) => (
                <>
                    <Button className="btn-secondary btn-pill main-edit-view-buttons mx-1" onClick={() => handleView(row)}><FaEye /></Button>
                    <Button className="btn-secondary btn-pill main-edit-view-buttons mx-1" onClick={() => handleEdit(row)}><FaRegEdit /></Button>
                    <Button variant="danger" className="btn-primary btn-pill main-buttons mx-1" onClick={() => handleDelete(row)}><MdDelete /></Button>
                </>
            ),

        },
    ];

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header ">
                                <div className="row">
                                    <div className=" align-items-center justify-content-between d-flex">
                                        <h5>My Life Motto</h5>
                                        <Button className="btn-success main-buttons" onClick={togglePopup}>Add My Life Motto</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="card-body ">
                            <div className="row">
                                <div className="col-md-9"></div>
                                <div className="col-md-3">
                                    <div className="form-group text-end mb-3 ">
                                        <input className="form-control" type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
                                    </div>
                                </div>
                            </div>
                           
                            <DataTable
                                columns={columns}
                                data={filteredEntries}
                                pagination
                                className="text-center"
                                persistTableHead={true}
                            />
                        </div>
                    </div>
                </div>

            </div>
            {isPopupOpen && (
                <Modal centered={true} show={isPopupOpen} onHide={togglePopup}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Life Motto</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="theme-form">
                            <div className="form-group">
                                <label className="col-form-label pt-0" htmlFor="reasonvalue">Life Motto</label>
                                <input className={`form-control ${reasonBorderError ? 'input-error' : ''}`}
                                    type="text"
                                    name="motto"
                                    onChange={(e) => {
                                        setreason(e.target.value);
                                        setreasonerror("");
                                        setreasonBorderError(false);
                                    }} placeholder="Enter Life Motto" />
                                {reasonerror && <div className="text-danger">{reasonerror}</div>}
                            </div>

                            <div className="form-group">
                                <label className="col-form-label pt-0" htmlFor="reasonvalue">Reason</label>
                                <input
                                    className={`form-control ${motoBorderError ? 'is-invalid' : ''}`}
                                    name="reason"
                                    type="text"
                                    placeholder="Enter Life Motto"
                                    onChange={(e) => {
                                        setmotto(e.target.value);
                                        setMotoerror("");
                                        setMotoBorderError(false);
                                    }} />
                                {motoerror && <div className="invalid-feedback">{motoerror}</div>}
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary me-1" onClick={togglePopup}>Cancel</button>
                        <button className="btn btn-secondary" onClick={handleAdd}>Submit</button>
                    </Modal.Footer>
                </Modal>
            )}
            {isDeleteModalOpen && (
                <Modal centered={true} show={isDeleteModalOpen} onHide={toggleDeleteModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete My Life Motto</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you want to delete {selectedItem && selectedItem.moto}?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-secondary" onClick={toggleDeleteModal}>Cancel</button>
                        <button className="btn btn-danger me-1" onClick={() => handleDeleteItem()}>Delete</button>
                    </Modal.Footer>
                </Modal>
            )}
            {isEditModalOpen && (
                <Modal centered={true} show={isEditModalOpen} onHide={toggleEditModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Item</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="theme-form">
                            <div className="form-group">
                                <label className="col-form-label pt-0" htmlFor="reasonvalue">Life Motto</label>
                                <input className={`form-control ${lifeMotoError ? 'is-invalid' : ''}`} id="reasonvalue" name="moto" type="text" placeholder="Enter Life Motto" value={lifeMoto} onChange={(e) => setLifeMoto(e.target.value)} />
                                {lifeMotoError && <div className="invalid-feedback">{lifeMotoError}</div>}
                            </div>

                            <div className="form-group">
                                <label className="col-form-label pt-0" >Reason</label>
                                <input className={`form-control ${mottoError ? 'is-invalid' : ''}`} name="reason" type="text" placeholder="Enter Life Motto" value={motto} onChange={(e) => setmotto(e.target.value)} />
                                {mottoError && <div className="invalid-feedback">{mottoError}</div>}
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-secondary" onClick={toggleEditModal}>Cancel</button>
                        <button className="btn btn-primary me-1" onClick={() => handleEditItem()}>Save</button>
                    </Modal.Footer>
                </Modal>
            )}
            <div className="card-body btn-showcase">
                {isViewModalOpen && ( // Render view modal when state is true

                    <Modal centered={true} show={isViewModalOpen} onHide={toggleViewModal} size="lg" >
                        <Modal.Header closeButton>
                            <Modal.Title>My Life Motto</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <table className="table">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th style={thStyle} colSpan={2}>My Life Motto</th>
                                    </tr>
                                    <tr>
                                        <td>Date</td>
                                        <td>{selectedItem && format(new Date(selectedItem.created_at), 'yyyy-MM-dd')}</td>
                                    </tr>
                                    <tr>
                                        <td>Life Motto</td>
                                        <td>{selectedItem.motto}</td>
                                    </tr>

                                    <tr>
                                        <td>Life Reason</td>
                                        <td>{selectedItem.reason}</td>
                                    </tr>

                                </tbody>
                            </table>

                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-secondary" onClick={toggleViewModal}>Close</button> {/* Close button */}
                        </Modal.Footer>
                    </Modal>
                )}
            </div>
        </div>
    );
}

export default MyLifeMotto;
