import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../../utils';
import axios from 'axios';
import { debounce } from 'lodash';

const YourStory = () => {
    const [questions, setQuestions] = useState([]);
    const uid = localStorage.getItem('user_id') || '';

    const [formData, setFormData] = useState({});

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         [name]: value
    //     }));
    // };

    useEffect(() => {
        fetchQuestions();
    }, []);

    const fetchQuestions = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('main_category_id', "9");
        formDataToSend.append('sub_category_id', "21");
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getLifeQuestion}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`
                    },
                }
            );
            if (response.data.status === "success") {
                setQuestions(response.data.question || []);
                const initialFormData = {};
                (response.data.question || []).forEach((question) => {
                    initialFormData[question.id] = question.answer || '';
                });
                setFormData(initialFormData);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        questions.forEach((question, index) => {
            formDataToSend.append(`question_id[${index + 1}]`, question.id);
            formDataToSend.append(`answer[${index + 1}]`, formData[question.id] || '');
        });

        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.updateAnswer}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                fetchQuestions();
                // toast.success(response.data.message);
            } else {
                // toast.error(response.data.message || 'Failed to update answers');
            }
        } catch (error) {
            toast.error('Failed to update answers. Please try again later.');
        }
    };
    const debouncedUpdateAnswer = useCallback(
        debounce(async (questionId, answer) => {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('question_id[${index + 1}]', questionId);
            formDataToSend.append('answer[${index + 1}]', answer);

            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.updateAnswer}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );

                if (response.data.status === 'success') {
                    //  toast.success(response.data.message);
                } else {
                    toast.error(response.data.message || 'Failed to update answer');
                }
            } catch (error) {
                console.error('Error updating answer:', error);
                toast.error('Failed to update answer. Please try again later.');
            }
        }, 2000),
        [uid]
    );

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        // Trigger the debounced API call
        debouncedUpdateAnswer(name, value);
    };
    return (
        <div className="row">
            <div className="col-sm-12">

                <div className="card">
                    <div className="card-header">
                        <h5>Identify : What's Your Story</h5>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="card-body m-3">
                            <div className="textnots-container-persomality mb-3">
                                <p className="textnots-text">
                                    The first step in the branding journey is to answer the question “What’s your story?” What’s your Why, What are your values, passions, purposes and superpowers? What story do you want others to know about you? If you don’t tell your story, someone else will, and it may not be the story you want to be told. Your story is your brand. The reality is that everyone has a brand, whether you’re actively managing it or not. You must decide how you want others to perceive you and what you want to be known for. Making this decision can be a struggle, it requires some deep thought and inner searching. This section provides some tools to make this process easier.
                                </p>
                            </div>
                            {questions.map((question) => (
                                <div className="form-group row mb-2 mx-1" key={question.id}>
                                    <label className="col-sm-12 col-form-label mb-2 p-0"><b>{question.question}</b></label>
                                    <textarea
                                        className='form-control'
                                        name={question.id}
                                        value={formData[question.id]}
                                        onChange={handleChange}
                                        rows="4"
                                    ></textarea>
                                </div>
                            ))}
                        </div>
                        {/* <div className="card-footer text-end">
                            <button type="submit" className="btn btn-success mx-1">Save</button>
                        </div> */}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default YourStory;
