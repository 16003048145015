import React, { useState, useEffect } from "react";
import axios from "axios";
import { format } from "date-fns";
import man from "../../assets/images/user/user_default.jpg";
import { toast } from "react-toastify";
import AddAccountabilityPartner from "../../pages/addaccountabilitypartner";
import RenewalModal from "../../auth/cardDetailsmodal/renewModal";
import API from "../../utils";
import ChangePassword from "./changepassword";
import { useNavigate } from "react-router-dom";

const UserEdit = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const user_id = localStorage.getItem("user_id");
  const profileURL = localStorage.getItem("profileURL");
  const [expiryDate, setExpiryDate] = useState("");
  const name = localStorage.getItem("name");
  const user_name = localStorage.getItem("user_name");
  const [googleLogin, setGoogleLogin] = useState(true);
  const [facebookLogin, setFacebookLogin] = useState(true);
  const isSubscriptionValid = localStorage.getItem("isSubscriptionValid") === 'true';

  useEffect(() => {
    const isGoogleLoggedIn = localStorage.getItem("googleLogin");
    const isFacebookLoggedIn = localStorage.getItem("facebookLogin");

    setGoogleLogin(isGoogleLoggedIn === "false");
    setFacebookLogin(isFacebookLoggedIn === "false");
  }, []);

  const [formData, setFormData] = useState({
    user_name: "",
    email: "",
    profile: null,
    profileURL: profileURL || man,
  });

  const [selectedFileName, setSelectedFileName] = useState("");
  const [showRenewalModal, setShowRenewalModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubscriptionExpired, setIsSubscriptionExpired] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [subscriptionDate, setSubscriptionDate] = useState(null);
  useEffect(() => {
    fetchData();
  }, [user_id]);

  const fetchData = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("user_id", user_id);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.getuserdetails}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      )

      if (response.data.status === "success") {
        const userData = response.data.user_data;

        // Handle null or undefined values
        const subscriptionDate = userData.subscription_date


          ? new Date(userData.subscription_date)
          : null;
        const subscriptionDuration = userData.subscription_duration
          ? parseInt(userData.subscription_duration, 10)
          : 0;
        setSubscriptionDate(subscriptionDate);
        setSubscriptionStatus(userData.subscription_status);
        if (subscriptionDate && subscriptionDuration) {
          subscriptionDate.setDate(subscriptionDate.getDate() + subscriptionDuration);
          const formattedExpiryDate = format(subscriptionDate, "eeee, MMMM d, yyyy");
          setExpiryDate(formattedExpiryDate);

          const today = new Date();
          if (subscriptionDate < today) {
            setIsSubscriptionExpired(true);
            localStorage.setItem("isSubscriptionValid", "false");
          } else {
            setIsSubscriptionExpired(false);
            localStorage.setItem("isSubscriptionValid", "true");
          }
        } else {
          setExpiryDate("Not Available");
          setIsSubscriptionExpired(true);
          localStorage.setItem("isSubscriptionValid", "false");
        }
        console.log(isSubscriptionExpired)

        setFormData({
          user_name: userData.user_name || "",
          email: userData.email || "",
          profile: null,
          profileURL: userData.profile || profileURL || man,
        });
      } else {
        toast.error(response.data.message);
        localStorage.removeItem("token");
        localStorage.removeItem("login");
        localStorage.removeItem("user_id");
        localStorage.removeItem("user_name");
        localStorage.removeItem("email");
        localStorage.removeItem("authenticated");
        localStorage.removeItem("auth0_profile");
        localStorage.removeItem("selectedCoreValues");
        localStorage.removeItem("selectedFiveCoreValues");
        localStorage.removeItem("profileURL");
        localStorage.removeItem("profile");
        localStorage.removeItem("formData");
        localStorage.removeItem("checked_items");
        localStorage.removeItem("lifeWheelFormData");
        localStorage.removeItem("loveLoatheFields");
        localStorage.removeItem("loveLoatheRemoveTopHated");
        localStorage.removeItem("comfortZoneFormData");
        localStorage.removeItem("googlelogin");

        navigate(`/login`);
      }
    } catch (error) {
      console.log("Failed to fetch user data");
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      const formDataToSend = new FormData();
      formDataToSend.append("user_name", formData.user_name);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("user_id", user_id);
      if (formData.profile) {
        formDataToSend.append("profile", formData.profile);
      }

      try {
        const response = await axios.post(
          `${API.BASE_URL}${API.ENDPOINTS.updateprofile}`,
          formDataToSend,
          {
            headers: {
              Authorization: `${API.AUTHORIZATION_TOKEN}`,
            },
          }
        );

        if (response.data.status === "success") {
          fetchData();
          setErrors({});
          if (formData.profile) {
            const imageUrl = URL.createObjectURL(formData.profile);
            localStorage.setItem("profileURL", imageUrl);
            setFormData({ ...formData, profileURL: imageUrl, profile: null });
          }
          setSelectedFileName("");
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("User Profile update failed.");
        console.error(error);
      } finally {
        setLoading(false);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: undefined });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        profile: file,
        profileURL: URL.createObjectURL(file),
      });
      setSelectedFileName(file.name);
    }
  };


  const validateForm = () => {
    let errors = {};

    if (!formData.user_name) {
      errors.user_name = "Name is required";
    }

    if (formData.profile && !formData.profile.type.startsWith("image/")) {
      errors.profile = "Please select a valid image ";
    }


    return errors;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleFormSubmit();
    }
  };

  const handleRenewalModalShow = () => setShowRenewalModal(true);
  const handleRenewalModalClose = () => setShowRenewalModal(false);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <form className="card" onSubmit={handleFormSubmit}>
            <div className="card-header">
              <h5 className="card-title mb-0">My Profile</h5>
            </div>
            <div className="card-body">
              <div className="row d-flex align-items-center">
                <div className="d-flex justify-content-between">
                  <div className="d-flex mb-2">
                    <div>
                      <img
                        className="img-70 rounded-circle"
                        alt=""
                        src={
                          formData.profile
                            ? URL.createObjectURL(formData.profile)
                            : formData.profileURL
                        }
                      />
                    </div>
                    <div className="px-4 align-items-center d-flex">
                      <h3 className="mb-1">{formData.user_name}</h3>
                    </div>
                  </div>
                  <div>
                    {!isSubscriptionExpired && (
                      <div className="mt-2">
                        <h6>Subscription expires on</h6>
                        <b>{expiryDate}</b>
                      </div>
                    )}
                    {(isSubscriptionExpired && subscriptionStatus === "0") ||
                      (!subscriptionDate && subscriptionStatus === "2") && (
                        <div className="text-danger mt-2">
                          <h6>Take New subscription For Access The Application</h6>
                          <button
                            className="btn float-end btn-primary"
                            style={{ cursor: "pointer" }}
                            onClick={handleRenewalModalShow}
                          >
                            Buy new Subscription
                          </button>
                        </div>
                      )}

                    {subscriptionDate && subscriptionStatus === "2" && (
                      <div className="text-danger mt-2">
                        <h6>Your subscription has expired.</h6>
                        <button
                          className="btn btn-primary"
                          style={{ cursor: "pointer" }}
                          onClick={handleRenewalModalShow}
                        >
                          Renew your subscription
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">
                      <b>Name</b>
                    </label>
                    <input
                      className={"form-control " + (errors.user_name ? "is-invalid" : "")}
                      type="text"
                      name="user_name"
                      value={formData.user_name}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                    />
                    {errors.user_name && (
                      <div className="invalid-feedback">{errors.user_name}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-sm-12 col-md-6">
                  <div className="form-group mb-3">
                    <label className="form-label">
                      <b>Email Address</b>
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      readOnly
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                </div>

                {!googleLogin && !facebookLogin && (
                  <div className="col-lg-6 col-sm-12 col-md-6">
                    <div className="form-group mb-3">
                      <label className="form-label">
                        <b>Profile Image</b>
                      </label>
                      <input
                        className={"form-control " + (errors.profile ? "is-invalid" : "")}
                        type="file"
                        name="profile"
                        accept="image/*" // This restricts the file selection to images only
                        onChange={handleImageChange}
                      />
                      {errors.profile && (
                        <div className="invalid-feedback">{errors.profile}</div>
                      )}
                      {selectedFileName && (
                        <small className="form-text text-muted">
                          Selected file: {selectedFileName}
                        </small>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {isSubscriptionValid && (
              <div className="card-footer text-end">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading ? "Saving..." : "Update Profile"}
                </button>
              </div>
            )}
          </form>
          <AddAccountabilityPartner />
          {googleLogin && facebookLogin && <ChangePassword />}
          <RenewalModal show={showRenewalModal} handleClose={handleRenewalModalClose} fetchData={fetchData} />
        </div>
      </div>
    </div >
  );
};

export default UserEdit;
