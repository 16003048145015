import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';

const MyLoader = ({ active, children }) => {
  return (
    <LoadingOverlay
      active={active}
      spinner={<BounceLoader />}
      styles={{
        overlay: (base) => ({
          ...base,
          position: 'fixed', // Ensure it covers the entire screen
          width: '100vw',
          height: '100vh',
          zIndex: 10000, // Higher than the modal's z-index
        }),
      }}
    >
      {children}
    </LoadingOverlay>
  );
};

export default MyLoader;
