import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../../utils';
import axios from 'axios';
import { debounce } from 'lodash';

const Personal = () => {
    const uid = localStorage.getItem('user_id') || '';

    const [formData, setFormData] = useState({});
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        fetchQuestions();
    }, []);

    const fetchQuestions = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('main_category_id', '4');
        formDataToSend.append('sub_category_id', '5');
        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.getQuestions}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );
            if (response.data.status === 'success') {
                setQuestions(response.data.question || []);
                const initialFormData = {};
                (response.data.question || []).forEach((question) => {
                    initialFormData[question.id] = question.answer || '';
                });
                setFormData(initialFormData);
            } else {
                toast.error(response.data.message || 'Failed to fetch questions');
            }
        } catch (error) {
            console.error('Error fetching questions:', error);
            toast.error('Failed to fetch questions. Please try again later.');
        }
    };

    const handleSubmitFamily = (e) => {
        e.preventDefault();
        handleSubmit();
    };

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         [name]: value,
    //     }));
    // };

    const debouncedUpdateAnswer = useCallback(
        debounce(async (questionId, answer) => {
            const formDataToSend = new FormData();
            formDataToSend.append('user_id', uid);
            formDataToSend.append('question_id[]', questionId);
            formDataToSend.append('answer[]', answer);
    
            try {
                const response = await axios.post(
                    `${API.BASE_URL}${API.ENDPOINTS.addAnswer}`,
                    formDataToSend,
                    {
                        headers: {
                            Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        },
                    }
                );
    
                if (response.data.status === 'success') {
                    //  toast.success(response.data.message);
                } else {
                    toast.error(response.data.message || 'Failed to update answer');
                }
            } catch (error) {
                console.error('Error updating answer:', error);
                toast.error('Failed to update answer. Please try again later.');
            }
        }, 2000),
        [uid]
    );
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    
        // Trigger the debounced API call
        debouncedUpdateAnswer(name, value);
    };

    const handleSubmit = async () => {
        const formDataToSend = new FormData();
        questions.forEach((question, index) => {
            formDataToSend.append(`question_id[]`, question.id);
            formDataToSend.append(`answer[]`, formData[question.id] || '');
        });

        formDataToSend.append('user_id', uid);

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.addAnswer}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                fetchQuestions();
                // toast.success(response.data.message);
            } else {
                // toast.error(response.data.message || 'Failed to update answers');
            }
        } catch (error) {
            console.error('Error updating answers:', error);
            toast.error('Failed to update answers. Please try again later.');
        }
    };

    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <h5>Personal</h5>
                    </div>
                    <form className="form theme-form" onSubmit={handleSubmitFamily}>
                        <div className="card-body m-3">
                            <div className="row">
                                <div className="col-sm-12">
                                    {questions.map((question) => (
                                        <div className="form-group row mb-2" key={question.id}>
                                            <label className="col-sm-12 col-form-label mb-2 p-0"><b>{question.question}</b></label>
                                            <textarea
                                                className="form-control"
                                                name={question.id}
                                                value={formData[question.id] || ''}
                                                onChange={handleChange}
                                                rows="4"
                                            ></textarea>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {/* <div className="card-footer">
                            <button type="submit" className="btn btn-success main-buttons me-1 float-end">
                                Save
                            </button>
                        </div> */}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Personal;
