import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import API, { isSubscribedAccountant } from '../../../utils';

const Disttestsummarytable = () => {
    const navigate = useNavigate();
    const [visionStatements, setVisionStatements] = useState({});
    const [lifeAreas, setLifeAreas] = useState([]);
    const uid = localStorage.getItem("user_id");
    const [debounceTimer, setDebounceTimer] = useState(null);

    useEffect(() => {
        if (!isSubscribedAccountant()) {
            navigate("/users/userEdit");
            window.location.reload();
        }
    }, [navigate]);

    const fetchData = async () => {
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_all_question_to_disc_test_SummaryMotivation}`,
                null, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                setLifeAreas(response.data.data);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch role model data error', error);
            toast.error("Failed to fetch role model data.");
        }
    };

    const fetchAnswers = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.get_all_to_disc_test_SummaryMotivation}`,
                formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                const visionData = response.data.data.reduce((acc, item) => {
                    acc[item.question_id] = {
                        score: item.score,
                        ranking: item.ranking,
                    };
                    return acc;
                }, {});
                setVisionStatements(visionData);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Fetch answers data error', error);
            toast.error("Failed to fetch answers data.");
        }
    };

    const handleChange = (e, key, field) => {
        const { value } = e.target;
        setVisionStatements(prevState => ({
            ...prevState,
            [key]: {
                ...prevState[key],
                [field]: value,
            },
        }));

        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }

        const newTimer = setTimeout(() => {
            handleSubmit(key);
        }, 2000);
        setDebounceTimer(newTimer);
    };

    const handleSubmit = useCallback(async (key) => {
        const visionStatement = visionStatements[key];
        console.log('fffnfn',visionStatements[key])

        const formDataToSend = new FormData();
        formDataToSend.append('user_id', uid);
        formDataToSend.append('question_id', key);
        formDataToSend.append('score', visionStatement.score || '');
        formDataToSend.append('ranking', visionStatement.ranking || '');

        try {
            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.add_update_disc_test_SummaryMotivation}`,
                formDataToSend, {
                headers: {
                    Authorization: `${API.AUTHORIZATION_TOKEN}`,
                },
            });

            if (response.data.status === "success") {
                fetchAnswers();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Add vision statement error', error);
            toast.error("Failed to add vision statement.");
        }
    }, [visionStatements]);

    useEffect(() => {
        fetchData();
        fetchAnswers(); 
    }, []);

    const getBackgroundColor = (areaId) => {
        switch (areaId) {
            case 0:
                return '#b3e5a1';
            case 1:
                return '#215e99'; 
            case 2:
                return '#ffc000'; 
            case 3:
                return '#ff0000'; 
            case 4:
                return '#f1a983'; 
            case 5:
                return '#000000'; 
            case 6:
                return '#80340d'; 
            default:
                return '#FFFFFF'; 
        }
    };
    return (
        <>
        <h3>Summary of Your Motivations</h3>
        <table className="table table-bordered">
        <thead>
            <tr>
                <th style={{width: "50%"}}>Value</th>
                <th style={{width: "25%"}}>Score</th>
                <th style={{width: "25%"}}>Ranking</th>
            </tr>
        </thead>
        <tbody>
            {lifeAreas.map((area ,areaId) => (
                <tr  key={area.id}>
                      <td  style={{ backgroundColor: getBackgroundColor(areaId) , color: 'white'}}><b>{area.question}</b></td>
                    <td>
                        <input type="number"
                            className="form-control"    
                            name={area.id}
                            value={visionStatements[area.id]?.score || ''}
                            onChange={(e) => handleChange(e, area.id, 'score')}
                        />
                          
                        
                    </td>
                    <td>
                        <input type='number'
                            className="form-control"
                            name={area.id}
                          
                            value={visionStatements[area.id]?.ranking || ''}
                            onChange={(e) => handleChange(e, area.id, 'ranking')}
                        />
                    </td>
                   
                </tr>
            ))}
        </tbody>
    </table>
        </>

    );
};

export default Disttestsummarytable;
