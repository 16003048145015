import React, { Fragment, useEffect, useState } from "react";
import CountUp from "react-countup";
import configDB from "../../../data/customizer/config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaCheck, FaDollarSign, FaGolfBall, FaHatCowboySide, FaTasks, } from 'react-icons/fa';
import axios from "axios";
import API from "../../../utils";

const primary = localStorage.getItem("primary_color") || configDB.data.color.primary_color;
const secondary = localStorage.getItem("secondary_color") || configDB.data.color.secondary_color;

const EventCharts = () => {
const [counter,setCounter]=useState("")
  const uid = localStorage.getItem("user_id");
  const fetchDashboardCounters  = async () => {
 
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
   

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_deshboard_counter}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
            // 'Content-Type': 'application/json' 
          },
        }
      );

      if (response.data.status === "success") {
        setCounter(response.data.data)
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during task submission:", error);
      toast.error("An error occurred. Please try again.");
    }
  };


  useEffect(() => {
    fetchDashboardCounters();
  }, []);
  return (
    <Fragment>
      <div className="col-md-3">
        <div className="card card-animate-eventcharts first-card">
          <div className="card-body eventcharts ">
            <div className="chart-widget-dashboard">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <a href="/personal-growth/task">
                    <div className="d-flex justify-content-between">

                      <p>Tasks</p>
                      <i className="text-white icon-angle-double-right"></i>
                    </div>
                    <h5 className="mt-0 mb-0 f-w-600">

                      <span className="d-flex text-white">
                        <FaTasks className="dashboard-icons" />
                        <CountUp className="counter" end={counter.task_count} />
                      </span>
                    </h5>
                  </a>
                </div>
              </div>
              <div className="dashboard-chart-container">

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card card-animate-eventcharts second-card">
          <div className="card-body eventcharts">
            <div className="chart-widget-dashboard">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <a href="/future/life-goal">
                    <div className="d-flex justify-content-between">
                      <p>Goals</p>
                      <i className="text-white icon-angle-double-right"></i>
                    </div>
                    <h5 className="mt-0 mb-0 f-w-600">

                      <span className="text-white">
                        <FaGolfBall className="dashboard-icons" />
                        <CountUp className="counter" end={counter.habit_count} />
                      </span>
                    </h5>
                  </a>
                </div>
                {/* <ShoppingBag /> */}
              </div>
              <div className="dashboard-chart-container">

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card card-animate-eventcharts third-card">
          <div className="card-body eventcharts">
            <div className="chart-widget-dashboard">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <a href="/personal-growth/habit-tracker">
                    <div className="d-flex justify-content-between">
                      <p>Habit Tracker</p>
                      <i className="text-white icon-angle-double-right"></i>
                    </div>
                    <h5 className="mt-0 mb-0 f-w-600">

                      <span className="text-white">
                        <FaHatCowboySide className="dashboard-icons" />
                        <CountUp className="counter" end={counter.goals} />
                      </span>
                    </h5>
                  </a>
                </div>
                {/* <Sun /> */}
              </div>
              <div className="dashboard-chart-container">

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card card-animate-eventcharts fourth-card">
          <div className="card-body eventcharts">
            <div className="chart-widget-dashboard">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <a href="/personal-growth/annual-income">
                    <div className="d-flex justify-content-between">
                      <p>Annual Income</p>
                      <i className="text-white icon-angle-double-right"></i>
                    </div>
                    <h5 className="mt-0 mb-0 f-w-600">

                      <span className="text-white">
                        <FaDollarSign className="dashboard-icons" />
                        <CountUp className="counter" end={counter.income} />
                      </span>
                    </h5>
                  </a>
                </div>
                {/* <Sun /> */}
              </div>
              <div className="dashboard-chart-container">

              </div>
            </div>
          </div>
        </div>
      </div>

    </Fragment>
  );
};

export default EventCharts;
