import React, { useState, useEffect } from "react";
import { Form, FormGroup, Input, Label } from 'reactstrap';
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../../../utils"; 

const AddModal = ({ isOpen, toggle, closeAddModal, fetchTasks }) => {
  const uid = localStorage.getItem("user_id");

  const initialFormData = {
    title: '',
    description: '',
    Criticality: '',
    dueDate: '',
    Status: '',
    Area: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

  useEffect(() => {
    if (!isOpen) {
      setFormData(initialFormData);
    }
  }, [isOpen]);

  useEffect(() => {
    const isFormEmpty = Object.values(formData).every(value => value === '');
    setIsSaveButtonDisabled(isFormEmpty);
  }, [formData]);

  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);
    formDataToSend.append('title', formData.title);
    formDataToSend.append('description', formData.description);
    formDataToSend.append('criticality', formData.Criticality);
    formDataToSend.append('status', formData.Status);
    formDataToSend.append('life_area', formData.Area);
    formDataToSend.append('due_date', formData.dueDate);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.addTask}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        setFormData(initialFormData); // Clear form data
        closeAddModal();
        fetchTasks();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during task submission:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <div>
      <h6>Add Task</h6>
      <Form className="form-bookmark needs-validation" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <FormGroup>
              <Label for="title"> <b>Title</b></Label>
              <Input
                type="text"
                name="title"
                id="title"
                placeholder="Enter Title"
                value={formData.title}
                onChange={(e) => handleChange('title', e.target.value)}
              />
            </FormGroup>
          </div>

          <div className="col-lg-4 col-md-12">
            <FormGroup>
              <Label for="Criticality"><b>Criticality</b></Label>
              <Input
                type="select"
                name="Criticality"
                id="Criticality"
                placeholder="select Criticality"
                value={formData.Criticality}
                onChange={(e) => handleChange('Criticality', e.target.value)}
              >
                <option value="">Choose Option</option>
                <option value="critical">Critical</option>
                <option value="high">High</option>
                <option value="medium">Medium</option>
                <option value="low">Low</option>
              </Input>
            </FormGroup>
          </div>

          <div className="col-lg-4 col-md-12">
            <FormGroup>
              <Label for="Status"><b>Status</b></Label>
              <Input
                type="select"
                name="Status"
                id="Status"
                value={formData.Status}
                onChange={(e) => handleChange('Status', e.target.value)}
              >
                <option value="">Choose Option</option>
                <option value="not-started">Not Started</option>
                <option value="In Process">In Process</option>
                <option value="on-hold">On Hold</option>
                <option value="complete">Complete</option>
              </Input>
            </FormGroup>
          </div>

          <div className="col-lg-4 col-md-12">
            <FormGroup>
              <Label for="Area"><b>Life Area</b></Label>
              <Input
                type="select"
                name="Area"
                id="Area"
                value={formData.Area}
                onChange={(e) => handleChange('Area', e.target.value)}
              >
                <option value="">Choose Option</option>
                <option value="physical-health">Physical Health</option>
                <option value="mental-health">Mental Health</option>
                <option value="romantic-life">Romantic Life</option>
                <option value="personal-finance">Personal Finances</option>
                <option value="family">Family</option>
                <option value="social-life">Social Life</option>
                <option value="career">Career</option>
                <option value="business">Business</option>
                <option value="personal-mission">Personal Mission</option>
                <option value="personal-growth">Personal Growth</option>
                <option value="home-environment">Home Environment</option>
                <option value="fun-recreation">Fun & Recreation</option>
                <option value="passion-hobbies">Passion & Hobbies</option>
                <option value="travel">Travel</option>
                <option value="spiritual">Spiritual</option>
                <option value="General To-Do List">General To-Do List</option>
              </Input>
            </FormGroup>
          </div>

          <div className="col-lg-4 col-md-12">
            <FormGroup>
              <Label for="dueDate"><b>Due Date</b></Label>
              <Input
                type="date"
                name="dueDate"
                id="dueDate"
                value={formData.dueDate}
                onChange={(e) => handleChange('dueDate', e.target.value)}
                min={new Date().toISOString().split('T')[0]} 
              />
            </FormGroup>
          </div>

          <div className="col-lg-4 col-md-12">
            <FormGroup>
              <Label for="description"><b>Description</b></Label>
              <Input
                type="textarea"
                name="description"
                id="description"
                placeholder="Enter Description"
                value={formData.description}
                onChange={(e) => handleChange('description', e.target.value)}
              />
            </FormGroup>
          </div>

          <div className="d-flex justify-content-end align-items-center w-100">
            {/* <button className="btn btn-primary my-2" type="submit" disabled={isSaveButtonDisabled}>Save</button> */}
            <button className="btn btn-secondary mb-4" type="submit"  disabled={isSaveButtonDisabled} >Save</button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddModal;
