import React, { Fragment, useRef, useState, useEffect } from "react";
import { IoCloudDownloadOutline } from "react-icons/io5";
import axios from 'axios';
import API, { isSubscribedAccountant } from '../../utils.js';
import { useNavigate } from 'react-router-dom';

const Life360Bluprint = () => {
  const navigate = useNavigate();
  const uid = localStorage.getItem("user_id");
  const [lifeFactors, setLifeFactors] = useState([]);
  const [lifeFactorQuestion, setLifeFactorQuestion] = useState([]);
  const [answers, setAnswers] = useState([]);
  const componentRef = useRef();

  const handleDownloadCsv = () => {
    if (lifeFactors.length === 0 || lifeFactorQuestion.length === 0 || answers.length === 0) {
      console.error("No data available to generate CSV.");
      return;
    }

    let csvContent = "data:text/csv;charset=utf-8,";

    // Add the header row
    csvContent += "Questions / Factors," + lifeFactors.map(factor => factor.name).join(",") + "\n";

    // Add each question and its corresponding answers
    lifeFactorQuestion.forEach((question) => {
      let row = [question.question];
      lifeFactors.forEach(factor => {
        const matchedAnswer = answers.find(a => a.category === factor.name && a.question === question.question);
        row.push(matchedAnswer ? matchedAnswer.answer : "N/A");
      });
      csvContent += row.join(",") + "\n";
    });

    // Create a downloadable link and trigger the download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "My_Personal_Operating_System_Life_Action_Plan.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const verticalRow = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.vertialrow}`, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`
        }
      });
      const data = response.data;
      if (data.status === "success") {
        setLifeFactorQuestion(data.life_area_vertical);
      } else {
        console.error("Failed to fetch vertical rows");
      }
    } catch (error) {
      console.error("Error fetching vertical rows:", error.message);
    }
  };

  const horizontalRow = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.horizontalrow}`, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`
        }
      });
      const data = response.data;
      if (data.status === "success") {
        setLifeFactors(data.life_area);
      } else {
        console.error("Failed to fetch horizontal rows");
      }
    } catch (error) {
      console.error("Error fetching horizontal rows:", error.message);
    }
  };

  const fetchAnswers = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.get_all_for_Life360_blueprint}`,
        formDataToSend,
        {
          headers: {
            Authorization: `${API.AUTHORIZATION_TOKEN}`,
          },
        }
      );

      if (response.data.status === "success") {
        setAnswers(response.data.data);
      } else {
        console.error("Failed to fetch answers.");
      }
    } catch (error) {
      console.error("Error fetching answers:", error.message);
    }
  };

  const handleDeleteRow = (question) => {
    setLifeFactorQuestion(prevQuestions => prevQuestions.filter(q => q.question !== question.question));
    // Optional: Also delete related answers if needed
    setAnswers(prevAnswers => prevAnswers.filter(a => a.question !== question.question));
  };

  useEffect(() => {
    horizontalRow();
    verticalRow();
    fetchAnswers();
  }, []);

  useEffect(() => {
    if (!isSubscribedAccountant()) {
      navigate("/users/userEdit");
      window.location.reload();
    }
  }, [navigate]);

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card mt-4">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-6 align-items-center d-flex">
                    <h5>Life 360 Blueprint</h5>
                  </div>
                  <div className="col-md-6 text-end">
                    <button className="btn btn-success main-buttons me-2" onClick={handleDownloadCsv}>
                      <IoCloudDownloadOutline className="mx-1" /> Download Report
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="life-planing-div">
                  <div ref={componentRef}>
                    {lifeFactors.length > 0 && answers.length > 0 ? (
                      <table className="life-planing-table table table-bordered mt-4">
                        <thead>
                          <tr>
                            <th className="question-anster-th-life360">Questions / Factors</th>
                            {lifeFactors.map((factor, index) => (
                              <th className="question-anster-th-life360" key={index}>{factor.name}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {lifeFactorQuestion.map((question, rowIndex) => (
                            <tr key={rowIndex}>
                              <td 
                                className="text-center life-plan-question-blueprint life-plan-table-width">
                                {question.question}
                              </td>
                              {lifeFactors.map((factor, factorIndex) => {
                                const matchedAnswer = answers.find(a => a.category === factor.name && a.question === question.question);
                                return (
                                  <td
                                    style={{
                                      backgroundColor: rowIndex >= lifeFactorQuestion.length - 2 ? 'yellow' : 'transparent',
                                    }} key={factorIndex} className="text-center">
                                    {matchedAnswer ? matchedAnswer.answer : "N/A"}
                                  </td>
                                );
                              })}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p className="text-center pt-4">No data available to display.</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Life360Bluprint;
