import React, { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from '../../../utils';
import axios from 'axios';

const TopTenCoreValue = ({ topTencoreValues, fetchTopTenCoreValues, fetchTopFiveCoreValues }) => {
    const maxSelection = 5;

    const [selectedCoreValues, setSelectedCoreValues] = useState([]);

    // Sync the selectedCoreValues state with the topTencoreValues prop
    useEffect(() => {
        const selectedValues = topTencoreValues
            .filter(entry => entry.top5_check === "1")
            .map(entry => entry.id);
        setSelectedCoreValues(selectedValues);
    }, [topTencoreValues]);

    const handleCheckboxChange = async (event, coreName, coreId) => {
        const isChecked = event.target.checked;

        if (isChecked && selectedCoreValues.length >= maxSelection) {
            toast.error(`You can select only ${maxSelection} core values.`);
            return;
        }

        const updatedSelectedCoreValues = isChecked
            ? [...selectedCoreValues, coreId] // Add the new selected core value
            : selectedCoreValues.filter(id => id !== coreId); // Remove the unselected core value

        setSelectedCoreValues(updatedSelectedCoreValues);

        try {
            const formDataToSend = new FormData();
            formDataToSend.append('personalos_insert_id', coreId);
            formDataToSend.append('is_check', isChecked ? 1 : 0);

            const response = await axios.post(
                `${API.BASE_URL}${API.ENDPOINTS.check_top5new}`,
                formDataToSend,
                {
                    headers: {
                        Authorization: `${API.AUTHORIZATION_TOKEN}`,
                        'Content-Type': 'multipart/form-data'
                    },
                }
            );

            if (response.data.status === "success") {
                fetchTopTenCoreValues();
                fetchTopFiveCoreValues();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error updating core value:", error);
        }
    };

    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <h5>My Top Core Values</h5>
                    </div>
                    <form className="form theme-form">
                        <div className="card-body">
                            <div className="core-values-container">
                                <p className="section-paragraph">
                                “Now that you have narrowed down the core values that resonate with you, please take some time to go through this smaller list and narrow these core values to the top 5 that really stand out to you, that resonate with you, and that mean something to the core of who you are.Don’t overthink this.Keep comparing and contrasting this way until you get to your top 5 personal core values.”
                                </p>
                            </div>
                            <div className="row m-3">
                                {topTencoreValues != null && topTencoreValues.map((entry, index) => (
                                    <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                                        <label className="d-block" htmlFor={`chk-ani${index}`}>
                                            <input
                                                className="checkbox_animated"
                                                id={`chk-ani${index}`}
                                                type="checkbox"
                                                value={entry.id}
                                                onChange={(e) => handleCheckboxChange(e, entry.question, entry.id)}
                                                checked={selectedCoreValues.includes(entry.id)}
                                            />
                                            {entry.question}
                                        </label>
                                    </div>
                                ))}
                                {topTencoreValues.length === 0 && (
                                    <div className='text-center'>
                                        <p><b>No top 10 core values available.</b></p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default TopTenCoreValue;
