import React, { useEffect, Fragment, useState } from 'react';
import { ShoppingBag } from 'react-feather';
import { FaUser } from 'react-icons/fa';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap';
import { Notification } from '../../../constant';
import API from '../../../utils';

const Notifications = props => {
  const [notifications, setNotifications] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [taskDetails, setTaskDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const uid = localStorage.getItem('user_id');
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    fetchData();
    fetchNotificationCount();
  }, []);

  const fetchNotificationCount = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.notifications}`,
        formDataToSend,
        {
          headers: {
            Authorization: 'FFxx21$$21life360AAA212TRRR',
          },
        }
      );

      if (response.data.status === 'success') {
        setNotificationCount(response.data.data.length);
      } else {
        const errorMessage = response.data ? response.data.message : 'Unknown error';
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error('Fetch notifications error', error);
    
    }
  };

  const fetchData = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('user_id', uid);

    try {
      const response = await axios.post(
        `${API.BASE_URL}${API.ENDPOINTS.notifications}`,
        formDataToSend,
        {
          headers: {
            Authorization: 'FFxx21$$21life360AAA212TRRR',
          },
        }
      );

      if (response.data && response.data.status === 'success') {
        setNotifications(response.data.data);
      } else {
        const errorMessage = response.data ? response.data.message : 'Unknown error';
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error('Fetch notifications error', error);
    
    }
  };

  const handleShowAllToggle = () => {
    setShowAll(!showAll);
  };

  const fetchNotificationDetails = async (id) => {
    try {
      const response = await axios.get(`${API.BASE_URL}${API.ENDPOINTS.viewTask}/${id}`, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });
      const data = response.data;
      if (data.status === 'success') {
        setTaskDetails(data.task);
        fetchData();
        fetchNotificationCount();
      } else {
        console.error('Failed to fetch task details');
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error('Task not found');
        toast.error('Task not found');
      } else {
        console.error('Error fetching task details:', error.message);
        toast.error('Failed to fetch task details');
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchNotificationDetailshabit = async (id) => {
    const formDataToSend = new FormData();
    formDataToSend.append('habit_id', id);

    try {
      const response = await axios.post(`${API.BASE_URL}${API.ENDPOINTS.view_habit_notification}`, formDataToSend, {
        headers: {
          Authorization: `${API.AUTHORIZATION_TOKEN}`,
        },
      });
      const data = response.data;
      if (data.status === 'success') {
        setTaskDetails(data.habit);
        fetchData();
        fetchNotificationCount();
      } else {
        console.error('Failed to fetch habit details');
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error('Habit not found');
        toast.error('Habit not found');
      } else {
        console.error('Error fetching habit details:', error.message);
        toast.error('Failed to fetch habit details');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleNotificationClick = (notification) => {
    setSelectedNotification(notification);
    setLoading(true); // Start loading
    if (notification.type === 'habit') {
      fetchNotificationDetailshabit(notification.habit_id);
    } else {
      fetchNotificationDetails(notification.task_id);
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedNotification(null);
    setTaskDetails(null);
    setLoading(true); // Reset loading state for next modal open
    window.location.reload(); // Reload the page
  };

  const displayedNotifications = showAll ? notifications : notifications.slice(0, 4);
  const thStyle = {
    border: "1px solid gray",
    textAlign: "left",
    background: "black",
    color: "white"
  };

  return (
    <Fragment>
      <div>
        <ul className="notification-dropdown onhover-show-div p-0">
          <li className='notification-heaing' >{Notification}</li>
          {displayedNotifications.length > 0 ? (
            displayedNotifications.map((notification, index) => (
              <li key={index} onClick={() => handleNotificationClick(notification)}>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <h6 className="mt-0">
                      <span>
                        {notification.type === 'habit' ? <ShoppingBag /> : <FaUser />}
                      </span>
                      {notification.title}
                    </h6>
                    <p className="mb-0">{notification.message}</p>
                  </div>
                </div>
              </li>
            ))
          ) : (
            <li>No Notifications</li>
          )}
          {notifications.length > 4 && (
            <li className="txt-dark">
              <p onClick={handleShowAllToggle}>
                {showAll ? 'Show Less' : 'All Notifications'}
              </p>
            </li>
          )}
        </ul>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Notification Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <p>Loading...</p>
          ) : (
            taskDetails && (
              <table className="table">
                <thead>
                  <tr>
                    <th style={thStyle} colSpan={2}>
                      {selectedNotification.type === 'habit' ? 'View Habit Details' : 'Task Detail'}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {selectedNotification.type === 'task' && (
                    <>
                      {taskDetails.title && (
                        <tr>
                          <td>Task Name</td>
                          <td>{taskDetails.title}</td>
                        </tr>
                      )}
                      {taskDetails.criticality && (
                        <tr>
                          <td>Criticality</td>
                          <td>{taskDetails.criticality}</td>
                        </tr>
                      )}
                      {taskDetails.due_date && (
                        <tr>
                          <td>Due Date</td>
                          <td>{taskDetails.due_date}</td>
                        </tr>
                      )}
                      {taskDetails.description && (
                        <tr>
                          <td>Description</td>
                          <td>{taskDetails.description}</td>
                        </tr>
                      )}
                      {taskDetails.status && (
                        <tr>
                          <td>Status</td>
                          <td>{taskDetails.status}</td>
                        </tr>
                      )}
                      {taskDetails.life_area && (
                        <tr>
                          <td>Life Area</td>
                          <td>{taskDetails.life_area}</td>
                        </tr>
                      )}
                 
                    </>
                  )}

                  {selectedNotification.type === 'habit' && (
                    <>
                      {taskDetails.habit && (
                        <tr>
                          <td>Habit Name</td>
                          <td>{taskDetails.habit}</td>
                        </tr>
                      )}
                      {taskDetails.criticality && (
                        <tr>
                          <td>Criticality</td>
                          <td>{taskDetails.criticality}</td>
                        </tr>
                      )}
                      {taskDetails.due_date && (
                        <tr>
                          <td>Due Date</td>
                          <td>{taskDetails.due_date}</td>
                        </tr>
                      )}
                      {taskDetails.description && (
                        <tr>
                          <td>Description</td>
                          <td>{taskDetails.description}</td>
                        </tr>
                      )}
                      {taskDetails.status && (
                        <tr>
                          <td>Status</td>
                          <td>{taskDetails.status}</td>
                        </tr>
                      )}
                      {taskDetails.life_area && (
                        <tr>
                          <td>Life Area</td>
                          <td>{taskDetails.life_area}</td>
                        </tr>
                      )}
                      <tr>
                        <td>Created At</td>
                        <td>{taskDetails.created_at}</td>
                      </tr>
                      <tr>
                        <td>Updated At</td>
                        <td>{taskDetails.updated_at}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            )
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </Fragment>
  );
};

export default Notifications;
