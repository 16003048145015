import React, { useState } from 'react';
import { EnterOTP } from '../constant';
import { useNavigate } from 'react-router-dom';
import TwoStepVerificationbg from "../assets/images/authimg/twostepverification.svg";
import Forgotpasswordbg from "../assets/images/authimg/forgotpasswordbg.svg";
import Logosvg from '../assets/images/logo/auth.svg';

const TwoStepVerification = () => {
    const [otpValues, setOtpValues] = useState(['', '', '', '', '', '']);
    const [validationError, setValidationError] = useState('');
    const navigate = useNavigate();

    const handleChange = (index, value) => {
        if (/^\d?$/.test(value)) {
            const updatedOtpValues = [...otpValues];
            updatedOtpValues[index] = value;
            setOtpValues(updatedOtpValues);
            setValidationError('');  // Clear validation error on change
        }
    };

    const handleVerify = () => {
        const allFilled = otpValues.every(value => /^\d$/.test(value));
        if (allFilled) {
            // All OTP inputs are valid, proceed with verification
            console.log('OTP verified:', otpValues.join(''));
            navigate(`/resetPwd`);
        } else {
            setValidationError('Please enter a digit in all fields');
        }
    };

    return (
        <>
            <div className="auth-page-wrapper">
                <div className="page-wrapper">
                    <div className="container-fluid">
                        <div className="authentication-main">
                            {/* <div className="row">
                                <div className="col-md-12 p-0"> */}
                            <div className="auth-innerright">
                                <div className="authentication-box">
                                    <div className="card mt-4 ">
                                        <div className='row m-0'>
                                            <div className="col-lg-6 col-md-6 col-sm-12 login-main ">
                                                {/* className="login-bg" */}
                                                <div className="h-100">
                                                    <img src={TwoStepVerificationbg} className="login-bg" alt="login-Bg"></img>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 ">
                                                <div className="p-lg-5 p-md-3 p-sm-0 ">
                                                    {/* <div className="login-card-body"> */}
                                                    <div className="text-center">
                                                        <img className="Logosvg-auth" src={Logosvg} alt="logo-svg" />
                                                        <span className='lifelogosignin'>Life360</span>
                                                    </div>
                                                    <div className=" ">
                                                        <h5 className="text-center text-primary text-login-h5">Verification 💬</h5>
                                                        <p className="text-center text-muted mb-3">We sent a verification code to your Email. Enter the code from the Email in the field below.</p>
                                                    </div>

                                                    <div className="form-group rounded opt-box">
                                                        <label className="col-form-label pt-0">{EnterOTP}</label>
                                                        <div className="form-row">
                                                            {otpValues.map((value, index) => (
                                                                <div className="col" key={index}>
                                                                    <input
                                                                        className={`form-control digits text-center opt-text ${validationError ? 'is-invalid' : ''}`}
                                                                        type="text"
                                                                        value={value}
                                                                        maxLength="1"
                                                                        inputMode="numeric"
                                                                        pattern="\d*"
                                                                        onChange={(e) => handleChange(index, e.target.value)}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </div>
                                                        {validationError && <div className="text-danger text-center mt-2">{validationError}</div>}
                                                    </div>

                                                    <div className="form-group mt-4 mb-4 mb-0 d-grid">
                                                        <button className="btn btn-primary" type="button" onClick={handleVerify}>
                                                            Verify my account
                                                        </button>
                                                    </div>
                                                    <p className="text-center mb-3">Didn't get the mail? <a href="#">Resend</a></p>
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TwoStepVerification;
