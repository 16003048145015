import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const AddHabitModal = ({ show, onHide, onSubmit, formData, setFormData, validationErrors, setValidationErrors }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSubmit();
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add Habit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Habit</Form.Label>
            <Form.Control
              type="text"
              name="habit"
              value={formData.habit}
              onChange={handleChange}
              isInvalid={!!validationErrors.habit}
              onKeyDown={handleKeyDown}
              autoFocus  // Ensure the input is focused
            />
            <Form.Control.Feedback type="invalid">
              {validationErrors.habit}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          Add Habit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddHabitModal;
